import React from "react";
import { useTranslation } from "react-i18next";
import DetailCard from "./DetailCard";
import { ANNO_DOMINI, BUDDHIST_CALENDAR } from "configs/date";
import { IIdInfoForm } from "types/id-info.d";
import { convertYearString } from "utils/formatter";

type Props = {
  userInfo: IIdInfoForm;
};

const UserDetail = ({ userInfo }: Props) => {
  const { t } = useTranslation();
  const { user } = userInfo;

  return (
    <DetailCard title={t("id.register.detail")} data-testid="summary-user">
      <div className="py-1">
        <p className="text-primary-text-500">{t("user.idCard")}</p>
        <p className="px-2">{user.id}</p>
      </div>
      <div className="py-1">
        <p className="text-primary-text-500">{t("user.prefix")}</p>
        <p className="px-2">{user.prefix?.prefixDescription}</p>
      </div>
      <div className="py-1">
        <p className="text-primary-text-500">{t("user.firstName")}</p>
        <p className="px-2">{user.firstName}</p>
      </div>
      <div className="py-1">
        <p className="text-primary-text-500">{t("user.lastName")}</p>
        <p className="px-2">{user.lastName}</p>
      </div>
      <div className="py-1">
        <p className="text-primary-text-500">{t("user.birth")}</p>
        <p className="px-2">
          {user.birth &&
            convertYearString(ANNO_DOMINI, BUDDHIST_CALENDAR, user.birth)}
        </p>
      </div>
      {/* <div className="py-1">
        <p className="text-primary-text-500">{t("user.gender")}</p>
        <p className="px-2">{user.gender?.gender}</p>
      </div> */}
      <div className="py-1">
        <p className="text-primary-text-500">{t("user.email")}</p>
        <p className="px-2">{user.email}</p>
      </div>
      <div className="py-1">
        <p className="text-primary-text-500">{t("user.tel")}</p>
        <p className="px-2">{user.tel}</p>
      </div>
    </DetailCard>
  );
};

export default UserDetail;
