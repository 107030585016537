import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NOTFOUND_PATH } from "configs/page";
import DetailContact from "components/Contact";
import DetailCard from "./components/DetailCard";
import { carPolicyStatus } from "configs/car";

const OrderStatusPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const currentParams = Object.fromEntries([...searchParams]);
  const status = currentParams.status || "";

  useEffect(() => {
    if (!status || !Object.values(carPolicyStatus).includes(status)) {
      navigate(NOTFOUND_PATH);
    }
  }, [status, navigate]);

  return (
    <DetailCard>
      <div className="pb-10">
        {(status === carPolicyStatus.pass ||
          status === carPolicyStatus.error) && (
          <>
            <h1 className="text-center py-3">
              {t("summary.orderStatus.success.title")}
            </h1>
            <h2 className="text-center py-4">
              {t("summary.orderStatus.success.detail")}
            </h2>
          </>
        )}
        {status === carPolicyStatus.cancel && (
          <>
            <h1 className="text-center py-3">
              {t("summary.orderStatus.cancel.title")}
            </h1>
            <h2 className="text-center py-4">
              {t("summary.orderStatus.cancel.detail")}
            </h2>
          </>
        )}
        {status === carPolicyStatus.imageReceive && (
          <>
            <h1 className="text-center py-3">
              {t("summary.orderStatus.imageReceive.title")}
            </h1>
            <h2 className="text-center py-4">
              {t("summary.orderStatus.imageReceive.detail")}
            </h2>
          </>
        )}
        {status === carPolicyStatus.waitingPayment && (
          <h1 className="text-center py-3">
            {t("summary.orderStatus.waitingPayment.title")}
          </h1>
        )}
        <DetailContact showDetail={status !== carPolicyStatus.waitingPayment} />
        {status === carPolicyStatus.waitingPayment && (
          <div className="text-center">
            <p className="text-primary body-small opacity-30 py-2">
              {t("summary.orderStatus.waitingPayment.note")}
            </p>
          </div>
        )}
      </div>
    </DetailCard>
  );
};

export default OrderStatusPage;
