import React from "react";
import { TFunction } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import Radio from "components/Input/Radio";
import { sentAddressOptions } from "configs/sent-insurance-option";

interface Props {
  t: TFunction;
}

const SentOptionForm = ({ t }: Props): JSX.Element => {
  const { control } = useFormContext();

  return (
    <div
      className="border px-2 py-4 rounded-lg border-primary-input-border mt-3 mb-7"
      data-testid="id-register-user-address-sentOption"
    >
      <Controller
        control={control}
        name={`sentOption`}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <Radio
              label={t("address.sentOption")}
              onChange={onChange}
              value={value}
              options={sentAddressOptions}
              errorMsg={error?.message}
              name={"sentOption"}
              t={t}
            />
          );
        }}
      />
    </div>
  );
};

export default SentOptionForm;
