export const LANDING_PATH = "/";
export const NOTFOUND_PATH = "/404";
export const CAR_BOOK_PREVIEW_PATH = "/car-book-preview";
export const CAR_BOOK_REGISTER_PATH = "/car-book-register";

export const CAR_PACKAGE_PATH = "/packages";
export const CAR_PACKAGE_DETAIL_PATH = "/packages-detail";
export const CAR_PACKAGE_CONFIRM_PATH = "/packages-confirm";

export const KYC_PATH = "/kyc";
export const SUMMARY_PATH = "/summary";
export const ORDER_SUCCESS_PATH = "/order-success";
export const ORDER_STATUS_PATH = "/order-status";
export const PAYMENT_RESULT_PATH = "/payment-result";

export const ID_CARD_PATH = "/id-card";
export const ID_UPLOAD_PATH = "/id-card-upload";
export const ID_INSURANCE_OPTION_PATH = "/id-insurance-option-page";

export const CAR_SUMMARY_PATH = "/car-summary";

export const SELECTED_INSURED_PATH = "/insured";
export const ID_HOME_PATH = "/id";
export const ID_PREVIEW_PATH = "/id-preview";
export const ID_REGISTER_PATH = "/id-register";

export const CAR_DETECTION_HOME_PATH = "/car-detection-intro";
export const CAR_DETECTION_PATH = "/car-detection";
export const CAR_DETECTION_PREVIEW_PATH = "/car-detection-preview";
export const CAR_SUCCESS_PATH = "/car-success";

export const MAIL_SUCCESS_PATH = "/mail-success";
export const SESSION_EXPIRE_PATH = "/session-expired";

export const HASH_NO_BACK = "home";
export const HASH_NO_BACK_AGAIN = "homepage";
