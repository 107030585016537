import { configs } from "configs/config";
import { axios } from "utils/axios";
import {
  IMtiGenerateProposalIdResponse,
  IMtiGenerateProposalIdParams,
  IMtiGeneratePolicyResponse,
  IMtiGeneratePolicyParams,
  IMtiCheckPolicyResponse,
  IMtiCheckPolicyParams,
  IMtiCheckBlackListParams,
  IMtiCheckDuplicateChassisResponse,
  IMtiCheckDuplicateChassisParams,
  IMtiRequestGeneratePolicyByPropIdParams,
} from "types/transaction.d";

export const generateProposalIds = (body: IMtiGenerateProposalIdParams) => {
  return axios.post<IMtiGenerateProposalIdResponse>(
    `${configs.apiUrl}/MtiRestService/api/MTIMotor/GeneratePaymentRefNoAndProposalId`,
    body,
    {
      headers: {
        Authorization: `Basic ${configs.mtiApiBasicAuth}`,
      },
    }
  );
};

export const generatePolicy = (body: IMtiGeneratePolicyParams) => {
  return axios.post<IMtiGeneratePolicyResponse>(
    `${configs.apiUrl}/MtiRestService/api/MTIMotor/CheckAndRequestGeneratePolicy`,
    body,
    {
      headers: {
        Authorization: `Basic ${configs.mtiApiBasicAuth}`,
      },
    }
  );
};

export const checkPolicy = (body: IMtiCheckPolicyParams) => {
  return axios.post<IMtiCheckPolicyResponse>(
    `${configs.apiUrl}/MtiRestService/api/MTIMotor/CheckPolicyStatus`,
    body,
    {
      headers: {
        Authorization: `Basic ${configs.mtiApiBasicAuth}`,
      },
    }
  );
};

export const checkBlackList = (params: IMtiCheckBlackListParams) => {
  return axios.get<boolean>(
    `${configs.apiUrl}/bpmp2/Utilities/CheckBlackList`,
    { params }
  );
};

export const checkDuplicateChassis = (
  params: IMtiCheckDuplicateChassisParams
) => {
  return axios.get<IMtiCheckDuplicateChassisResponse>(
    `${configs.apiUrl}/MtiRestService/api/MTIMotor/CheckDuplicateChassis`,
    {
      params,
      headers: {
        Authorization: `Basic ${configs.mtiApiBasicAuth}`,
      },
    }
  );
};

export const requestGeneratePolicyByPropId = (
  params: IMtiRequestGeneratePolicyByPropIdParams
) => {
  return axios.get<IMtiGeneratePolicyResponse>(
    `${configs.apiUrl}/MtiRestService/api/MTIMotor/RequestGeneratePolicyByPropId`,
    {
      params,
      headers: {
        Authorization: `Basic ${configs.mtiApiBasicAuth}`,
      },
    }
  );
};
