// NOTE: cannot use covert year (defaultFormat props)
import React from "react";
import { useTranslation } from "react-i18next";
import { CalendarIcon } from "components/Icon/Common";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import th from "date-fns/locale/th";
import Label from "../Label";
registerLocale("th", th);
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import { formatDate, padTo2Digits } from "utils/formatter";
import {
  BUDDHIST_CALENDAR,
  CalendarFormat,
  RANGE_YEAR,
  BUDDHIST_FROM_ANNODIMINI,
} from "configs/date";

type Props = {
  classNameLabel?: string;
  label?: string;
  onChange: (date: Date) => void;
  value?: Date | null;
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
  errorMsg?: string;
  defaultFormat?: CalendarFormat;
};

const Calendar = ({
  label,
  onChange,
  value,
  minDate,
  maxDate,
  placeholder,
  errorMsg,
  classNameLabel = "",
  defaultFormat = "BE|AD",
}: Props) => {
  const { t } = useTranslation();
  const isError = !!errorMsg;
  const date = value ? new Date(value) : new Date();

  const formatSeperate = defaultFormat.split("|");
  let showFormat = BUDDHIST_CALENDAR;
  if (formatSeperate.length === 2) {
    showFormat = formatSeperate[0];
  } else {
    showFormat = defaultFormat;
  }

  const getYearType = (date: Date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return new Date(`${year}-${padTo2Digits(month)}-${padTo2Digits(day)}`);
  };

  const onChangeYear = (year: number) => {
    const day = `${padTo2Digits(date.getDate())}`;
    const month = `${padTo2Digits(date.getMonth() + 1)}`;

    onChangeDatePicker(new Date(`${year}-${month}-${day}`));
  };
  const onChangeMonth = (month: string) => {
    const day = `${padTo2Digits(date.getDate())}`;
    month = `${padTo2Digits(parseInt(month) + 1)}`;
    const year = date.getFullYear();

    onChangeDatePicker(new Date(`${year}-${month}-${day}`));
  };

  // ON CHANGE
  const onChangeDatePicker = (value: Date) => {
    onChange(getYearType(value));
  };

  // SET DEFAULT VALUE
  const showDate: Date = getYearType(date);

  const range = (start: number, end: number) => {
    return [...Array(end - start)].map((d, i) => i + start);
  };
  const yearRangeMin = minDate
    ? getYear(minDate)
    : getYear(new Date()) - RANGE_YEAR;
  const yearRangeMax = maxDate
    ? getYear(maxDate) + 1
    : getYear(new Date()) + RANGE_YEAR;
  const years = range(yearRangeMin, yearRangeMax);
  const months = [
    "month.jan",
    "month.feb",
    "month.mar",
    "month.apr",
    "month.may",
    "month.jun",
    "month.jul",
    "month.aug",
    "month.sep",
    "month.oct",
    "month.nov",
    "month.dec",
  ];

  return (
    <>
      {label && (
        <Label className={classNameLabel} data-testid="select-label">
          {label}
        </Label>
      )}
      <div className="relative" data-testid="select-list">
        <DatePicker
          selected={showDate}
          onChange={onChangeDatePicker}
          dateFormatCalendar="dd/MM/yyyy"
          dateFormat="dd/MM/yyyy"
          showMonthDropdown
          showYearDropdown
          disabledKeyboardNavigation
          dropdownMode="select"
          locale="th"
          minDate={minDate ? getYearType(minDate) : null}
          maxDate={maxDate ? getYearType(maxDate) : null}
          placeholderText={placeholder}
          customInput={
            <label
              className={`mt-1 py-2 pl-3 pr-10 block w-full border focus:outline-none rounded-lg text-primary-input-text ${
                isError ? "border-error" : "border-primary-input-border"
              }`}
            >
              {value ? (
                <span className="text-primary-input-text">
                  {formatDate(showDate, "dd/mm/yyyy", showFormat)}
                </span>
              ) : (
                <span className="text-primary-input-placeholder">
                  {placeholder}
                </span>
              )}
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <CalendarIcon className="h-6 w-6" />
              </span>
            </label>
          }
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: "grid",
                justifyContent: "center",
              }}
            >
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                type="button"
                className="react-datepicker__navigation react-datepicker__navigation--previous"
                style={{ paddingTop: "22px" }}
                aria-label="Previous Month"
              >
                <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                  Previous Month
                </span>
              </button>

              <div className="react-datepicker__current-month react-datepicker__current-month--hasYearDropdown react-datepicker__current-month--hasMonthDropdown">
                {formatDate(showDate, "dd/mm/yyyy", showFormat)}
              </div>

              <div className="react-datepicker__header__dropdown react-datepicker__header__dropdown--select">
                <div className="react-datepicker__month-dropdown-container react-datepicker__month-dropdown-container--select">
                  <select
                    className="react-datepicker__month-select"
                    value={getMonth(date)}
                    onChange={({ target: { value } }) => {
                      changeMonth(parseInt(value));
                      onChangeMonth(value);
                    }}
                  >
                    {months.map((option, i) => (
                      <option key={option} value={i}>
                        {t(option)}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="react-datepicker__year-dropdown-container react-datepicker__year-dropdown-container--select">
                  <select
                    className="react-datepicker__year-select"
                    value={getYear(date)}
                    onChange={({ target: { value } }) => {
                      changeYear(parseInt(value));
                      onChangeYear(parseInt(value));
                    }}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {showFormat === BUDDHIST_CALENDAR
                          ? option + BUDDHIST_FROM_ANNODIMINI
                          : option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                type="button"
                className="react-datepicker__navigation react-datepicker__navigation--next"
                style={{ paddingTop: "22px" }}
                aria-label="Next Month"
              >
                <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                  Next Month
                </span>
              </button>
            </div>
          )}
        />
      </div>
      {isError && (
        <span className="text-error" data-testid="select-error">
          {errorMsg}
        </span>
      )}
    </>
  );
};

export default Calendar;
