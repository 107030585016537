import { useQuery } from "@tanstack/react-query";
import { getColors } from "services/mti/color";
import { useCommonState } from "./useCommonState";
import { useTranslation } from "react-i18next";

export const useColors = (isAutoFetch = true) => {
  const { setWarningDialog } = useCommonState();
  const { t } = useTranslation();
  const { data, isLoading, isError, error, refetch } = useQuery(
    ["color"],
    getColors,
    {
      enabled: isAutoFetch,
      onError: () => {
        setWarningDialog({
          title: t("warning.common"),
          message: t("warning.retry"),
        });
      },
    }
  );

  return { colors: data?.data, isLoading, isError, error, refetch };
};
