import { configs } from "configs/config";
import { axiosBaseApi } from "utils/axios";
import { IOneTimeTokenResponse } from "types/auth.d";

export const oneTimeTokenByApiKey = async () => {
  return axiosBaseApi.post<IOneTimeTokenResponse>(
    `${configs.caraiBaseApiUrl}/one_time_token`,
    {},
    {
      headers: {
        ApiKey: configs.caraiBaseApiPublicKey,
      },
    }
  );
};
