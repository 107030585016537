import {
  useMutation,
  UseMutateFunction,
  UseMutateAsyncFunction,
} from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import {
  generateProposalIds,
  generatePolicy,
  checkPolicy,
  checkBlackList,
  checkDuplicateChassis,
  requestGeneratePolicyByPropId,
} from "services/mti/transaction";
import {
  IMtiGenerateProposalIdResponse,
  IMtiGenerateProposalIdParams,
  IMtiGeneratePolicyResponse,
  IMtiGeneratePolicyParams,
  IMtiCheckPolicyParams,
  IMtiCheckPolicyResponse,
  IMtiCheckBlackListParams,
  IMtiCheckDuplicateChassisParams,
  IMtiCheckDuplicateChassisResponse,
  IMtiRequestGeneratePolicyByPropIdParams,
} from "types/transaction.d";
import { useCommonState } from "./useCommonState";

export const useGenerateProposalId = (): {
  proposalData: IMtiGenerateProposalIdResponse | null;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  generateProposalId: UseMutateFunction<
    AxiosResponse<IMtiGenerateProposalIdResponse, unknown>,
    unknown,
    IMtiGenerateProposalIdParams,
    unknown
  >;
} => {
  const { data, isLoading, isError, error, mutate } = useMutation(
    (body: IMtiGenerateProposalIdParams) => generateProposalIds(body)
  );

  const proposalData = data?.data ?? null;

  return {
    generateProposalId: mutate,
    proposalData,
    isLoading,
    isError,
    error,
  };
};

export const useGeneratePolicy = (): {
  policyResult: IMtiGeneratePolicyResponse | null;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  checkAndGeneratePolicy: UseMutateFunction<
    AxiosResponse<IMtiGeneratePolicyResponse, unknown>,
    unknown,
    IMtiGeneratePolicyParams,
    unknown
  >;
  checkAndGeneratePolicyAsync: UseMutateAsyncFunction<
    AxiosResponse<IMtiGeneratePolicyResponse, unknown>,
    unknown,
    IMtiGeneratePolicyParams,
    unknown
  >;
} => {
  const { data, isLoading, isError, error, mutate, mutateAsync } = useMutation(
    (body: IMtiGeneratePolicyParams) => generatePolicy(body)
  );

  const policyResult = data?.data ?? null;

  return {
    checkAndGeneratePolicy: mutate,
    checkAndGeneratePolicyAsync: mutateAsync,
    policyResult,
    isLoading,
    isError,
    error,
  };
};

export const useCheckPolicy = (): {
  policyData: IMtiCheckPolicyResponse | null;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  checkPolicy: UseMutateFunction<
    AxiosResponse<IMtiCheckPolicyResponse, unknown>,
    unknown,
    IMtiCheckPolicyParams,
    unknown
  >;
} => {
  const { data, isLoading, isError, error, mutate } = useMutation(
    (body: IMtiCheckPolicyParams) => checkPolicy(body)
  );

  const policyData = data?.data ?? null;

  return {
    checkPolicy: mutate,
    policyData,
    isLoading,
    isError,
    error,
  };
};

export const useCheckBlackList = (): {
  isBlackList: boolean | null;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  checkBlackListKYC: UseMutateFunction<
    AxiosResponse<boolean | null, unknown>,
    unknown,
    IMtiCheckBlackListParams,
    unknown
  >;
} => {
  const { setWarningDialog } = useCommonState();
  const { t } = useTranslation();

  const { data, isLoading, isError, error, mutate } = useMutation(
    (body: IMtiCheckBlackListParams) => checkBlackList(body),
    {
      onError: () => {
        setWarningDialog({
          title: t("warning.common"),
          message: t("warning.retry"),
        });
      },
    }
  );

  const res = data?.data;
  const isBlackList = typeof res === "boolean" ? res : null;

  return {
    checkBlackListKYC: mutate,
    isBlackList,
    isLoading,
    isError,
    error,
  };
};

export const useCheckDuplicateChassis = (): {
  checkDupChassisResult: IMtiCheckDuplicateChassisResponse | null;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  checkDupChassis: UseMutateFunction<
    AxiosResponse<IMtiCheckDuplicateChassisResponse, unknown>,
    unknown,
    IMtiCheckDuplicateChassisParams,
    unknown
  >;
  checkDupChassisAsync: UseMutateAsyncFunction<
    AxiosResponse<IMtiCheckDuplicateChassisResponse, unknown>,
    unknown,
    IMtiCheckDuplicateChassisParams,
    unknown
  >;
} => {
  const { data, isLoading, isError, error, mutate, mutateAsync } = useMutation(
    (body: IMtiCheckDuplicateChassisParams) => checkDuplicateChassis(body)
  );

  const checkDupChassisResult = data?.data ?? null;

  return {
    checkDupChassis: mutate,
    checkDupChassisAsync: mutateAsync,
    checkDupChassisResult,
    isLoading,
    isError,
    error,
  };
};

export const useRequestGeneratePolicy = (): {
  requestGeneratePolicyResult: IMtiGeneratePolicyResponse | null;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  requestGeneratePolicy: UseMutateFunction<
    AxiosResponse<IMtiGeneratePolicyResponse, unknown>,
    unknown,
    IMtiRequestGeneratePolicyByPropIdParams,
    unknown
  >;
  requestGeneratePolicyAsync: UseMutateAsyncFunction<
    AxiosResponse<IMtiGeneratePolicyResponse, unknown>,
    unknown,
    IMtiRequestGeneratePolicyByPropIdParams,
    unknown
  >;
} => {
  const { data, isLoading, isError, error, mutate, mutateAsync } = useMutation(
    (body: IMtiRequestGeneratePolicyByPropIdParams) =>
      requestGeneratePolicyByPropId(body)
  );

  const requestGeneratePolicyResult = data?.data ?? null;

  return {
    requestGeneratePolicy: mutate,
    requestGeneratePolicyAsync: mutateAsync,
    requestGeneratePolicyResult,
    isLoading,
    isError,
    error,
  };
};
