import React from "react";
import Label from "../Label";

type Props = {
  className?: string;
  classNameLabel?: string;
  label?: string;
  disabled?: boolean;
  onChange: (e?: React.ChangeEvent<HTMLTextAreaElement> | null) => void;
  value: string | number;
  placeholder?: string;
  errorMsg?: string;
  row?: number;
};

interface ITextAreaConfig {
  [key: string]: string;
}

const textAreaConfig: ITextAreaConfig = {
  default:
    "bg-primary-input-bg border-primary-input-border text-primary-input-text",
  disabled:
    "bg-primary-input-disabled-bg border-primary-input-disabled-border text-primary-input-disabled-text",
  error: "bg-primary-input-bg border-error text-primary-input-text",
};

const TextArea = ({
  label,
  disabled,
  onChange,
  errorMsg,
  className = "",
  classNameLabel = "",
  row = 5,
  ...rest
}: Props) => {
  const isError = !!errorMsg;
  let inputCss = "";
  if (disabled) inputCss = textAreaConfig.disabled;
  else if (isError) inputCss = textAreaConfig.error;
  else inputCss = textAreaConfig.default;

  return (
    <>
      {label && (
        <Label
          disabled={disabled}
          className={classNameLabel}
          data-testid="textarea-label"
        >
          {label}
        </Label>
      )}
      <textarea
        data-testid="textarea-input"
        className={`mt-1 py-2 px-3 block w-full border focus:outline-none rounded-lg placeholder:text-primary-input-placeholder ${inputCss} ${className}`}
        disabled={disabled}
        rows={row}
        onChange={onChange}
        {...rest}
      />
      {isError && (
        <span className="text-error" data-testid="textarea-error">
          {errorMsg}
        </span>
      )}
    </>
  );
};

export default TextArea;
