import { configs } from "configs/config";
import { axios } from "utils/axios";
import {
  IPackage,
  IPackageSuggest,
  IPackageDetail,
  IMtiPackage,
  IMtiPackageSuggest,
  IMtiPackageDetail,
  IMtiPackageListResponse,
  IMtiPackageSuggestListResponse,
  IMtiPackageDetailResponse,
  IMtiPackageListParams,
  IMtiPackageSuggestListParams,
  IMtiPackageDetailParams,
} from "types/package.d";

export const getPackageSuggests = (params: IMtiPackageSuggestListParams) => {
  return axios.get<IMtiPackageSuggestListResponse[]>(
    `${configs.apiUrl}/bpmp2/Cars/PackageSuggests`,
    { params }
  );
};

export const convertPackageSuggestData = (
  packageSuggest: IMtiPackageSuggest
): IPackageSuggest => ({
  agentNumber: packageSuggest.cAgentNumber,
  dChannel: packageSuggest.cDChannel,
  makeCode: packageSuggest.cMakeCode,
  family: packageSuggest.cFamily,
  engineSize: packageSuggest.cEngineSize,
  doorNum: packageSuggest.cDoorNum,
  carYear: packageSuggest.cCarYear,
  bodyConfig: packageSuggest.cBodyConfig,
  carType: packageSuggest.cCarType,
  motorGroup: packageSuggest.cMotorGroup,
  sumInsured: packageSuggest.cSumInsured,
  minSumInsured: packageSuggest.cMinSumInsured,
  maxSumInsured: packageSuggest.cMaxSumInsured,
  coverType: packageSuggest.cCoverType,
  workShop: packageSuggest.cWorkShop,
  useCar: packageSuggest.cUseCar,
  regProv: packageSuggest.cRegProv,
  deductTPAmt: packageSuggest.cDeductTPAmt,
  deductODAmt: packageSuggest.cDeductODAmt,
  accessoryAmt: packageSuggest.cAccessoryAmt,
  driverAge: packageSuggest.cDriverAge,
  effectiveDate: packageSuggest.cEffectiveDate,
  expiryDate: packageSuggest.cExpiryDate,
  pkgList: packageSuggest.cPkgList,
  otherDiscount: packageSuggest.cOtherDiscount,
  ncbDiscount: packageSuggest.cNCBDiscount,
  fleetDiscount: packageSuggest.cFleetDiscount,
  adjustPremium: packageSuggest.cAdjustPremium,
  weight: packageSuggest.cWeight,
  isBigTruck: packageSuggest.cIsBigTruck,
  vehicleCode: packageSuggest.cVehicleCode,
  minWeight: packageSuggest.cMinWeight,
  maxWeight: packageSuggest.cMaxWeight,
  fuelType: packageSuggest.cFuelType,
  sumInsuredStep: packageSuggest.cSumInsuredStep,
});

export const getPackages = (params: IMtiPackageListParams) => {
  return axios.get<IMtiPackageListResponse[]>(
    `${configs.apiUrl}/bpmp2/Cars/Packages`,
    { params }
  );
};

export const convertPackageData = (packageData: IMtiPackage): IPackage => ({
  seq: packageData.seq,
  coverType: packageData.CoverType,
  marketingName: packageData.MarketingName,
  campaignCode: packageData.CampaignCode,
  packageCode: packageData.PackageCode,
  workshop: packageData.Workshop,
  workshopTxt: packageData.WorkshopTxt,
  deductAmountOD: packageData.DeductAmount_OD,
  deductAmountTP: packageData.DeductAmount_TP,
  vehCode: packageData.VEHCODE,
  sumInsure: packageData.Suminsure,
  sumInsureAccessory: packageData.SuminsureAccessory,
  sumInsureCage: packageData.SuminsureCage,
  grossPremiumVol: packageData.GrossPremiumVol,
  vatVol: packageData.VatVol,
  stampVol: packageData.StampVol,
  totPremiumVol: packageData.TotPremiumVol,
  grossPremiumCTP: packageData.GrossPremiumCTP,
  vatCTP: packageData.VatCTP,
  stampCTP: packageData.StampCTP,
  totPremiumCTP: packageData.TotPremiumCTP,
  remark: packageData.Remark,
  buyFlag: packageData.BuyFlag,
  contactType: packageData.ContactType,
  riskType: packageData.RiskType,
  specialAccessoryFlag: packageData.SpecialAccessoryFlag,
  cmiCode: packageData.CMICode,
});

export const getPackageDetail = (params: IMtiPackageDetailParams) => {
  return axios.get<IMtiPackageDetailResponse[]>(
    `${configs.apiUrl}/bpmp2/Cars/PackageCoverDetails`,
    { params }
  );
};

export const convertPackageDetailData = (
  packageDetail: IMtiPackageDetail
): IPackageDetail => ({
  pkgCode: packageDetail.cPkgCode,
  coverType: packageDetail.cCoverType,
  id: packageDetail.cID,
  category: packageDetail.cCateogry,
  prefix: packageDetail.cPrefix,
  name: packageDetail.cName,
  value: packageDetail.cValue,
  unit: packageDetail.cUnit,
});
