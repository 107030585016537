import React, { useEffect } from "react";
import { TFunction } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";

import Select from "components/Input/Select";
import TextField from "components/Input/TextField";
import Calendar from "components/Input/Calendar";

import { usePrefix } from "hooks/useUser";

import Divider from "components/Divider";
import { formatToNumberOnly } from "utils/formatter";

const PREFIX_TYPE = ["P", "C"];

interface IUserFormProps {
  userPrefix?: string;
  t: TFunction;
}

const UserForm = ({ t, userPrefix }: IUserFormProps): JSX.Element => {
  const { control, setValue } = useFormContext();

  const { data: prefixList } = usePrefix({
    type: PREFIX_TYPE[0],
  });

  useEffect(() => {
    if (prefixList?.length && userPrefix) {
      const readPrefixOption = prefixList.find((option) => {
        return option?.prefixDescription === userPrefix;
      });
      if (readPrefixOption) setValue("user.prefix", readPrefixOption);
    }
  }, [prefixList, setValue, userPrefix]);

  return (
    <>
      <h2
        className="text-primary-input-label pb-3"
        data-testid="id-register-user-label"
      >
        {t("id.register.detail")}
      </h2>
      <div className="py-3" data-testid="id-register-user-id">
        <Controller
          control={control}
          name="user.id"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <TextField
                label={t("user.id")}
                onChange={(e) => {
                  const val = formatToNumberOnly(e?.target?.value ?? "");
                  onChange(val);
                }}
                maxLength={13}
                value={value ?? ""}
                placeholder="x-xxxx-xxxxx-xx-x"
                errorMsg={error?.message}
                inputMode="numeric"
              />
            );
          }}
        />
      </div>

      <div className="py-3" data-testid="id-register-user-prefix">
        <Controller
          control={control}
          name="user.prefix"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              label={t("user.prefix")}
              keyTitle={"prefixDescription"}
              onChange={onChange}
              value={value}
              options={prefixList}
              placeholder={t("user.prefix")}
              errorMsg={error?.message}
            />
          )}
        />
      </div>

      <div className="py-3" data-testid="id-register-user-firstName">
        <Controller
          control={control}
          name="user.firstName"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <TextField
                label={t("user.firstName")}
                onChange={onChange}
                value={value ?? ""}
                placeholder={t("user.firstName")}
                errorMsg={error?.message}
              />
            );
          }}
        />
      </div>

      <div className="py-3" data-testid="id-register-user-lastName">
        <Controller
          control={control}
          name="user.lastName"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <TextField
                label={t("user.lastName")}
                onChange={onChange}
                value={value ?? ""}
                placeholder={t("user.lastName")}
                errorMsg={error?.message}
              />
            );
          }}
        />
      </div>

      <div className="py-3" data-testid="id-register-user-birth">
        <Controller
          control={control}
          name="user.birth"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Calendar
                label={t("user.birth")}
                onChange={onChange}
                value={value}
                maxDate={new Date()}
                placeholder={t("user.birth")}
                errorMsg={error?.message}
              />
            );
          }}
        />
      </div>

      <Divider className="my-3 border-gray-50" size="large" />
      <h2
        className="text-primary-input-label py-3"
        data-testid="id-register-user-info-label"
      >
        {t("user.moreInfo")}
      </h2>
      <div className="py-3" data-testid="id-register-user-email">
        <Controller
          control={control}
          name="user.email"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <TextField
                label={t("user.email")}
                onChange={onChange}
                value={value ?? ""}
                placeholder={t("user.email")}
                errorMsg={error?.message}
                inputMode="email"
              />
            );
          }}
        />
      </div>

      <div className="py-3" data-testid="id-register-user-tel">
        <Controller
          control={control}
          name="user.tel"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <TextField
                label={t("user.tel")}
                onChange={(e) => {
                  const val = formatToNumberOnly(e?.target?.value ?? "");
                  onChange(val);
                }}
                maxLength={10}
                value={value ?? ""}
                placeholder={t("user.tel")}
                errorMsg={error?.message}
                inputMode="tel"
              />
            );
          }}
        />
      </div>
    </>
  );
};

export default UserForm;
