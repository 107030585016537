import React from "react";
import { TFunction } from "i18next";

import Select from "components/Input/Select";
import TextField from "components/Input/TextField";
import { ANNO_DOMINI, BUDDHIST_CALENDAR } from "configs/date";
import { ICarInfo } from "types/car-info.d";
import { IPackageForm } from "types/package.d";
import { convertYearString } from "utils/formatter";

export interface CarCommonProps {
  t: TFunction;
  carInfo: ICarInfo | null;
  carPackage: IPackageForm | null;
}

const CarCommonDetail = ({
  t,
  carInfo,
  carPackage,
}: CarCommonProps): JSX.Element => {
  return (
    <>
      <div className="py-3" data-testid="car-summary-brand">
        <Select
          label={t("carInfo.brand")}
          value={carInfo?.brand?.brand}
          options={[]}
          placeholder={t("carInfo.brand")}
          disabled
        />
      </div>
      <div className="py-3" data-testid="car-summary-model">
        <Select
          label={t("carInfo.model")}
          value={carInfo?.model?.modelName}
          options={[]}
          placeholder={t("carInfo.model")}
          disabled
        />
      </div>
      <div className="py-3" data-testid="car-summary-year">
        <Select
          label={t("carInfo.year")}
          value={carInfo?.year?.yearGroup}
          options={[]}
          placeholder={t("carInfo.year")}
          disabled
        />
      </div>
      <div className="py-3" data-testid="car-summary-engineSize">
        <Select
          label={t("carInfo.engineSize")}
          value={carInfo?.model?.engineSize}
          options={[]}
          placeholder={t("carInfo.engineSize")}
          disabled
        />
      </div>
      <div className="py-3" data-testid="car-summary-regProvince">
        <Select
          label={t("carInfo.regProvince")}
          value={carInfo?.regProvince?.province}
          options={[]}
          placeholder={t("carInfo.regProvince")}
          disabled
        />
      </div>
      <div className="py-3" data-testid="car-summary-workshop">
        <Select
          label={t("package.workshop")}
          value={carPackage?.package?.workshopTxt}
          options={[]}
          placeholder={t("package.workshop")}
          disabled
        />
      </div>
      <div className="py-3" data-testid="car-summary-deductAmountTP">
        <Select
          label={t("package.deductAmountTP")}
          value={carPackage?.package?.deductAmountTP.toString()}
          options={[]}
          placeholder={t("package.deductAmountTP")}
          disabled
        />
      </div>
      <div className="py-3" data-testid="car-summary-deductAmountOD">
        <Select
          label={t("package.deductAmountOD")}
          value={carPackage?.package?.deductAmountOD.toString()}
          options={[]}
          placeholder={t("package.deductAmountOD")}
          disabled
        />
      </div>
      <div
        className="py-3"
        data-testid="car-summary-insuranceCoverageStartDate"
      >
        <TextField
          label={t("carInfo.insuranceCoverageStartDate")}
          value={convertYearString(
            ANNO_DOMINI,
            BUDDHIST_CALENDAR,
            carInfo?.insuranceCoverageStartDate
          )}
          placeholder={t("carInfo.insuranceCoverageStartDate")}
          disabled
        />
      </div>
      <div className="py-3" data-testid="car-summary-insuranceCoverageEndDate">
        <TextField
          label={t("carInfo.insuranceCoverageEndDate")}
          value={convertYearString(
            ANNO_DOMINI,
            BUDDHIST_CALENDAR,
            carInfo?.insuranceCoverageEndDate
          )}
          placeholder={t("carInfo.insuranceCoverageEndDate")}
          disabled
        />
      </div>
    </>
  );
};
export default CarCommonDetail;
