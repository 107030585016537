import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import CarImage from "assets/images/common/car-intro.png";
import { useCarDetection } from "hooks/useCarDetection";
import { useAuth } from "hooks/useAuth";
import { useCommonState } from "hooks/useCommonState";
import Button from "components/Button";
import { searchParamsState } from "states/searchParamsState";
import { CAR_DETECTION_PATH } from "configs/page";
import { isPolicyConfirmed } from "utils/car-detection";
import { IAdapterError } from "types/common";
import { AdapterSystem } from "configs/common";

const CarDetectionHomePage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const searchParam = useRecoilValue(searchParamsState);
  const { setAuth, setLoading, setWarningDialog } = useCommonState();
  const { tokenData, isLoading: isLoadingAuth } = useAuth();
  const {
    carDetectionData,
    carDetectionMutate,
    isLoading: isLoadingCarDetection,
  } = useCarDetection();

  const isLoadingAll = isLoadingAuth || isLoadingCarDetection;

  useEffect(() => {
    if (!searchParam || !tokenData) return;

    setAuth(true);
    const refNo = searchParam?.refNo ?? "";
    const carDetectionBody = {
      companyRef: { referenceNo: refNo },
    };

    carDetectionMutate(carDetectionBody, {
      onError(error: IAdapterError) {
        const system = error?.response?.data?.system;
        if (system === AdapterSystem.MTI) {
          setWarningDialog({
            title: t("warning.mti.common"),
            message: t("warning.mti.retry"),
          });
        } else {
          setWarningDialog({
            title: t("warning.internal.common"),
            message: t("warning.internal.retry"),
          });
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, tokenData]);

  useEffect(() => {
    setLoading(isLoadingAll);
  }, [isLoadingAll, setLoading]);

  const handleOnclick = () => {
    navigate(CAR_DETECTION_PATH);
  };

  return (
    <div className="py-6 flex flex-col h-full justify-between">
      <div>
        <h3
          className="h3-semibold text-secondary-text"
          data-testid="cardetection-title"
        >
          {t("carDetection.photoAdvice")}
        </h3>
        <p
          className="body-small text-secondary-text-800"
          data-testid="cardetection-desc"
        >
          {t("carDetection.adviceDetail")}
        </p>
      </div>
      <img
        src={CarImage}
        alt="car detection home page"
        className="max-h-[414px] object-contain my-4"
        data-testid="cardetection-logo"
      />
      <Button
        fullWidth
        variant="gradient"
        color="primary"
        size="large"
        onClick={handleOnclick}
        disabled={
          isLoadingAll ||
          !carDetectionData ||
          !isPolicyConfirmed(carDetectionData.lastMTIStatus)
        }
        data-testid="cardetection-start-btn"
      >
        {t("carDetection.processStart")}
      </Button>
    </div>
  );
};
export default CarDetectionHomePage;
