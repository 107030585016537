import React from "react";

interface ProgressProp {
  percent: number;
  radius?: number;
}

const PADDING_CIRCLE = 5;

const CircularProgressbar = ({
  percent,
  radius = 28,
}: ProgressProp): JSX.Element => {
  const circumference = radius * 2 * Math.PI;
  const width = (radius + PADDING_CIRCLE) * 2;
  const cx = radius + PADDING_CIRCLE;

  const textColorCss = percent ? "text-primary-text-900" : "text-gray-500";

  return (
    <div className="relative">
      <svg width={width} height={width}>
        <circle
          className="text-gray-500"
          strokeWidth="3"
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx={cx}
          cy={cx}
        />
        <circle
          className="text-primary"
          strokeWidth="3"
          strokeDasharray={circumference}
          strokeDashoffset={circumference - (percent / 100) * circumference}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          transform={`rotate(-90 ${cx} ${cx})`}
          r={radius}
          cx={cx}
          cy={cx}
        />
      </svg>
      <span
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold text-primary-text text-sm ${textColorCss}`}
      >
        {percent}%
      </span>
    </div>
  );
};
export default CircularProgressbar;
