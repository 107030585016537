/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Label from "components/Input/Label";
import { RadioGroup } from "@headlessui/react";
import { useTranslation } from "react-i18next";

type Props = {
  className?: string;
  classNameOption?: string;
  classNameLabel?: string;
  name: string;
  label?: string;
  errorMsg?: string;
  disabled?: boolean;
  keyTitle: string;
  onChange: () => void;
  value?: any;
  options?: { [key: string]: any }[] | null;
};

const RadioButton = ({
  className = "",
  classNameOption = "",
  classNameLabel = "",
  label,
  name,
  keyTitle,
  onChange,
  disabled,
  options,
  errorMsg,
  value,
}: Props) => {
  const { t } = useTranslation();
  const isError = !!errorMsg;
  let optionCss = "";
  if (disabled) {
    optionCss +=
      "border-primary-input-disabled-text text-primary-input-disabled-text";
  }

  return (
    <div className={className}>
      {label && (
        <Label
          disabled={disabled}
          className={classNameLabel}
          data-testid="radio-label"
        >
          {label}
        </Label>
      )}
      <RadioGroup
        by={keyTitle}
        name={name}
        value={value}
        onChange={onChange}
        className="flex flex-wrap gap-2"
        data-testid="radio-input"
      >
        {options?.map((option, index) => (
          <RadioGroup.Option
            key={`${name}_${index}`}
            value={option}
            data-testid={`radio-${index}`}
            className={({ checked }) =>
              `${
                checked ? "bg-primary-main-500" : "bg-primary-main-300"
              } py-2 px-4 text-white text-center rounded-xl ${optionCss} ${classNameOption}`
            }
          >
            {t(option?.[keyTitle] || "")}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
      {isError && (
        <span className="text-error" data-testid="radio-error">
          {errorMsg}
        </span>
      )}
    </div>
  );
};

export default RadioButton;
