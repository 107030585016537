import React from "react";

interface Props extends React.ComponentPropsWithoutRef<"button"> {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  type?: "button" | "submit" | "reset";
  size?: "small" | "medium" | "large" | "fit";
  variant?: "contained" | "outlined" | "gradient";
  color?: "primary" | "secondary" | "success" | "error" | "warning";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

interface IButtonConfig {
  [key: string]: string;
}

const buttonConfig: IButtonConfig = {
  "contained-primary": "bg-primary text-white border border-primary",
  "contained-secondary": "bg-secondary text-primary border border-secondary",
  "outlined-primary": "bg-white text-primary border border-primary",
  "outlined-secondary": "bg-white text-secondary border border-secondary",
  "gradient-primary":
    "bg-gradient-to-r from-primary to-primary-main-850 text-white",
  "gradient-secondary":
    "bg-gradient-to-r from-gray-300 to-gray-400 text-primary",

  // size
  large: "py-5 px-6",
  small: "py-1 px-6",
  medium: "py-3 px-6",
  fit: "",
};

const Button = ({
  variant,
  children,
  disabled,
  fullWidth,
  className = "",
  type = "button",
  color = "primary",
  size = "medium",
  ...rest
}: Props) => {
  const buttonVariantCss = buttonConfig[`${variant}-${color}`];
  const buttonSizeCss = buttonConfig[size] || "";
  let buttonCss = "";

  if (disabled) buttonCss = " opacity-60";
  if (fullWidth) buttonCss = `${buttonCss} w-full`;

  return (
    <button
      type={type}
      disabled={disabled}
      className={`whitespace-pre-line flex items-center justify-center shadow-md rounded-xl ${buttonVariantCss} ${buttonCss} ${buttonSizeCss} ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
