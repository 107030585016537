import React from "react";
import { useTranslation } from "react-i18next";
import Card from "components/Card";
import Button from "components/Button";
import { IPackageForm } from "types/package.d";
import { formatNumber } from "utils/number";

type Props = {
  packageData: IPackageForm;
};

const PackageAmountDetail = ({ packageData }: Props) => {
  const { t } = useTranslation();
  const packageDetail = packageData.package;
  const { totPremiumVol, totPremiumCTP, vatVol, stampVol, grossPremiumVol } =
    packageDetail ?? {};

  if (!packageDetail) {
    return null;
  }

  const totalPrice =
    (totPremiumVol ?? 0) + (packageData.hasCTP ? totPremiumCTP ?? 0 : 0);

  return (
    <div data-testid="summary-package-amount">
      <Button
        fullWidth
        className="pointer-events-none p-4 z-10 shadow-top relative"
        size="fit"
        variant="gradient"
        color="secondary"
      >
        <div className="font-semibold flex justify-between items-center w-full">
          <span className="text-base">{t("package.totalPremium")}</span>
          <span className="text-3xl">
            {formatNumber(totalPrice)} {t("thb")}
          </span>
        </div>
      </Button>
      <div className="mt-[-65px]">
        <Card borderColor="none" shadow="top" className="pt-[70px]">
          <div className="flex justify-between">
            <p className="flex-1">
              {t("package.detail.totalPremiumInsurancePrice")}
            </p>
            <p className=" text-primary">
              {formatNumber(grossPremiumVol ?? 0)}{" "}
              <span className="text-gray-500">{t("thb")}</span>
            </p>
          </div>
          <div className="flex justify-between">
            <p className="flex-1">{t("package.detail.duty")}</p>
            <p className=" text-primary">
              {formatNumber(stampVol ?? 0)}{" "}
              <span className="text-gray-500">{t("thb")}</span>
            </p>
          </div>
          <div className="flex justify-between">
            <p className="flex-1">{t("package.detail.taxFullTxt")}</p>
            <p className=" text-primary">
              {formatNumber(vatVol ?? 0)}{" "}
              <span className="text-gray-500">{t("thb")}</span>
            </p>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default PackageAmountDetail;
