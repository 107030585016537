import React from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CAR_PACKAGE_DETAIL_PATH } from "configs/page";
import { driverAgeValue } from "configs/package";
import Button from "components/Button";
import Card from "components/Card";
import Divider from "components/Divider";
import { IPackageData, IPackageSuggest, IPackage } from "types/package.d";
import { formatNumber } from "utils/number";

type Props = {
  carTitle: string;
  isConfirm?: boolean;
  packageData: IPackage & { packageSuggest: IPackageSuggest | null };
  driverAge?: string;
  hasCTP?: boolean;
  cageAmt?: string;
  onClickSelectPackage?: (data: IPackageData) => void;
  onSaveCustomData?: () => void;
};

const PackageCard = ({
  packageData,
  driverAge,
  onClickSelectPackage,
  onSaveCustomData,
  carTitle = "",
  hasCTP = false,
  isConfirm = false,
  cageAmt = "",
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    workshopTxt,
    deductAmountTP,
    deductAmountOD,
    sumInsure,
    totPremiumVol,
    totPremiumCTP,
    packageCode,
    marketingName,
  } = packageData || {};
  const { coverType } = packageData.packageSuggest ?? {};

  const totalPrice = (totPremiumVol || 0) + (hasCTP ? totPremiumCTP || 0 : 0);

  const onClickPackageDetail = () => {
    onSaveCustomData?.();

    const qsPackageDetail = `?${createSearchParams({
      packageCode,
      coverType: coverType ?? "",
      sumInsure: `${sumInsure || ""}`,
      deductAmountTP: `${deductAmountTP || ""}`,
      deductAmountOD: `${deductAmountOD || ""}`,
      hasCTP: hasCTP ? "1" : "",
      cageAmt,
    })}`;

    navigate(
      {
        pathname: CAR_PACKAGE_DETAIL_PATH,
        search: qsPackageDetail,
      },
      { state: { packageData } }
    );
  };

  return (
    <Card borderColor="none" shadow="top">
      <div className="text-center">
        <h1 data-testid="package-type">
          {t("package.insuranceClass")} {coverType}
        </h1>
        <h2 className="text-primary-main-800" data-testid="package-title">
          {carTitle}
        </h2>
        <h6
          className="text-primary-main-800 body-small px-1"
          data-testid="package-marketname"
        >
          {marketingName || ""}
        </h6>
      </div>
      <Divider className="border-primary-main-300 my-2" />
      <div className="flex justify-between gap-1">
        <p className="body-small" data-testid="package-insurancetype">
          {t("package.insuranceFixType")}
        </p>
        <p className="body-small text-primary" data-testid="package-workshop">
          {workshopTxt}
        </p>
      </div>
      <div className="flex justify-between gap-1">
        <p className="body-small flex-1" data-testid="package-amountOD">
          {t("package.deductAmountODTxt")}
        </p>
        <p className="body-small text-primary" data-testid="package-amountODV">
          {formatNumber(deductAmountOD)}{" "}
          <span className="text-gray-500">{t("thb")}</span>
        </p>
      </div>
      {isConfirm && (
        <div className="flex justify-between gap-1">
          <p className="body-small">{t("package.driverAge")}</p>
          <p className="body-small text-primary">
            {driverAge ? t(driverAgeValue[driverAge]) : t("undefined")}
            <span className="text-gray-500"></span>
          </p>
        </div>
      )}
      <div className="flex justify-between gap-1">
        <p className="body-small flex-1" data-testid="package-insured">
          {t("package.insured")}
        </p>
        <p className="body-small text-primary" data-testid="package-insuredV">
          {formatNumber(sumInsure)}{" "}
          <span className="text-gray-500">{t("thb")}</span>
        </p>
      </div>
      <div className="flex justify-between gap-1">
        <p className="body-small flex-1" data-testid="package-premiumTotal">
          {t("package.premiumTotal")}
        </p>
        <p
          className="body-small text-primary"
          data-testid="package-premiumTotalV"
        >
          {formatNumber(totPremiumVol)}{" "}
          <span className="text-gray-500">{t("thb")}</span>
        </p>
      </div>
      {hasCTP && (
        <div
          className="flex justify-between gap-1"
          data-testid="package-actTotal"
        >
          <p className="body-small flex-1">{t("package.actTotal")}</p>
          <p className="body-small text-primary">
            {formatNumber(totPremiumCTP)}{" "}
            <span className="text-gray-500">{t("thb")}</span>
          </p>
        </div>
      )}
      {!isConfirm && (
        <>
          <div className="flex justify-between gap-1">
            <p className="body-small flex-1" data-testid="package-netPremium">
              {t("package.netPremium")}
            </p>
            <p
              className="body-small text-primary"
              data-testid="package-netPremiumV"
            >
              {formatNumber(totalPrice)}{" "}
              <span className="text-gray-500">{t("thb")}</span>
            </p>
          </div>

          <div className="flex gap-3 justify-center py-3">
            <Button
              variant="gradient"
              color="secondary"
              onClick={onClickPackageDetail}
              data-testid="package-detail-btn"
            >
              {t("detail")}
            </Button>
            <Button
              variant="gradient"
              color="primary"
              onClick={() => onClickSelectPackage?.(packageData)}
              data-testid="package-buy-btn"
            >
              {t("package.buy")}
            </Button>
          </div>
        </>
      )}
    </Card>
  );
};

export default PackageCard;
