import React from "react";

type Props = {
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
};

const Label = ({ children, className = "", disabled, ...rest }: Props) => {
  let textCss = "text-primary-input-label";
  if (disabled) textCss = "text-primary-input-disabled-label";

  return (
    <label className={`block ${textCss} ${className}`} {...rest}>
      {children}
    </label>
  );
};

export default Label;
