import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { oneTimeTokenByApiKey } from "services/carai/auth";
import { IOneTimeTokenResponse } from "types/auth.d";
import { useCommonState } from "hooks/useCommonState";
import { setAxiosHeaderAuthorization } from "utils/axios";

export const useAuth = (): {
  tokenData: IOneTimeTokenResponse | null;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
} => {
  const { t } = useTranslation();
  const { setWarningDialog } = useCommonState();

  const { data, isLoading, isError, error } = useQuery(
    ["one-time-token"],
    oneTimeTokenByApiKey,
    {
      cacheTime: 60 * 60 * 1000, // 60 mins
      onSuccess: (resp) => {
        const token = resp.data.accessToken.token;
        setAxiosHeaderAuthorization(token);
      },
      onError: () => {
        setWarningDialog({
          title: t("warning.internal.common"),
          message: t("warning.internal.retry"),
        });
      },
    }
  );

  const tokenData = data?.data ?? null;

  return {
    tokenData,
    isLoading,
    isError,
    error,
  };
};
