/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from "@sentry/react";
import { AxiosResponse } from "axios";
import { configs } from "configs/config";
import { ILogExtra, ILogTag } from "configs/logging";

const filterKeyword = {
  username: "username",
  password: "password",
  Authorization: "Authorization",
  apikey: "apikey",
  userId: "userId",
  keyId: "keyId",
  InsuredUniqueID: "InsuredUniqueID",
  InsuredName: "InsuredName",
  InsuredSurname: "InsuredSurname",
  MobilePhoneNumber: "MobilePhoneNumber",
  EmailAddr: "EmailAddr",
  ChassisSerialNumber: "ChassisSerialNumber",
  EngineSerialNumber: "EngineSerialNumber",
  Registration: "Registration",
  Receipt_InsuredUniqueID: "Receipt_InsuredUniqueID",
  Receipt_LicenseNumber: "Receipt_LicenseNumber",
  Receipt_InsuredName: "Receipt_InsuredName",
  Receipt_InsuredSurname: "Receipt_InsuredSurname",
  Receipt_MobilePhoneNumber: "Receipt_MobilePhoneNumber",
  Receipt_EmailAddr: "Receipt_EmailAddr",
  Delivery_Name: "Delivery_Name",
  Delivery_Surname: "Delivery_Name",
  Delivery_EmailAddr: "Delivery_EmailAddr",
  Driver1_InsuredName: "Driver1_InsuredName",
  Driver1_InsuredSurname: "Driver1_InsuredSurname",
  Driver1_InsuredUniqueID: "Driver1_InsuredUniqueID",
  Driver1_LicenseNumber: "Driver1_LicenseNumber",
  Driver1_MobilePhoneNumber: "Driver1_MobilePhoneNumber",
  Driver1_EmailAddr: "Driver2_EmailAddr",
  Driver2_InsuredName: "Driver2_InsuredName",
  Driver2_InsuredSurname: "Driver2_InsuredSurname",
  Driver2_InsuredUniqueID: "Driver2_InsuredUniqueID",
  Driver2_LicenseNumber: "Driver2_LicenseNumber",
  Driver2_MobilePhoneNumber: "Driver2_MobilePhoneNumber",
  Driver2_EmailAddr: "Driver2_EmailAddr",
  EPolicyTo: "EPolicyTo",
};

const filterTo = "***filtered***";

const filterObjectByKeys = (
  filterKeys: object,
  newVal: string,
  object: object
) => {
  let results: any = {};
  for (const filterKey of Object.values(filterKeys)) {
    if (Object.keys(results).length === 0)
      results = filterObjectByKey(filterKey, newVal, object);
    else results = filterObjectByKey(filterKey, newVal, results);
  }
  return results;
};

const filterObjectByKey = (filterKey: string, newVal: string, object: any) => {
  const results: any = {};
  for (const key in object) {
    if (key === filterKey) {
      results[key] = newVal;
    } else if (typeof object[key] === "object" && object[key] !== null) {
      results[key] = filterObjectByKey(filterKey, newVal, object[key]);
    } else {
      results[key] = object[key];
    }
  }
  return results;
};

export const initLogging = () => {
  if (!configs.sentryLogKey) return;
  Sentry.init({
    dsn: configs.sentryLogKey,
    integrations: [Sentry.browserTracingIntegration()],
    environment: configs.env || "development",
    normalizeDepth: 9,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // below handle event before sent
    // beforeSend(event) {
    //   return event;
    // },
  });
};

const setLogScope = (extras?: ILogExtra, tag?: ILogTag) => {
  const scope = new Sentry.Scope();
  if (!configs.sentryLogKey) return scope;
  if (tag)
    Object.entries(tag).forEach(([k, v]) => {
      if (v) scope.setTag(k, v);
    });
  if (extras) {
    const filterExtra = filterObjectByKeys(filterKeyword, filterTo, extras);
    scope.setExtras(filterExtra);
  }
  return scope;
};

export const captureLogException = (
  error: unknown,
  tag?: ILogTag,
  extras?: ILogExtra
) => {
  if (!configs.sentryLogKey) return;
  const scope = setLogScope(extras, tag);
  Sentry.captureException(error, scope);
};

export const captureLogMessage = (
  msg: string,
  tag?: ILogTag,
  extras?: ILogExtra
) => {
  Sentry.withScope((scope) => {
    if (tag)
      Object.entries(tag).forEach(([k, v]) => {
        if (v) scope.setTag(k, v);
      });
    if (extras) {
      const filterExtra = filterObjectByKeys(filterKeyword, filterTo, extras);
      scope.setExtras(filterExtra);
    }
    Sentry.captureMessage(msg);
  });
};

export const createExtras = (
  body: object,
  resp: AxiosResponse<unknown, unknown>
) => {
  return {
    bodyParam: body,
    respData: resp?.data,
    status: resp?.status,
    header: resp?.headers,
  };
};
