import React from "react";
import {
  CheckCircleIcon,
  CircleBorderIcon,
  RadioActiveIcon,
} from "components/Icon/Common";

interface Props {
  currentStep: number;
  maxStep: number;
  className?: string;
}

const SIZE = 17;

const Stepper = ({ currentStep, maxStep, className = "" }: Props) => {
  const current = currentStep;

  const renderStatusIcon = (type = "") => {
    if (type === "complete") {
      return <CheckCircleIcon width={SIZE} height={SIZE} />;
    } else if (type === "active") {
      return <RadioActiveIcon width={SIZE} height={SIZE} />;
    } else {
      return <CircleBorderIcon width={SIZE} height={SIZE} />;
    }
  };

  return (
    <div className="flex justify-between" data-testid="header-stepper">
      {[...Array(maxStep)].map((_, index) => {
        const step = index + 1;
        const isComplete = step < current;
        const isActive = step === current;
        let type = "";
        if (isComplete) type = "complete";
        else if (isActive) type = "active";

        return (
          <div
            key={index}
            className={`stepper-item ${isActive ? "active" : ""}${
              isComplete ? "complete" : ""
            } ${className}`}
            data-testid={`header-stepper-${index}`}
          >
            <div className="step" data-testid={`header-stepper-${index}-icon`}>
              {renderStatusIcon(type)}
            </div>
            <p
              className="text-gray-700 text-sm font-light"
              data-testid={`header-stepper-${index}-step`}
            >
              Step {step}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
