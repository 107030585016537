import { useQuery } from "@tanstack/react-query";
import { getYears, convertYearData } from "services/mti/year";
import { IMtiYearListParams, IYearListQueryKey } from "types/year.d";

export const useYears = (params: IMtiYearListParams, isAutoFetch = true) => {
  const queryKey: IYearListQueryKey = ["years", params];
  const { data, isLoading, isError, error, refetch } = useQuery(
    queryKey,
    getYears,
    { enabled: isAutoFetch }
  );

  const res = data?.data?.[0] ?? null;
  const models = res?.CarYears?.map(convertYearData);

  return { data: models, isLoading, isError, error, refetch };
};
