import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IDCardImg from "assets/images/common/id-card.png";
import { CameraAddIcon, KeyboardIcon } from "components/Icon/Common";
import { ID_PREVIEW_PATH, ID_REGISTER_PATH } from "configs/page";
import Button from "components/Button";

const IDHomePage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-between items-center h-full portrait:min-h-[680px] landscape:min-h-[360px]">
      <div className="flex flex-1 justify-center w-full origin-top">
        <div className="relative flex justify-center bg-gradient-to-t from-primary-main-500 to-primary-main-900 rounded-b-[126.5px] landscape:rounded-b-[126.5px] landscape:lg:rounded-b-[226.5px] w-full portrait:md:h-[70%] portrait:md:rounded-b-[200px] h-[60%] landscape:h-[80%] landscape:w-[50%]">
          <img
            src={IDCardImg}
            alt="logo"
            className="z-10 absolute top-0 landscape:-top-8 landscape:mt-0 portrait:w-full landscape:max-w-[100%] max-h-[200%] object-contain w-[100%]"
            data-testid="id-logo"
          />
        </div>
      </div>
      <div className="flex flex-col flex-1 text-center mx-6 p-3 pb-16 landscape:pb-3 w-full justify-end">
        <h1 className="h1-title" data-testid="id-header">
          {t("id.register.header")}
        </h1>
        <h1 className="text-primary-text-700 m-3" data-testid="id-title">
          {t("id.register.title")}
        </h1>
        <div className="flex flex-col landscape:flex-row landscape:mt-0 landscape:w-full landscape:justify-center landscape:items-start gap-4 my-4">
          <Link
            to={ID_PREVIEW_PATH}
            className="flex-1"
            data-testid="id-camera-btn"
          >
            <Button variant="gradient" fullWidth color="primary" size="large">
              <span data-testid="id-camera-text">
                {t("id.register.byImage")}
              </span>
              <CameraAddIcon
                className="ml-4"
                width={24}
                height={24}
                data-testid="id-camera-icon"
              />
            </Button>
          </Link>
          <Link
            to={ID_REGISTER_PATH}
            className="flex flex-1 justify-center h-full"
            data-testid="id-typing-btn"
          >
            <Button variant="gradient" fullWidth color="secondary" size="large">
              <span data-testid="id-typing-text">
                {t("id.register.manual")}
              </span>
              <KeyboardIcon
                className="ml-4"
                width={24}
                height={24}
                data-testid="id-typing-icon"
              />
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default IDHomePage;
