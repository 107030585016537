import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { searchParamsState } from "states/searchParamsState";
import Card from "components/Card";
import Qr from "components/Qr";
import Button from "components/Button";
import Logo from "assets/images/logos/logo-with-text.png";
import { configs } from "configs/config";
import { agentNumber, FlowType } from "configs/common";
import { CAR_DETECTION_HOME_PATH, NOTFOUND_PATH } from "configs/page";
import { useResetState } from "hooks/useResetState";

const OrderSuccessPage = (): JSX.Element => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { logoutReset } = useResetState();
  const navigate = useNavigate();
  const [searchState, setSearchState] = useRecoilState(searchParamsState);
  const currentParams = Object.fromEntries([...searchParams]);
  const refNo = currentParams?.refNo;

  // same format with mti
  const path = `?flow=${FlowType.CarDetection}&refNo=${refNo}&agent=${
    searchState?.agent ?? agentNumber.mti
  }`;
  const fullLink = `${configs.appUrl}${path}`;

  useEffect(() => {
    if (!refNo) navigate(NOTFOUND_PATH);
  }, [navigate, refNo]);

  useEffect(() => {
    logoutReset(false);
  }, [logoutReset]);

  const handleClick = () => {
    setSearchState({ ...searchState, refNo });

    navigate(`${CAR_DETECTION_HOME_PATH}${path}`);
  };

  return (
    <>
      <Card
        borderColor="primary"
        backgroundColor="gray"
        data-testid="summary-result"
      >
        <div className="flex items-center flex-col">
          <img
            src={Logo}
            alt="order success icon"
            width="64"
            data-testid="summary-result-logo"
          />
          <h1 className="text-center py-3" data-testid="summary-result-title">
            {t("order.success.title")}
          </h1>
          <h1 className="text-center py-2" data-testid="summary-result-desc">
            {t("order.success.nextProcess")}
          </h1>
          <h2
            className="text-primary text-center"
            data-testid="summary-result-desc2"
          >
            {t("order.success.detail")}
          </h2>
          <div className="bg-white w-[120px] h-[120px] flex justify-center items-center mt-2">
            <Qr value={fullLink} size={90} />
          </div>
        </div>
      </Card>
      <div className="py-5">
        <Button
          variant="gradient"
          color="primary"
          size="large"
          fullWidth
          onClick={handleClick}
          data-testid="summary-result-start-btn"
          disabled={!refNo}
        >
          {t("carDetection.start")}
        </Button>
      </div>
    </>
  );
};

export default OrderSuccessPage;
