import * as Icons from "components/Icon/Car";

// position format by KX Backend
export const carConfig = {
  front: {
    label: "car.position.front",
    position: "front",
    icon: Icons.CarFrontIcon,
  },
  frontLeft: {
    label: "car.position.frontLeft",
    position: "frontLeft",
    icon: Icons.CarFrontLeftIcon,
  },
  frontRight: {
    label: "car.position.frontRight",
    position: "frontRight",
    icon: Icons.CarFrontRightIcon,
  },
  left: {
    label: "car.position.left",
    position: "left",
    icon: Icons.CarLeftIcon,
  },
  right: {
    label: "car.position.right",
    position: "right",
    icon: Icons.CarRightIcon,
  },
  backLeft: {
    label: "car.position.backLeft",
    position: "backLeft",
    icon: Icons.CarBackLeftIcon,
  },
  backRight: {
    label: "car.position.backRight",
    position: "backRight",
    icon: Icons.CarBackRightIcon,
  },
  back: {
    label: "car.position.back",
    position: "back",
    icon: Icons.CarBackIcon,
  },
  dashboard: {
    label: "car.dashboard",
    position: "dashboard",
    icon: Icons.CarMeterIcon,
  },
};

export const carConfigLength = 9;

export type ICarConfig = typeof carConfig.front;

export const carPolicyStatus = {
  waitingPayment: "1", // แจ้งงานทำประกัน
  confirm: "2", // ยืนยันการทำประกัน
  imageReceive: "3", // ได้รับรูปแล้ว
  cancel: "4", // ยกเลิกรายการ
  error: "5", // งานติดปัญหา
  pass: "6", // โอนเข้าระบบ
};

export enum LicensePlateType {
  red = "RED",
  general = "GENERAL",
}

export enum PolicyTypeCd {
  CMI = "P",
}

export enum CarInsuranceType {
  VMI = "VMI", // ประกันรถภาคสมัครใจ
  CMI = "CMI", // ประกันรถภาคบังคับ (พรบ)
}

export enum AiFailReason {
  FailImageType = "FailImageType",
  FailObjectDetection = "FailObjectDetection",
  FailCheckDistance = "FailCheckDistance",
  FailCheckDistanceTooFar = "FailCheckDistanceTooFar",
  FailCheckDistanceTooClose = "FailCheckDistanceTooClose",
  FailSide = "FailSide",
  FailBrightness = "FailBrightness",
  FailSharpness = "FailSharpness",
  FailLicensePlate = "FailLicensePlate",
  FailOcclusion = "FailOcclusion",
  FailDamageDetection = "FailDamageDetection",
  FailDamageDetectionLabel = "FailDamageDetectionLabel",
}

export enum AiFailType {
  fail = "fail",
  unavailable = "unavailable",
  none = "",
}
