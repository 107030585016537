import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { carPackageState } from "states/carPackageState";
import { carInfoState } from "states/carInfoState";
import { formatNumber } from "utils/number";
import { getCarTitle } from "utils/formatter";
import Button from "components/Button";
import DialogContactStaff from "components/Dialog/DialogContactStaff";
import PackageCard from "./components/PackageCard";
import PackageNotFound from "./components/PackageNotFound";
import { CAR_SUMMARY_PATH } from "configs/page";
import { MailContactType } from "configs/common";

const PackagePage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const carPackageSelected = useRecoilValue(carPackageState);
  const carInfo = useRecoilValue(carInfoState);
  const [openContactStaffDialog, setOpenContactStaffDialog] =
    useState<boolean>(false);
  const carTitle = getCarTitle(carInfo);
  const packageData = carPackageSelected?.package;
  const hasCTP = !!carPackageSelected?.hasCTP;
  const driverAge = `${
    carPackageSelected?.customPackage?.driverAge?.value ?? ""
  }`;
  const totalPrice = formatNumber(
    (packageData?.totPremiumVol ?? 0) +
      (hasCTP ? packageData?.totPremiumCTP ?? 0 : 0)
  );

  const onConfirmPackage = () => {
    navigate(CAR_SUMMARY_PATH);
  };

  if (!carPackageSelected) {
    return (
      <div className="pt-6">
        <PackageNotFound title={t("package.suggest.notFound")} />
      </div>
    );
  }

  return (
    <>
      {packageData && (
        <PackageCard
          isConfirm
          carTitle={carTitle}
          packageData={packageData}
          hasCTP={!!carPackageSelected?.hasCTP}
          driverAge={driverAge}
        />
      )}
      <Button
        fullWidth
        size="large"
        variant="gradient"
        color="secondary"
        className="my-5 text-primary-text pointer-events-none"
        data-testid="package-summary"
      >
        <div className="w-full flex items-center justify-between">
          <h3 className="text-primary h3-semibold">
            {t("package.totalPremium")}
          </h3>
          <h1 className="text-primary">
            {totalPrice} {t("thb")}
          </h1>
        </div>
      </Button>
      <div className="pt-10">
        <Button
          fullWidth
          size="large"
          variant="gradient"
          color="primary"
          className="my-5"
          onClick={onConfirmPackage}
          data-testid="package-confirm-btn"
        >
          {t("confirmBtn")}
        </Button>
        <Button
          fullWidth
          size="large"
          variant="gradient"
          color="secondary"
          className="my-5"
          onClick={() => {
            setOpenContactStaffDialog(true);
          }}
          data-testid="package-contact-btn"
        >
          {t("contactAdmin")}
        </Button>
      </div>
      <DialogContactStaff
        contactType={MailContactType.InsuranceInterested}
        open={openContactStaffDialog}
        onCloseDialog={() => {
          setOpenContactStaffDialog(false);
        }}
      />
    </>
  );
};

export default PackagePage;
