import React from "react";
import { useTranslation } from "react-i18next";
import { configs } from "configs/config";

interface Props {
  showDetail?: boolean;
}
const ContactDetail = ({ showDetail = true }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {showDetail && (
        <h2
          className="text-primary font-semibold text-center"
          data-testid="contact-desc"
        >
          {t("contact.detail")}
        </h2>
      )}
      <h1
        className="text-primary pt-1 text-center"
        data-testid="contact-number"
      >
        {configs.contactNumber}
      </h1>
      <h1 className="text-primary text-center" data-testid="contact-officehour">
        {t("contact.officeHours", { officeHours: configs.officeHours })}
      </h1>
      <p
        className="text-primary text-sm font-semibold text-center"
        data-testid="contact-email"
      >
        {configs.contactEmail}
      </p>
    </>
  );
};

export default ContactDetail;
