import { carConfig } from "configs/car";
import idCardContour from "assets/images/contour/id-card.png";
import carContourFront from "assets/images/car-contour/front.svg";
import carContourFrontLeft from "assets/images/car-contour/front-left.svg";
import carContourFrontRight from "assets/images/car-contour/front-right.svg";
import carContourBack from "assets/images/car-contour/back.svg";
import carContourBackLeft from "assets/images/car-contour/back-left.svg";
import carContourBackRight from "assets/images/car-contour/back-right.svg";
import carContourLeft from "assets/images/car-contour/left.svg";
import carContourRight from "assets/images/car-contour/right.svg";

export enum CameraType {
  CarBook = "car-book",
  IdCard = "id-card",
  CarPosition = "car-position",
}
interface ICameraValue {
  key: string;
  img: string;
  width: number;
  height: number;
  ratioW: number;
  ratioH: number;
  aspectRatio: number;
}

interface ICameraConfig {
  [key: string]: ICameraValue;
}

interface ICarPositionContour {
  [key: string]: string;
}

export const cameraConfig: ICameraConfig = {
  [CameraType.IdCard]: {
    key: CameraType.IdCard,
    img: idCardContour,
    width: 960,
    height: 720,
    ratioW: 4,
    ratioH: 3,
    aspectRatio: 1.33333333,
  },
  [CameraType.CarBook]: {
    key: CameraType.CarBook,
    img: "",
    width: 960,
    height: 720,
    ratioW: 4,
    ratioH: 3,
    aspectRatio: 1.33333333,
  },
  [CameraType.CarPosition]: {
    key: CameraType.CarPosition,
    img: "",
    width: 1280,
    height: 720,
    ratioW: 16,
    ratioH: 9,
    aspectRatio: 1.77777777778,
  },
};

export const carPositionContour: ICarPositionContour = {
  [carConfig.front.position]: carContourFront,
  [carConfig.frontLeft.position]: carContourFrontLeft,
  [carConfig.frontRight.position]: carContourFrontRight,
  [carConfig.back.position]: carContourBack,
  [carConfig.backLeft.position]: carContourBackLeft,
  [carConfig.backRight.position]: carContourBackRight,
  [carConfig.left.position]: carContourLeft,
  [carConfig.right.position]: carContourRight,
};

export enum imageType {
  jpg = "image/jpeg",
  png = "image/png",
}
