import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  driverAgeOptions,
  workshopOptions,
  workshopOptionDefault,
  deductAmountODOptions,
  deductAmountTPOptions,
  useCarOptions,
  carTypeValue,
  CarType,
} from "configs/package";
import Button from "components/Button";
import Select from "components/Input/Select";
import TextField from "components/Input/TextField";
import CheckBox from "components/Input/CheckBox";
import RadioButton from "components/Input/Radio/RadioButton";
import {
  IPackageData,
  IPackageCustomForm,
  IPackageSuggest,
} from "types/package.d";
import { IOptionPackageConfig } from "types/common.d";
import { formatNumber } from "utils/number";

type Props = {
  customPackage: IPackageCustomForm | null;
  packageData: IPackageData;
  packageSuggest: IPackageSuggest | null;
  onSubmit: (filter: IPackageCustomForm) => void;
  onClear: (value: IPackageCustomForm) => void;
};

const PackageCustomForm = ({
  customPackage,
  packageData,
  packageSuggest,
  onSubmit: onSubmitForm,
  onClear: onClearForm,
}: Props) => {
  const { t } = useTranslation();

  const sumInsuredOptionDefault: IOptionPackageConfig | null =
    packageSuggest?.sumInsured
      ? {
          name: formatNumber(+packageSuggest.sumInsured),
          value: +packageSuggest.sumInsured,
        }
      : null;

  const useCarOptionDefault: IOptionPackageConfig | null =
    packageSuggest?.useCar
      ? useCarOptions.find(
          (option) => +packageSuggest.useCar === option.value
        ) ?? null
      : null;

  const getCarTypeOptions = (): IOptionPackageConfig[] => {
    if (!packageSuggest?.carType) return [];

    return packageSuggest.carType.split(",").map((typeValue) => ({
      name: carTypeValue[typeValue] || "",
      value: +typeValue,
    }));
  };
  const carTypeOptions = getCarTypeOptions();

  const defaultValues: IPackageCustomForm = {
    sumInsured: customPackage?.sumInsured ?? sumInsuredOptionDefault,
    deductAmountOD: customPackage?.deductAmountOD ?? null,
    deductAmountTP: customPackage?.deductAmountTP ?? null,
    carType: customPackage?.carType ?? carTypeOptions[0] ?? null,
    workshop: customPackage?.workshop ?? workshopOptionDefault ?? null,
    driverAge: customPackage?.driverAge ?? driverAgeOptions[0] ?? null,
    hasCTP: customPackage?.hasCTP ?? false,
    promotion: customPackage?.promotion ?? "",
    isCageAmt: customPackage?.isCageAmt ?? false,
    useCar: customPackage?.useCar ?? useCarOptionDefault,
  };

  const schema = yup
    .object({
      sumInsured: yup.object().nullable(),
      deductAmountOD: yup.object().nullable(),
      deductAmountTP: yup.object().nullable(),
      carType: yup
        .object()

        .nullable(),
      workshop: yup.object().nullable(),
      driverAge: yup.object().nullable(),
      hasCTP: yup.boolean(),
      promotion: yup.string(),
    })
    .required();

  const { control, watch, handleSubmit, setValue } =
    useForm<IPackageCustomForm>({
      resolver: yupResolver(schema),
      defaultValues,
    });
  const watchCarType = watch("carType");

  useEffect(() => {
    if (watchCarType?.value !== +CarType.Pickup) {
      setValue("isCageAmt", false);
    }
  }, [watchCarType, setValue]);

  const onSubmit = (dataForm: IPackageCustomForm) => {
    onSubmitForm(dataForm);
  };

  const onClear = () => {
    // set default value
    onClearForm({
      sumInsured: sumInsuredOptionDefault,
      deductAmountOD: null,
      deductAmountTP: null,
      carType: carTypeOptions[0] || null,
      workshop: workshopOptionDefault,
      driverAge: driverAgeOptions[0] || null,
      hasCTP: false,
      promotion: "",
      isCageAmt: false,
      useCar: useCarOptionDefault,
    });
  };

  const getSumInsuredOptions = () => {
    if (!packageSuggest) return [];
    const max = +packageSuggest.maxSumInsured;
    const step = +packageSuggest.sumInsuredStep;
    const options = [];
    let option = +packageSuggest.minSumInsured;

    while (option <= max) {
      options.push({
        name: formatNumber(option),
        value: option,
      });
      option += step;
    }
    return options;
  };

  const sumInsuredOptions = getSumInsuredOptions();

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="py-2" data-testid="package-setting-sumInsured">
          <Controller
            control={control}
            name="sumInsured"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                label={t("package.sumInsured")}
                keyTitle="name"
                onChange={onChange}
                value={value}
                options={sumInsuredOptions}
                placeholder={t("package.sumInsured")}
                errorMsg={error?.message}
              />
            )}
          />
        </div>
        <div className="py-2" data-testid="package-setting-deductAmountTP">
          <Controller
            control={control}
            name="deductAmountTP"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                label={t("package.deductAmountTP")}
                keyTitle="name"
                onChange={onChange}
                value={value}
                options={deductAmountTPOptions}
                placeholder={t("package.deductAmountTP")}
                errorMsg={error?.message}
              />
            )}
          />
        </div>
        <div className="py-2" data-testid="package-setting-deductAmountOD">
          <Controller
            control={control}
            name="deductAmountOD"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                label={t("package.deductAmountOD")}
                keyTitle="name"
                onChange={onChange}
                value={value}
                options={deductAmountODOptions}
                placeholder={t("package.deductAmountOD")}
                errorMsg={error?.message}
              />
            )}
          />
        </div>
        <div className="py-2" data-testid="package-setting-carType">
          <Controller
            control={control}
            name="carType"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <RadioButton
                label={t("package.carType")}
                name="carType"
                keyTitle="name"
                onChange={onChange}
                value={value}
                options={carTypeOptions}
                errorMsg={error?.message}
              />
            )}
          />
        </div>
        {watchCarType?.value === CarType.Pickup && (
          <div className="py-2" data-testid="package-setting-isCageAmt">
            <Controller
              control={control}
              name="isCageAmt"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <CheckBox
                    name="isCageAmt"
                    onChange={onChange}
                    checked={value}
                    errorMsg={error?.message}
                  >
                    <p>{t("package.cageAmt")}</p>
                  </CheckBox>
                );
              }}
            />
          </div>
        )}
        <div className="py-2" data-testid="package-setting-useCar">
          <Controller
            control={control}
            name="useCar"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <RadioButton
                label={t("package.useCar")}
                name="useCar"
                keyTitle="name"
                onChange={onChange}
                value={value}
                options={useCarOptions}
                errorMsg={error?.message}
              />
            )}
          />
        </div>
        <div className="py-2" data-testid="package-setting-workshop">
          <Controller
            control={control}
            name="workshop"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <RadioButton
                label={t("package.workshop")}
                name="workshop"
                keyTitle="name"
                onChange={onChange}
                value={value}
                options={workshopOptions}
                errorMsg={error?.message}
              />
            )}
          />
        </div>
        <div className="py-2" data-testid="package-setting-driverAge">
          <Controller
            control={control}
            name="driverAge"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <RadioButton
                classNameOption="w-[100px]"
                label={t("package.driverAgeSelect")}
                name="driverAge"
                keyTitle="name"
                onChange={onChange}
                value={value}
                options={driverAgeOptions}
                errorMsg={error?.message}
              />
            )}
          />
        </div>
        <div className="py-2" data-testid="package-setting-hasCTP">
          <Controller
            control={control}
            name="hasCTP"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <CheckBox
                  name="hasCTP"
                  onChange={onChange}
                  checked={value}
                  errorMsg={error?.message}
                >
                  <p data-testid="checkbox-value">
                    {t("package.buyCTP", {
                      totPremium: packageData?.totPremiumCTP || "0",
                    })}
                  </p>
                </CheckBox>
              );
            }}
          />
        </div>
        <div className="py-2" data-testid="package-setting-promotion">
          <Controller
            control={control}
            name="promotion"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  label={t("package.promotion")}
                  onChange={onChange}
                  value={value}
                  placeholder={t("package.promotion")}
                  helperText={t("package.promotion.helper")}
                  errorMsg={error?.message}
                />
              );
            }}
          />
        </div>
        <div className="flex gap-3 justify-center py-3">
          <Button
            variant="gradient"
            color="secondary"
            onClick={onClear}
            data-testid="package-setting-clear-btn"
          >
            {t("clearBtn")}
          </Button>
          <Button
            variant="gradient"
            color="primary"
            type="submit"
            data-testid="package-setting-submit-btn"
          >
            {t("okBtn")}
          </Button>
        </div>
      </form>
      <DevTool control={control} />
    </>
  );
};

export default PackageCustomForm;
