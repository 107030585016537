import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LANDING_PATH } from "configs/page";
import DetailCard from "./components/DetailCard";
import DetailContact from "components/Contact";

interface ILocationState {
  showCloseBtn: boolean;
}

const MailSuccessPage = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const locationState = location.state as ILocationState;
  const showCloseBtn = locationState?.showCloseBtn || false;

  const onClose = () => {
    window.location.href = LANDING_PATH;
  };

  return (
    <div className="flex items-center h-full w-full">
      <DetailCard onClose={showCloseBtn ? onClose : undefined}>
        <>
          <h1
            className="text-center py-3 mb-4"
            data-testid="modal-mailsuccess-title"
          >
            {t("summary.contactAdmin.receiveDetail")}
          </h1>
          <DetailContact />
        </>
      </DetailCard>
    </div>
  );
};

export default MailSuccessPage;
