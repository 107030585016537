import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageIcon } from "components/Icon/Common";
import Button from "components/Button";

interface Props {
  src?: string | null;
  alt?: string;
  className?: string;
  onSubmit?: () => void;
  onRetake?: () => void;
  setImagePreview?: (value: string) => void;
  debug?: boolean;
}

const ImagePreview = ({
  src = "",
  alt,
  className,
  onSubmit,
  onRetake,
  setImagePreview,
  debug,
}: Props) => {
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);

  const onError = () => {
    if (!isError) {
      setIsError(true);
    }
  };

  // for test ocr version upload Image
  const onUploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    const file = e?.target?.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImagePreview?.(reader.result as string);
      };
    }
  };

  return (
    <div className={`flex flex-col items-center ${className ?? ""}`}>
      <div className="mb-8" data-testid="imagepreview-img">
        {isError || !src ? (
          <div className="flex bg-gray-600 w-[240px] h-[320px] justify-center items-center">
            <ImageIcon />
          </div>
        ) : (
          <img
            className={`h-[320px] w-full object-contain m-auto`}
            src={src || ""}
            alt={alt}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              onError();
            }}
          />
        )}
      </div>
      {debug && (
        <>
          <label
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            htmlFor="file_input"
          >
            Upload file
          </label>
          <input
            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            id="file_input"
            type="file"
            accept="image/*"
            onChange={onUploadImage}
          />
        </>
      )}
      <Button
        onClick={onSubmit}
        type="submit"
        fullWidth
        variant="gradient"
        color="primary"
        className="my-4"
        size="large"
        data-testid="imagepreview-confirm-btn"
      >
        {t("confirmPhotoBtn")}
      </Button>
      <Button
        onClick={onRetake}
        fullWidth
        variant="gradient"
        color="secondary"
        size="large"
        data-testid="imagepreview-retake-btn"
      >
        {t("retakePhoto")}
      </Button>
    </div>
  );
};

export default ImagePreview;
