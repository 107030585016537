import { configs } from "configs/config";
import { axios } from "utils/axios";
import { ISendMailResponse } from "types/mail.d";

export const sendEmailContact = (body: FormData) => {
  return axios.post<ISendMailResponse>(
    `${configs.apiUrl}/motorinsurance/Utility/SendMailContact`,
    body
  );
};
