import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Webcam from "./Webcam";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  onChange: (image: string) => void;
  onClickBackBtn?: () => void;
  type?: string;
  contourType?: string;
}

const Camera = ({
  open,
  setOpen,
  onChange,
  onClickBackBtn,
  type,
  contourType,
}: Props) => {
  const refDiv = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={setOpen}
        initialFocus={refDiv}
      >
        <div className="fixed inset-0 bg-black transition-opacity" ref={refDiv}>
          <Webcam
            onChange={onChange}
            onClickBackBtn={onClickBackBtn}
            type={type}
            contourType={contourType}
          />
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Camera;
