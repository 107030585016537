/* eslint-disable @typescript-eslint/no-explicit-any */
export type ILogTag = {
  [key: string]: string;
};

export type ILogExtra = {
  [key: string]: any;
};

export const logType = {
  request: "Request",
  camera: "Camera",
};

export const logTags = {
  camera: {
    type: "error",
    category: logType.camera,
  },
  request: {
    type: "error",
    category: logType.request,
  },
};
