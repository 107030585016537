import React, { useEffect, Fragment } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IPackage } from "types/package.d";
import { Language } from "types/common.d";
import Button from "components/Button";
import { usePackageDetail } from "hooks/usePackage";
import { useCommonState } from "hooks/useCommonState";
import { formatNumber, isNumber } from "utils/number";

interface IPackageSummary {
  grossPremiumVol: number;
  stampVol: number;
  vatVol: number;
  totPremiumVol: number;
  totPremiumCTP: number;
  totalPrice: number;
}
interface ILocationState {
  packageData: IPackage | null;
}

const PackageDetailPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { setLoading } = useCommonState();
  const location = useLocation();
  const locationState = location.state as ILocationState;
  const [searchParams] = useSearchParams();
  const currentParams = Object.fromEntries([...searchParams]);
  const packageData = locationState?.packageData ?? null;

  const { packageDetail, isLoading } = usePackageDetail({
    I_PkgCode: currentParams?.packageCode,
    I_CoverType: currentParams?.coverType,
    I_SumInsured: currentParams?.sumInsure,
    I_DeductTP: currentParams?.deductAmountTP,
    I_DeductOD: currentParams?.deductAmountOD,
    // I_TempDisability: "",
    I_AccessoryAmt: currentParams?.sumInsureAccessory,
    I_CageAmt: currentParams?.cageAmt,
    I_Language: Language.th,
  });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  const getSummaryPrice = (pkgData: IPackage): IPackageSummary => {
    const hasCTP = !!currentParams?.hasCTP;
    const grossPremiumVol = pkgData?.grossPremiumVol || 0;
    const stampVol = pkgData?.stampVol || 0;
    const vatVol = pkgData?.vatVol || 0;
    const totPremiumVol = pkgData?.totPremiumVol || 0;
    const totPremiumCTP = hasCTP ? pkgData?.totPremiumCTP || 0 : 0;
    const totalPrice = totPremiumVol + totPremiumCTP;

    return {
      grossPremiumVol,
      stampVol,
      vatVol,
      totPremiumVol,
      totPremiumCTP,
      totalPrice,
    };
  };

  const sumData = packageData ? getSummaryPrice(packageData) : null;

  const renderPackageDetail = () => {
    return packageDetail?.packageDetailList?.map((detail) => {
      const isHead = detail.category === "H";
      const detailValue = detail.value;
      const children = [];

      if (isHead) {
        children.push(
          <Fragment key={detail.prefix}>
            <th className="w-[20px] bg-gray-400 text-left">{detail.prefix}</th>
            <th colSpan={3} className="bg-gray-400 text-left w-full">
              {detail.name}
            </th>
          </Fragment>
        );
      } else {
        children.push(
          <Fragment key={detail.prefix}>
            <td className="w-[20px]">{detail.prefix}</td>
            <td className="w-[60%]">{detail.name}</td>
          </Fragment>
        );
      }

      if (detailValue) {
        const value = isNumber(detailValue)
          ? formatNumber(+detailValue)
          : detailValue;

        children.push(
          <Fragment key={value}>
            <td className="w-[20%] text-right">{value}</td>
            <td className="w-[20%]">{detail.unit}</td>
          </Fragment>
        );
      }

      return (
        <tr key={detail.id} className="border-b border-gray">
          {children}
        </tr>
      );
    });
  };

  return (
    <div className="pb-6">
      <h2 className="text-center pt-1 pb-3" data-testid="package-detail-title">
        {t("package.detail.title")}
      </h2>
      <table className="package-detail-table table-auto w-full ">
        <thead data-testid="package-detail-table-head">
          <tr className="bg-primary text-white font-normal">
            <th colSpan={2} className="align-middle">
              {t("package.detail.cover")}
            </th>
            <th colSpan={1}>
              {t("package.detail.titleCoverRate", {
                coverType: packageData?.coverType ?? "",
                vehCode: packageData?.vehCode ?? "",
              })}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody data-testid="package-detail-table-body">
          {renderPackageDetail()}
          {!isLoading && sumData && (
            <>
              <tr className="bg-success bg-opacity-20 border-b border-gray">
                <td colSpan={2} className="text-center">
                  {t("package.detail.totalPremiumPrice")}
                </td>
                <td className="w-[20%] text-right">
                  {formatNumber(sumData.grossPremiumVol)}
                </td>
                <td className="w-[20%]">{t("baht")}</td>
              </tr>
              <tr className="bg-success bg-opacity-20 border-b border-gray">
                <td colSpan={2} className="text-center">
                  {t("package.detail.duty")}
                </td>
                <td className="w-[20%] text-right">
                  {formatNumber(sumData.stampVol)}
                </td>
                <td className="w-[20%]">{t("baht")}</td>
              </tr>
              <tr className="bg-success bg-opacity-20 border-b border-gray">
                <td colSpan={2} className="text-center">
                  {t("package.detail.tax")}
                </td>
                <td className="w-[20%] text-right">
                  {formatNumber(sumData.vatVol)}
                </td>
                <td className="w-[20%]">{t("baht")}</td>
              </tr>
              <tr className="bg-success bg-opacity-20 border-b border-gray">
                <td colSpan={2} className="text-center">
                  {t("package.detail.premiumWithTax")}
                </td>
                <td className="w-[20%] text-right">
                  {formatNumber(sumData.totPremiumVol)}
                </td>
                <td className="w-[20%]">{t("baht")}</td>
              </tr>
              <tr className="bg-success bg-opacity-20 border-b border-gray">
                <td colSpan={2} className="text-center">
                  {t("package.detail.premiumAct")}
                </td>
                <td className="w-[20%] text-right">
                  {formatNumber(sumData.totPremiumCTP)}
                </td>
                <td className="w-[20%]">{t("baht")}</td>
              </tr>
              <tr className="bg-yellow">
                <td colSpan={2} className="text-center">
                  {t("package.detail.total")}
                </td>
                <td className="w-[20%] text-right">
                  {formatNumber(sumData.totalPrice)}
                </td>
                <td className="w-[20%]">{t("baht")}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      <div className="flex justify-center mt-8">
        <a
          href={packageDetail?.coverageUrl ?? ""}
          rel="noopener noreferrer"
          target="_blank"
          data-testid="package-detail-download-btn"
        >
          <Button size="large" variant="gradient" color="primary">
            {t("package.detail.pdf")}
          </Button>
        </a>
      </div>
    </div>
  );
};

export default PackageDetailPage;
