import { configs } from "configs/config";
import { axios } from "utils/axios";
import { authenJWT } from "./auth";

export const postIDOCR = async (body: FormData) => {
  const loginToken = await authenJWT();
  return axios.post(`${configs.apiUrl}/ocr/ThaiCard`, body, {
    headers: {
      Authorization: `Bearer ${loginToken?.data?.Token || ""}`,
    },
  });
};
