import React from "react";
import { useNavigate } from "react-router-dom";
import { TFunction } from "react-i18next";
import Card from "components/Card";
import { CameraIcon } from "components/Icon/Common";
import Button from "components/Button";
import { ICarConfig } from "configs/car";
import { ImageStatus } from "types/car-detection.d";
import { CAR_DETECTION_PREVIEW_PATH } from "configs/page";
import CircularProgressbar from "./CircularProgressBar";

interface ProgressProp {
  percent: number;
  carConfig: ICarConfig;
  failReason?: string;
  t: TFunction;
  carInspectionId: string;
  status: string;
}

const CarDetailCard = ({
  percent,
  carConfig,
  failReason,
  t,
  carInspectionId,
  status,
}: ProgressProp): JSX.Element => {
  const navigate = useNavigate();

  let statusCss;
  if (failReason) {
    statusCss = "text-warning";
  } else {
    statusCss = "text-success";
  }

  const handlePhoto = () => {
    navigate(CAR_DETECTION_PREVIEW_PATH, {
      state: {
        position: carConfig.position,
        carInspectionId,
      },
    });
  };

  return (
    <Card borderColor="darkPrimary">
      <div className="flex flex-row items-center w-full gap-2 sm:gap-4 overflow-hidden break-all max-w-full">
        <CircularProgressbar
          percent={percent}
          radius={24}
          data-testid="cardetection-detail-percent"
        />
        <div className="flex-1">
          <p
            className="break-normal overflow-hidden"
            data-testid="cardetection-detail-label"
          >
            {t(carConfig.label)}
          </p>
          {status === ImageStatus.completed && (
            <p
              className={`body-small ${statusCss}`}
              data-testid="cardetection-detail-desc"
              style={{ wordBreak: "break-word" }}
            >
              {failReason ? t(failReason) : t("completePhoto")}
            </p>
          )}
        </div>
        <div className="flex-none">
          <carConfig.icon
            height="51"
            width="51"
            data-testid="cardetection-detail-car-icon"
          />
        </div>
        <div
          className="flex flex-none items-center justify-end"
          data-testid="cardetection-detail-camera"
        >
          <Button
            variant="contained"
            color="primary"
            size="fit"
            onClick={handlePhoto}
            className="py-2 px-3"
            data-testid="cardetection-detail-camera-btn"
          >
            <CameraIcon height={17} width={17} />
          </Button>
        </div>
      </div>
    </Card>
  );
};
export default CarDetailCard;
