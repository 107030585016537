import React from "react";
import Card from "components/Card";
import Divider from "components/Divider";

type Props = {
  title: string;
  children: React.ReactNode;
  showDivider?: boolean;
};

const DetailCard = ({
  title,
  children,
  showDivider = true,
  ...rest
}: Props) => {
  return (
    <Card borderColor="none" shadow="top" {...rest}>
      <div className="text-center">
        <h2>{title}</h2>
      </div>
      {showDivider && <Divider className="border-primary-main-300 my-3" />}
      {children}
    </Card>
  );
};

export default DetailCard;
