import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { generatePaymentUrl } from "services/mti/payment";
import { IPaymentParams, IPaymentResponse } from "types/payment.d";

export const useGeneratePaymentUrl = (): {
  paymentData: IPaymentResponse | null;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  generatePayment: UseMutateFunction<
    AxiosResponse<IPaymentResponse, unknown>,
    unknown,
    IPaymentParams,
    unknown
  >;
} => {
  const { data, isLoading, isError, error, mutate } = useMutation(
    (body: IPaymentParams) => generatePaymentUrl(body)
  );

  const paymentData = data?.data ?? null;

  return {
    generatePayment: mutate,
    paymentData,
    isLoading,
    isError,
    error,
  };
};
