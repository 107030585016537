import React from "react";
import { useTranslation } from "react-i18next";
import Dialog from ".";
import { InformationIcon } from "components/Icon/Common";

const condition = {
  main: "ข้าพเจ้าตกลงทำประกันภัย ตามเงื่อนไข",
  conditions: [
    "ข้าพเจ้าขอรับรองว่า คำแถลงตามรายการข้างบนเป็นความจริง และให้ถือเป็นส่วนหนึ่งของสัญญาประกันภัยระหว่างข้าพเจ้าและบริษัทเมืองไทยประกันภัย จำกัด (มหาชน)",
    "ข้าพเจ้ารับทราบข้อกำหนดและเงื่อนไขการรับประกันภัยตามแผนความคุ้มครองที่ข้าพเจ้าได้เลือกไว้ และตกลงขอเอาประกันภัยภายใต้ข้อกำหนด และเงื่อนไขการประกันภัยดังกล่าว",
    "ข้าพเจ้ายินยอมให้บริษัทฯ จัดเก็บ ใช้ และเปิดเผยข้อเท็จจริงเกี่ยวกับข้อมูลของข้าพเจ้าต่อสำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย เพื่อประโยชน์ในการกำกับดูแลธุรกิจประกันภัย",
    `ข้าพเจ้าขอรับรองว่าข้อมูลที่แจ้งให้ทราบเป็นความจริงทุกประการ และตกลงทำประกันภัยตามทุนประกันภัยดังกล่าวข้างต้น และ "ข้าพเจ้าขอรับรองและยืนยันว่า ข้าพเจ้าไม่เคยมีประวัติการกระทำผิดกฎหมายป้องกันและปราบปรามการฟอกเงิน หรือ กฎหมายป้องกันและปราบปรามการสนับสนุนทางการเงินแก่การก่อการร้ายและการแพร่ขยายอาวุธที่มีอานุภาพทำลายล้างสูง หรือกฎหมายอื่นที่เกี่ยวข้อง เช่น กฎหมายเกี่ยวกับยาเสพติดให้โทษ และข้าพเจ้าขอรับรองว่าข้อมูลรายละเอียดข้างต้นเป็นความจริงทุกประการ"`,
    <>
      ข้าพเจ้ารับทราบเงื่อนไขกรณีมีการเคลมเกิดขึ้นระหว่างรอผลตรวจสภาพรถว่า
      บมจ.เมืองไทยประกันภัยจะให้ความคุ้มครองเฉพาะการเคลมสดเท่านั้น
      <br />
      <InformationIcon width={12} height={12} className="inline mr-1" />
      เคลมสด หมายถึง การเกิดเหตุ และเคลม ณ เวลานั้น โดยที่คู่กรณีก็ยังอยู่ ณ
      ที่เกิดเหตุโดยที่เจ้าหน้าที่ สำรวจภัย (Surveyor) ไปพบผู้เอาประกัน
      และคู่กรณีและออกเอกสารรับรองความเสียหาย (ใบเคลม)
      หรือเอกสารใบติดต่อในที่เกิดเหตุ
    </>,
  ],
  warning:
    "คำเตือน\nของสำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย (คปภ.) ให้ตอบคำถามข้างต้นตามความเป็นจริงทุกข้อ หากผู้ขอเอาประกันภัยปกปิดข้อความจริง หรือแถลงข้อความอันเป็นเท็จจะมีผลให้สัญญานี้ตกเป็นโมฆียะ ซึ่งบริษัทมีสิทธิบอกล้างสัญญาประกันภัยได้ตามประมวลกฎหมายแพ่งและพาณิชย์ มาตรา 865",
};

interface Props {
  open: boolean;
  onCloseDialog: () => void;
}

const DialogCondition = ({ open, onCloseDialog }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog title={t("condition")} isOpen={open} onCloseDialog={onCloseDialog}>
      <div className="py-2">
        <p className="text-xs">{condition.main} &gt;&gt;</p>
        <ul className="dialog-condition pl-5">
          {condition?.conditions?.map((cond, index) => {
            return (
              <li key={index} className="py-1">
                <p className="text-xs">{cond}</p>
              </li>
            );
          })}
        </ul>
        <p className="text-warning text-xs pt-1">{condition.warning}</p>
      </div>
    </Dialog>
  );
};

export default DialogCondition;
