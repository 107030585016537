import React from "react";
import Dialog from ".";
import { WarningIcon } from "components/Icon/Common";
import Button from "components/Button";
import { IWarning } from "types/common.d";
import { useSetRecoilState } from "recoil";
import { warningState } from "states/warningState";

interface Props {
  warning: IWarning;
}

const DialogWarning = ({ warning }: Props) => {
  const setWarning = useSetRecoilState(warningState);
  const {
    title,
    subTitle,
    message,
    children,
    position = "center",
    onClose,
    onSubmit,
    textSubmit,
    onCancel,
    textCancel,
    showClose,
    enableCloseOnClickOutside,
  } = warning;

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
    setWarning(null);
  };

  const handleOnSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
    setWarning(null);
  };

  const handleOnCancel = () => {
    if (onCancel) {
      onCancel();
    }
    setWarning(null);
  };

  return (
    <Dialog
      isOpen={!!warning}
      onCloseDialog={handleOnClose}
      position={position}
      showClose={showClose}
      enableCloseOnClickOutside={enableCloseOnClickOutside}
    >
      <div className="flex flex-col justify-center items-center gap-1 mt-[-5px]">
        <WarningIcon width={40} height={35} data-testid="dialog-warning-icon" />
        <h2 className="text-center" data-testid="dialog-warning-title">
          {title}
        </h2>
      </div>
      <div className="text-center pt-3 pb-1">
        {subTitle && (
          <h3 className="h3-semibold" data-testid="dialog-warning-subtitle">
            {subTitle}
          </h3>
        )}
        {message && (
          <p className="body-small" data-testid="dialog-warning-desc">
            {message}
          </p>
        )}
      </div>
      {children}
      {(onSubmit || onCancel) && (
        <div className="flex justify-center gap-2 my-3">
          {onCancel && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleOnCancel}
              data-testid="dialog-warning-cancel-btn"
            >
              {textCancel}
            </Button>
          )}
          {onSubmit && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleOnSubmit}
              data-testid="dialog-warning-submit-btn"
            >
              {textSubmit}
            </Button>
          )}
        </div>
      )}
    </Dialog>
  );
};

export default DialogWarning;
