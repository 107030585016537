import { configs } from "configs/config";
import { axios } from "utils/axios";
import { IMtiColorListResponse } from "types/color.d";

export const getColors = () => {
  return axios.get<IMtiColorListResponse>(
    `${configs.apiUrl}/MtiRestService/api/Car/GetCarColor`,
    {
      headers: {
        Authorization: `Basic ${configs.mtiApiBasicAuth}`,
      },
    }
  );
};
