import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useRecoilState } from "recoil";
import { carPackageState } from "states/carPackageState";
import { carInfoState } from "states/carInfoState";
import { searchParamsState } from "states/searchParamsState";
import { CAR_PACKAGE_CONFIRM_PATH } from "configs/page";
import { CageAmt, CarType } from "configs/package";
import { removeObjEmpty, getCarTitle } from "utils/formatter";
import { FilterIcon } from "components/Icon/Common";
import Button from "components/Button";
import Dialog from "components/Dialog";
import PackageCard from "./components/PackageCard";
import PackageCustomForm from "./components/PackageCustomForm";
import PackageNotFound from "./components/PackageNotFound";
import { usePackages, usePackageSuggest } from "hooks/usePackage";
import {
  IPackageData,
  IPackageSuggest,
  IPackageCustomForm,
} from "types/package.d";
import { useCommonState } from "hooks/useCommonState";

const PackagePage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setLoading } = useCommonState();
  const [carPackageSelected, setCarPackage] = useRecoilState(carPackageState);
  const searchParam = useRecoilValue(searchParamsState);
  const carInfo = useRecoilValue(carInfoState);
  const [openCustomDialog, setOpenCustomDialog] = useState<boolean>(false);
  const [customPackage, setCustomPackage] = useState<IPackageCustomForm | null>(
    carPackageSelected?.customPackage ?? null
  );
  const carTitle = getCarTitle(carInfo);
  const userCar = {
    agentNumber: searchParam?.agent ?? "",
    vehicleKey: "",
    makeCode: carInfo.brand?.makeCode ?? "",
    family: carInfo.model?.family ?? "",
    engineSize: carInfo.model?.engineSize ?? "",
    doorNum: carInfo.model?.doorNum ?? "",
    carYear: `${carInfo.year?.yearGroup ?? ""}`,
    fuelType: carInfo.model?.fuelType ?? "",
    bodyConfig: carInfo.model?.bodyConfig ?? "",
  };

  const {
    packageSuggestList,
    isLoading: isLoadingPackageSuggest,
    isError: isErrorPackageSuggest,
  } = usePackageSuggest({
    I_AgentNumber: userCar.agentNumber,
    I_VehicleKey: userCar.vehicleKey,
    I_MakeCode: userCar.makeCode,
    I_Family: userCar.family,
    I_EngineSize: userCar.engineSize,
    I_DoorNum: userCar.doorNum,
    I_CarYear: userCar.carYear,
    I_FuelType: userCar.fuelType,
    I_BodyConfig: userCar.bodyConfig,
  });
  const packageSuggestDefault = packageSuggestList?.[0];

  const getCageAmtValue = (carType: number) =>
    carType === CarType.Pickup && customPackage?.isCageAmt
      ? CageAmt.Y
      : CageAmt.N;

  const convertPackageParams = (suggest: IPackageSuggest) => {
    const carType = suggest.carType.split(",")?.[0] || "";
    const cageAmt = getCageAmtValue(+carType);

    return {
      AgentNumber: suggest.agentNumber,
      DChannel: suggest.dChannel,
      CoverType: suggest.coverType,
      VehicleCode: suggest.vehicleCode,
      CarType: carType,
      MakeCode: suggest.makeCode,
      Family: suggest.family,
      Riskgroup: suggest.motorGroup,
      Capacity: suggest.engineSize,
      Weight: suggest.weight,
      CarYear: suggest.carYear,
      Workshop: suggest.workShop,
      IsRegBK: carInfo?.regProvince?.short ?? "",
      DeductAmountOD: suggest.deductODAmt,
      DeductAmountTP: suggest.deductTPAmt,
      DriverAge: suggest.driverAge,
      EffectiveDate: suggest.effectiveDate,
      ExpiryDate: suggest.expiryDate,
      SuminsureMin: suggest.minSumInsured,
      SuminsureMax: suggest.maxSumInsured,
      SuminsureSuggest: suggest.sumInsured,
      UseCar: suggest.useCar,
      AccessoryAmt: suggest.accessoryAmt,
      CageAmt: cageAmt,
      PackageList: suggest.pkgList,
      OtherDiscount: suggest.otherDiscount,
      NCBDiscount: suggest.ncbDiscount,
      FleetDiscount: suggest.fleetDiscount,
      AdjustPremium: suggest.adjustPremium,
      SpecialAccessoryFlag: "N",
      BigTruckFlag: `${suggest.isBigTruck}`,
      FuelType: suggest.fuelType,
    };
  };

  const getPackagesParams = () => {
    if (customPackage && packageSuggestDefault) {
      const customDataMapped = {
        sumInsured: `${customPackage?.sumInsured?.value ?? ""}`,
        deductODAmt: `${customPackage.deductAmountOD?.value ?? ""}`,
        deductTPAmt: `${customPackage.deductAmountTP?.value ?? ""}`,
        carType: `${customPackage.carType?.value ?? ""}`,
        workShop: `${customPackage.workshop?.value ?? ""}`,
        driverAge: `${customPackage.driverAge?.value ?? ""}`,
        useCar: `${customPackage.useCar?.value ?? ""}`,
      };
      const filteredObj = removeObjEmpty(customDataMapped);

      const customParams = { ...packageSuggestDefault, ...filteredObj };
      return [convertPackageParams(customParams)];
    } else {
      return (packageSuggestList ?? []).map(convertPackageParams);
    }
  };

  const resPackageAll = usePackages(getPackagesParams(), !!packageSuggestList);

  const packageListAll = (resPackageAll || [])
    .flatMap((resPackage) => resPackage.packageList ?? [])
    .map((pkg) => ({
      ...pkg,
      packageSuggest: packageSuggestList?.[0] ?? null,
    }));

  const onCloseCustomDialog = () => {
    setOpenCustomDialog(false);
  };

  const onSubmitCustomPackage = (data: IPackageCustomForm) => {
    setCustomPackage(data);
    setCarPackage({ ...carPackageSelected, customPackage: data });
    onCloseCustomDialog();
  };

  const onClearCustomPackage = (customPackageDefault: IPackageCustomForm) => {
    setCustomPackage(customPackageDefault);
    setCarPackage({
      ...carPackageSelected,
      customPackage: customPackageDefault,
    });
    onCloseCustomDialog();
  };

  const onClickSelectPackage = (packageData: IPackageData) => {
    const packageSelected = {
      package: packageData,
      driverAge: `${customPackage?.driverAge?.value || "-1"}`,
      hasCTP: customPackage?.hasCTP ?? false,
      promotion: customPackage?.promotion ?? "",
      customPackage,
    };

    setCarPackage(packageSelected);
    navigate(CAR_PACKAGE_CONFIRM_PATH);
  };

  const onSaveCustomData = () => {
    if (customPackage) {
      const packageSelected = {
        ...carPackageSelected,
        customPackage,
      };
      setCarPackage(packageSelected);
    }
  };

  const isLoadingPackage = resPackageAll.some((pkg) => pkg.isLoading);
  const isLoadingAll = isLoadingPackageSuggest || isLoadingPackage;

  useEffect(() => {
    setLoading(isLoadingAll);
  }, [isLoadingAll, setLoading]);

  if (isErrorPackageSuggest) {
    return (
      <div className="pt-6">
        <PackageNotFound
          title={t("package.notFound")}
          description={t("package.notFoundDesc")}
        />
      </div>
    );
  }

  return (
    <>
      <div className="w-full flex justify-center">
        <Button
          variant="gradient"
          color="secondary"
          className="mb-3 text-primary-text w-[90%]"
          onClick={() => setOpenCustomDialog(true)}
          data-testid="package-setting-btn"
        >
          <div className="w-full flex items-center">
            <FilterIcon width={24} data-testid="package-setting-icon" />
            <div
              className="body-semibold-small pl-3"
              data-testid="package-setting-text"
            >
              {t("package.custom")}
            </div>
          </div>
        </Button>
      </div>
      {packageListAll.length > 0
        ? packageListAll.map((packageData, index) => (
            <div
              key={index}
              className="py-3 packageList"
              data-testid={`package-${index}`}
            >
              <PackageCard
                carTitle={carTitle}
                packageData={packageData}
                hasCTP={!!customPackage?.hasCTP}
                cageAmt={getCageAmtValue(
                  customPackage?.carType?.value ??
                    +(packageSuggestDefault?.carType ?? 0)
                )}
                onClickSelectPackage={onClickSelectPackage}
                onSaveCustomData={onSaveCustomData}
              />
            </div>
          ))
        : !isLoadingAll && (
            <div className="pt-2">
              <PackageNotFound
                title={t("package.notFound")}
                description={t("package.notFoundDesc")}
              />
            </div>
          )}

      {openCustomDialog && (
        <Dialog
          title={t("package.custom")}
          isOpen={openCustomDialog}
          onCloseDialog={onCloseCustomDialog}
        >
          <PackageCustomForm
            customPackage={customPackage}
            packageData={packageListAll[0]}
            packageSuggest={packageSuggestList?.[0] ?? null}
            onSubmit={onSubmitCustomPackage}
            onClear={onClearCustomPackage}
          />
        </Dialog>
      )}
    </>
  );
};

export default PackagePage;
