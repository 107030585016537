import { sentAddressValue } from "configs/sent-insurance-option";
import { atom } from "recoil";
import { IIdInfoForm } from "types/id-info.d";

export const idCardState = atom({
  key: "idCardState",
  default: {
    user: {
      id: "",
      prefix: null,
      firstName: "",
      lastName: "",
      birth: null,
      // gender: null,
      email: "",
      tel: "",
    },
    address: {
      buildNo: "",
      moo: "",
      buildName: "",
      floor: "",
      room: "",
      soi: "",
      road: "",
      province: null,
      district: null,
      subdistrict: null,
      zipcode: "",
    },
    sentOption: sentAddressValue.email,
    sentAddress: null,
    isSentAddressAsIDCard: true,
    isInsurer: null,
  } as IIdInfoForm,
});
