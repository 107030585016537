export enum MailContactType {
  CarDetail = "MOTOR101",
  Package = "MOTOR102",
  Confirmation = "MOTOR103",
  CarDetection = "MOTOR104",
  DupChassis = "MOTOR105",
  BlackList = "MOTOR106",
  InsuranceInterested = "MOTOR107", // สนใจทำประกัน
  Rating = "MOTOR201",
}

export enum ConsentCode {
  MTI003 = "COMMON.MTI003",
}

export enum FlowType {
  FullStep = "01",
  CarDetection = "02",
}

export enum CntType {
  PMX = "PMX",
  CTP = "CTP",
}

export const agentNumber = {
  mti: "07506036",
};

export enum AdapterSystem {
  MTI = "mti",
  INTERNAL = "internal",
  ADAPTER = "adapter",
}

export enum ErrorCode {
  TOKEN_ERROR = "TOKEN_ERROR",
  QUEUE_IN_PROCESS = "QUEUE_IN_PROCESS",
  ERR_NETWORK = "ERR_NETWORK",
  ECONNABORTED = "ECONNABORTED",
}
