import React, { HTMLInputTypeAttribute } from "react";
import Label from "../Label";

type Props = {
  className?: string;
  classNameLabel?: string;
  label?: string;
  disabled?: boolean;
  onChange?: (e?: React.ChangeEvent<HTMLInputElement> | null) => void;
  value: string | number;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  pattern?: string;
  helperText?: string;
  inputMode?: "text" | "numeric" | "tel" | "email";
  errorMsg?: string;
  maxLength?: number;
};

interface ITextFieldConfig {
  [key: string]: string;
}

const textFieldConfig: ITextFieldConfig = {
  default:
    "bg-primary-input-bg border-primary-input-border text-primary-input-text",
  disabled:
    "bg-primary-input-disabled-bg border-primary-input-disabled-border text-primary-input-disabled-text",
  error: "bg-primary-input-bg border-error text-primary-input-text",
};

const TextField = ({
  label,
  disabled,
  onChange,
  errorMsg,
  helperText,
  pattern,
  className = "",
  classNameLabel = "",
  type = "text",
  value = "",
  ...rest
}: Props) => {
  const isError = !!errorMsg;
  let inputCss = "";
  if (disabled) inputCss = textFieldConfig.disabled;
  else if (isError) inputCss = textFieldConfig.error;
  else inputCss = textFieldConfig.default;

  return (
    <>
      {label && (
        <Label
          disabled={disabled}
          className={classNameLabel}
          data-testid="textfield-label"
        >
          {label}
        </Label>
      )}
      <input
        data-testid="textfield-input"
        className={`mt-1 py-2 px-3 block w-full border focus:outline-none rounded-lg placeholder:text-primary-input-placeholder ${inputCss} ${className}`}
        onChange={onChange}
        type={type}
        disabled={disabled}
        pattern={pattern}
        value={value}
        {...rest}
      />
      {helperText && (
        <span
          className="text-help text-primary-input-border"
          data-testid="textfield-helper"
        >
          {helperText}
        </span>
      )}
      {isError && (
        <span className="text-error" data-testid="textfield-error">
          {errorMsg}
        </span>
      )}
    </>
  );
};

export default TextField;
