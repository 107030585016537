import { configs } from "configs/config";
import { axios } from "utils/axios";
import {
  IMtiYearListResponse,
  IMtiYear,
  IYear,
  IFetchYearList,
} from "types/year.d";

export const getYears = ({ queryKey }: IFetchYearList) => {
  const [, params] = queryKey;
  return axios.get<IMtiYearListResponse[]>(
    `${configs.apiUrl}/bpmp2/Cars/Years`,
    { params }
  );
};

export const convertYearData = (year: IMtiYear): IYear => ({
  makeCode: year.cMakeCode,
  family: year.cFamily,
  mtiKey: year.cMTIKey,
  isActive: year.cIsActive,
  yearGroup: year.cYearGroup,
  bodyConfig: year.cBodyConfig,
});
