export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "windows phone";
  }

  if (/android/i.test(userAgent)) {
    return "android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "ios";
  }

  return "";
};

export const checkIsIOS = (): boolean => {
  return getMobileOperatingSystem() === "ios";
};
