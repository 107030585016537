import { useNavigate, createSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import { carDetectionState } from "states/carDetectionState";
import { searchParamsState } from "states/searchParamsState";
import { AxiosResponse } from "axios";
import {
  ICarDetectionBody,
  ICarSubmitBody,
  ICarDetectionResponse,
  ICarDetectionImage,
} from "types/car-detection.d";
import {
  carUpload,
  carDetection,
  carSubmit,
} from "services/carai/car-detection";
import { useCommonState } from "hooks/useCommonState";
import { logTags } from "configs/logging";
import { ORDER_STATUS_PATH, CAR_DETECTION_HOME_PATH } from "configs/page";
import { AiFailType, carPolicyStatus } from "configs/car";
import { configs } from "configs/config";
import { AdapterSystem, ErrorCode } from "configs/common";
import { captureLogMessage, createExtras } from "utils/logging";
import { isPolicyConfirmed } from "utils/car-detection";
import { IAdapterError, ISearchParams } from "types/common.d";

export const useCarDetection = (): {
  carDetectionData: ICarDetectionResponse;
  isLoading: boolean;
  carDetectionMutate: UseMutateFunction<
    AxiosResponse<ICarDetectionResponse, unknown> | null,
    IAdapterError,
    ICarDetectionBody,
    unknown
  >;
} => {
  const searchParams = useRecoilValue(searchParamsState);
  const navigate = useNavigate();
  const { setLoading } = useCommonState();

  const {
    data,
    mutate: carDetectionMutate,
    isLoading,
  } = useMutation<
    AxiosResponse<ICarDetectionResponse, unknown>,
    IAdapterError,
    ICarDetectionBody,
    unknown
  >(
    (body: ICarDetectionBody) => carDetection(body, !!searchParams?.skipStatus),
    {
      onSuccess: (resp, body) => {
        const respData = resp?.data;
        if (!respData) {
          captureLogMessage(
            "fail to get car response",
            {
              ...logTags.request,
              requestURL: resp.config.url ?? "",
            },
            createExtras(body, resp)
          );
          throw new Error("Api fail");
        } else {
          const isConfirmed =
            respData?.lastMTIStatus &&
            isPolicyConfirmed(respData?.lastMTIStatus);

          if (!isConfirmed) {
            navigate(`${ORDER_STATUS_PATH}?status=${respData.lastMTIStatus}`);
            return;
          }

          if (isConfirmed && respData?.urlInspectionExpireDate) {
            const expiredDate = new Date(
              respData.urlInspectionExpireDate
            ).getTime();
            const nowDate = new Date().getTime();
            if (expiredDate <= nowDate) {
              navigate(`${ORDER_STATUS_PATH}?status=${carPolicyStatus.cancel}`);
              return;
            }
          }
        }
      },
      onSettled: () => {
        setLoading(false);
      },
    }
  );

  const carDetectionData = data?.data as ICarDetectionResponse;
  return { carDetectionData, carDetectionMutate, isLoading };
};

export const useCarUpload = (
  carInspectionId: string
): {
  carUploadMutate: UseMutateFunction<
    AxiosResponse<unknown, unknown> | null,
    unknown,
    FormData,
    unknown
  >;
} => {
  const { t } = useTranslation();
  const searchParams = useRecoilValue(searchParamsState);
  const [carDetectionInfo, setCarDetectionState] =
    useRecoilState(carDetectionState);
  const { setWarningDialog } = useCommonState();
  const { mutate: carUploadMutate } = useMutation(
    (body: FormData) => carUpload(carInspectionId, body),
    {
      onSuccess: (resp, variables) => {
        captureLogMessage(
          "upload image carInspectionId: " + carInspectionId,
          { carInspectionId },
          resp.data
        );

        if (!carDetectionInfo) return;
        // update recoil by position for smooth ui on list page
        const position = (variables?.get("position") ?? "") as string;
        const isDefaultImg = carDetectionInfo?.images.defaultOrder.find(
          (order) => order === position
        );

        const defaultImage: ICarDetectionImage = {
          carInspectionId,
          position,
          status: "",
          isPass: false,
          progress: 0,
          aiProcessTime: 0,
          failReason: "",
          failType: AiFailType.none,
          failService: "",
          damageResult: {
            hasDamage: false,
            damageCount: 0,
          },
          urls: {
            original: "",
            damageLabel: "",
          },
        };

        const imageObj = isDefaultImg
          ? {
              default: {
                ...carDetectionInfo.images.default,
                [position]: defaultImage,
              },
            }
          : {
              additional: {
                ...carDetectionInfo.images.additional,
                [position]: defaultImage,
              },
            };

        setCarDetectionState({
          ...carDetectionInfo,
          images: { ...carDetectionInfo.images, ...imageObj },
        });
      },
      onError: (error: IAdapterError) => {
        const isTokenError = isOneTimeTokenExpired(error);
        if (isTokenError) {
          handleTokenError(searchParams);
          return;
        }

        setWarningDialog({
          title: t("warning.carInfo.title"),
          subTitle: t("warning.carInfo.detail", {
            contactNumber: configs.contactNumber,
            officeHours: configs.officeHours,
          }),
          onSubmit: () => {
            setWarningDialog(null);
          },
          onCancel: () => {
            window.location.href = configs.telLink;
            setWarningDialog(null);
          },
          textSubmit: t("warning.carInfo.retake"),
          textCancel: t("warning.carInfo.contact", {
            contactNumber: configs.contactNumber,
          }),
        });
      },
    }
  );
  return { carUploadMutate };
};

export const useCarSubmit = (
  carInspectionId: string
): {
  carSubmitMutate: UseMutateFunction<
    AxiosResponse<unknown, unknown> | null,
    IAdapterError,
    ICarSubmitBody,
    unknown
  >;
} => {
  const searchParams = useRecoilValue(searchParamsState);
  const { setWarningDialog } = useCommonState();
  const { t } = useTranslation();
  const { mutate: carSubmitMutate } = useMutation(
    (body: ICarSubmitBody) =>
      carSubmit(carInspectionId, body, !!searchParams?.skipStatus),
    {
      onError: (error: IAdapterError) => {
        const isTokenError = isOneTimeTokenExpired(error);
        if (isTokenError) {
          handleTokenError(searchParams);
          return;
        }

        const system = error?.response?.data?.system;
        if (system === AdapterSystem.MTI) {
          setWarningDialog({
            title: t("warning.mti.common"),
            message: t("warning.mti.retry"),
          });
        } else {
          setWarningDialog({
            title: t("warning.internal.common"),
            message: t("warning.internal.retry"),
          });
        }
      },
      retry: (failureCount, error: IAdapterError) => {
        // Retry if the failure count is less than 3 and timeout case
        // if the error queue in process
        const isTimeout =
          error?.code === ErrorCode.ERR_NETWORK ||
          error?.code === ErrorCode.ECONNABORTED;

        return (failureCount < 3 && isTimeout) || isQueueInProcess(error);
      },
      retryDelay: (_, error: IAdapterError) => {
        if (isQueueInProcess(error)) return 2000; // 2 seconds delay
        return 0;
      },
    }
  );
  return { carSubmitMutate };
};

const isQueueInProcess = (error: IAdapterError) =>
  !!(
    error?.response?.status === 400 &&
    error?.response?.data?.code === ErrorCode.QUEUE_IN_PROCESS
  );

export const isOneTimeTokenExpired = (error: IAdapterError) => {
  const statusCode = error.response?.status;
  return (
    statusCode === 401 ||
    (statusCode === 400 && error.response?.data?.code === ErrorCode.TOKEN_ERROR)
  );
};

export const handleTokenError = (searchParams: ISearchParams | null) => {
  const queryString = searchParams
    ? `?${createSearchParams(searchParams)}`
    : "";

  window.location.href = CAR_DETECTION_HOME_PATH + queryString;
};
