import React from "react";
import { WarningIcon } from "components/Icon/Common";
import Card from "components/Card";

type Props = {
  title: string;
  description?: string;
};

const PackageNotFound = ({ title, description }: Props): JSX.Element => {
  return (
    <Card shadow="top">
      <div className="flex flex-col items-center py-9">
        <WarningIcon
          width={44}
          height={38}
          data-testid="package-notfound-icon"
        />
        <h2 className="py-2" data-testid="package-notfound-title">
          {title}
        </h2>
        {description && (
          <p className="body-small" data-testid="package-notfound-desc">
            {description}
          </p>
        )}
      </div>
    </Card>
  );
};

export default PackageNotFound;
