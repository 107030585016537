import { configs } from "configs/config";
import { axios } from "utils/axios";
import { IPaymentResponse, IPaymentParams } from "types/payment.d";

export const generatePaymentUrl = (body: IPaymentParams) => {
  return axios.post<IPaymentResponse>(
    `${configs.apiUrl}/friends/api/services/app/Payment/GeneratePaymentUrl`,
    body
  );
};
