import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { configs } from "configs/config";
import Button from "components/Button";
import Select from "components/Input/Select";
import CheckBox from "components/Input/CheckBox";
import DialogCondition from "components/Dialog/DialogCondition";
import { IOrderForm } from "types/order.d";
import { IOption } from "types/common.d";
import { useCommonState } from "hooks/useCommonState";

type Props = {
  paymentMethodOptions?: IOption[];
  onSubmitForm: (form: IOrderForm) => void;
  disabledSubmitBtn?: boolean;
};

const OrderForm = ({
  onSubmitForm,
  paymentMethodOptions = [],
  disabledSubmitBtn = false,
}: Props) => {
  const { t } = useTranslation();
  const { setWarningDialog } = useCommonState();
  const [openConditionDialog, setOpenConditionDialog] =
    useState<boolean>(false);

  const defaultValues: IOrderForm = {
    paymentMethod: null,
    acceptCondition: false,
  };

  const schema = yup
    .object({
      paymentMethod: yup
        .object()
        .required(t("order.inputError.required.paymentMethod"))
        .nullable(),
      acceptCondition: yup.boolean(),
    })
    .required();

  const { control, handleSubmit } = useForm<IOrderForm>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = (dataForm: IOrderForm) => {
    if (!dataForm.acceptCondition) {
      setWarningDialog({
        title: t("condition.fail.title"),
        message: t("condition.fail.message", {
          contactNumber: configs.contactNumber,
          officeHours: configs.officeHours,
        }),
      });
      return;
    }

    onSubmitForm(dataForm);
  };

  const onClickShowCondition = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpenConditionDialog(true);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="py-2" data-testid="summary-payment-acceptCondition">
          <Controller
            control={control}
            name="acceptCondition"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <CheckBox
                  name="acceptCondition"
                  onChange={onChange}
                  checked={value}
                  errorMsg={error?.message}
                >
                  <p>
                    {t("order.acceptCondition")}
                    <button
                      className="font-semibold underline"
                      onClick={onClickShowCondition}
                      data-testid="summary-payment-acceptCondition2"
                    >
                      {t("order.acceptCondition2")}
                    </button>
                  </p>
                </CheckBox>
              );
            }}
          />
        </div>
        <div className="py-2" data-testid="summary-payment-method">
          <Controller
            control={control}
            name="paymentMethod"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                label={t("order.paymentMethod")}
                keyTitle="name"
                onChange={onChange}
                value={value}
                options={paymentMethodOptions}
                placeholder={t("order.paymentMethod")}
                errorMsg={error?.message}
              />
            )}
          />
        </div>
        <div className="pt-2">
          <Button
            fullWidth
            type="submit"
            size="large"
            variant="gradient"
            color="primary"
            className="my-5"
            disabled={disabledSubmitBtn}
            data-testid="summary-payment-submit"
          >
            {t("payment")}
          </Button>
        </div>
      </form>
      {openConditionDialog && (
        <DialogCondition
          open={openConditionDialog}
          onCloseDialog={() => {
            setOpenConditionDialog(false);
          }}
        />
      )}
    </>
  );
};

export default OrderForm;
