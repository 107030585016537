import { configs } from "configs/config";
import { axios } from "utils/axios";
import {
  IMtiBrandListResponse,
  IMtiBrand,
  IBrand,
  IFetchBrandList,
} from "types/brand.d";

export const getBrands = ({ queryKey }: IFetchBrandList) => {
  const [, params] = queryKey;
  return axios.get<IMtiBrandListResponse[]>(
    `${configs.apiUrl}/bpmp2/Cars/Brands`,
    { params }
  );
};

export const convertBrandData = (brand: IMtiBrand): IBrand => ({
  makeCode: brand.cMakeCode,
  brand: brand.cBrand,
  priority: brand.iPriority,
  isActive: brand.cIsActive,
});
