import React from "react";
import { Listbox } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { IBrand } from "types/brand.d";
import LogoToyota from "../../../assets/images/car-brand/toyota.png";
import LogoHonda from "../../../assets/images/car-brand/honda.png";
import LogoNissan from "../../../assets/images/car-brand/nissan.png";
import LogoMitsubishi from "../../../assets/images/car-brand/mitsubishi.png";
import LogoMazda from "../../../assets/images/car-brand/mazda.png";
import LogoIsuzu from "../../../assets/images/car-brand/isuzu.png";
import LogoFord from "../../../assets/images/car-brand/ford.png";
import LogoSuzuki from "../../../assets/images/car-brand/suzuki.png";
import LogoChevrolet from "../../../assets/images/car-brand/chevrolet.png";
import Select from "../Select";

type Props = {
  label?: string;
  disabled?: boolean;
  errorMsg?: string;
  placeholder?: string;
  onChange: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  value?: IBrand | null;
  options?: IBrand[];
};

interface ILogoBrand {
  [key: string]: string;
}

const logoBrands: ILogoBrand = {
  toyota: LogoToyota,
  honda: LogoHonda,
  nissan: LogoNissan,
  mitsubishi: LogoMitsubishi,
  mazda: LogoMazda,
  isuzu: LogoIsuzu,
  ford: LogoFord,
  suzuki: LogoSuzuki,
  chevrolet: LogoChevrolet,
};

const SelectCarBrand = ({
  label,
  placeholder,
  disabled,
  errorMsg,
  onChange,
  value,
  options,
}: Props) => {
  const { t } = useTranslation();
  const commonBrandOptions: IBrand[] = [];
  const otherBrandOptions: IBrand[] = [];

  options?.forEach((option) => {
    if (logoBrands[(option?.brand || "").toLocaleLowerCase()]) {
      commonBrandOptions.push(option);
    } else {
      otherBrandOptions.push(option);
    }
  });

  return (
    <Select
      label={label}
      keyTitle="brand"
      onChange={onChange}
      value={value}
      options={options}
      placeholder={placeholder}
      disabled={disabled}
      errorMsg={errorMsg}
    >
      {options && options?.length > 1 && (
        <>
          <div className="grid grid-cols-3 bg-gray gap-[0.8px]">
            {commonBrandOptions?.map((option, i) => (
              <Listbox.Option
                key={option.brand}
                className="relative cursor-default select-none p-5 bg-white flex text-center"
                value={option}
                data-testid={`carbook-brand-common-${i}`}
              >
                <img
                  src={logoBrands?.[(option?.brand || "").toLocaleLowerCase()]}
                  alt="car brand logo"
                  width="100%"
                />
              </Listbox.Option>
            ))}
          </div>
          <li
            className="relative cursor-default select-none px-6 py-2 border-y border-gray"
            tabIndex={-1}
            data-testid="carbook-brand-select"
          >
            <span className="block truncate">
              {t("otherCarBrand")}
              <ChevronDownIcon className="inline w-5 ml-2" aria-hidden="true" />
            </span>
          </li>
          {otherBrandOptions?.map((option) => (
            <Listbox.Option
              key={option.brand}
              className={({ active, selected }) =>
                `relative cursor-default select-none py-2 pl-6 pr-4 text-gray-900 ${
                  active || selected ? "bg-gray-600" : ""
                }`
              }
              value={option}
            >
              {({ selected }) => (
                <div className="flex justify-between">
                  <span className="block truncate">{option.brand}</span>
                  {selected && <CheckIcon className="w-[17px]" />}
                </div>
              )}
            </Listbox.Option>
          ))}
        </>
      )}
    </Select>
  );
};

export default SelectCarBrand;
