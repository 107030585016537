import React from "react";
import Card from "components/Card";
import Logo from "assets/images/logos/logo-with-text.png";

type Props = {
  children: React.ReactNode;
  onClose?: () => void;
};

const DetailCard = ({ children, onClose }: Props) => {
  return (
    <Card borderColor="gray" shadow="top" onClose={onClose}>
      <div className="flex items-center flex-col">
        <img
          src={Logo}
          alt="status detail icon"
          width="64"
          data-testid="modal-logo"
        />

        {children}
      </div>
    </Card>
  );
};

export default DetailCard;
