import { atom } from "recoil";
import { ICarInfo } from "types/car-info.d";

export const carInfoState = atom({
  key: "carInfoState",
  default: {
    brand: null,
    model: null,
    year: null,
    regProvince: null,
    insuranceCoverageStartDate: null,
    insuranceCoverageEndDate: null,
    ocrData: null,
    carUniqueInfo: null,
  } as ICarInfo,
});
