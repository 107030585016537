import React from "react";
import {
  CAR_BOOK_PREVIEW_PATH,
  CAR_BOOK_REGISTER_PATH,
  CAR_PACKAGE_PATH,
  CAR_PACKAGE_DETAIL_PATH,
  CAR_PACKAGE_CONFIRM_PATH,
  CAR_SUMMARY_PATH,
  SELECTED_INSURED_PATH,
  ID_HOME_PATH,
  ID_PREVIEW_PATH,
  ID_REGISTER_PATH,
  KYC_PATH,
  SUMMARY_PATH,
  CAR_DETECTION_PATH,
  CAR_DETECTION_PREVIEW_PATH,
  CAR_SUCCESS_PATH,
  MAIL_SUCCESS_PATH,
  ORDER_SUCCESS_PATH,
  ORDER_STATUS_PATH,
  SESSION_EXPIRE_PATH,
  LANDING_PATH,
  CAR_DETECTION_HOME_PATH,
  PAYMENT_RESULT_PATH,
} from "configs/page";
import LandingPage from "pages/Landing";
import CarDetectionHomePage from "pages/CarDetection/CarDetectionHomePage";
import PaymentResultPage from "pages/Order/PaymentResultPage";
import PackagePage from "pages/Package";
import PackageDetailPage from "pages/Package/PackageDetailPage";
import BookPreviewPage from "pages/BookRegister/BookPreviewPage";
import BookRegisterPage from "pages/BookRegister/BookRegisterPage";
import PackageConfirmPage from "pages/Package/PackageConfirmPage";
import CarSummaryPage from "pages/CarSummary/CarSummaryPage";
import SelectedInsuredPage from "pages/InsuredRegister/SelectedInsuredPage";
import IDHomePage from "pages/InsuredRegister/IDHomePage";
import IDPreviewPage from "pages/InsuredRegister/IDPreviewPage";
import IDRegisterPage from "pages/InsuredRegister/IDRegisterPage";
import KYCPage from "pages/KYC";
import OrderPage from "pages/Order";
import CarInfoPage from "pages/CarDetection/CarInfoPage";
import CarDetectionPreviewPage from "pages/CarDetection/CarDetectionPreviewPage";
import CarSuccessPage from "pages/CarDetection/CarSuccessPage";
import OrderSuccessPage from "pages/Order/OrderSuccessPage";
import MailSuccessPage from "pages/Status/MailSuccessPage";
import OrderStatusPage from "pages/Status/OrderStatusPage";
import SessionTimeoutPage from "pages/Status/SessionExpiredPage";

export type IRoute = {
  path: string;
  title: string;
  element: JSX.Element;
  showHeader?: boolean;
  showBackBtn?: boolean;
  showStepper?: boolean;
  step?: number;
  layout?: boolean;
};

export const routes: IRoute[] = [
  {
    path: LANDING_PATH,
    title: "",
    element: <LandingPage />,
    showBackBtn: true,
    showStepper: false,
    step: 1,
    layout: false,
  },
  {
    path: CAR_DETECTION_HOME_PATH,
    title: "route.title.carDetectionHome",
    element: <CarDetectionHomePage />,
    showBackBtn: false,
    showStepper: true,
    step: 4,
    layout: true,
  },
  {
    path: PAYMENT_RESULT_PATH,
    title: "route.title.payment",
    element: <PaymentResultPage />,
    showBackBtn: true,
    showStepper: false,
    step: 3,
    layout: true,
  },
  {
    path: ORDER_SUCCESS_PATH,
    title: "route.title.orderSuccess",
    element: <OrderSuccessPage />,
    showBackBtn: false,
    showStepper: true,
    step: 3,
    layout: true,
  },
  {
    path: ORDER_STATUS_PATH,
    title: "route.title.orderStatus",
    element: <OrderStatusPage />,
    showBackBtn: false,
    showStepper: false,
    step: 1,
    layout: true,
  },
];

export const privateRoutes: IRoute[] = [
  {
    path: CAR_BOOK_PREVIEW_PATH,
    title: "route.title.carBookPreview",
    element: <BookPreviewPage />,
    showBackBtn: true,
    showStepper: true,
    step: 1,
  },
  {
    path: CAR_BOOK_REGISTER_PATH,
    title: "route.title.carBookRegister",
    element: <BookRegisterPage />,
    showBackBtn: true,
    showStepper: true,
    step: 1,
  },
  {
    path: CAR_PACKAGE_PATH,
    title: "route.title.carPackage",
    element: <PackagePage />,
    showBackBtn: true,
    showStepper: true,
    step: 1,
  },
  {
    path: CAR_PACKAGE_DETAIL_PATH,
    title: "route.title.carPackageDetail",
    element: <PackageDetailPage />,
    showBackBtn: true,
    showStepper: false,
    step: 1,
  },
  {
    path: CAR_PACKAGE_CONFIRM_PATH,
    title: "route.title.carPackageConfirm",
    element: <PackageConfirmPage />,
    showBackBtn: true,
    showStepper: true,
    step: 2,
  },
  {
    path: CAR_SUMMARY_PATH,
    title: "route.title.carSummary",
    element: <CarSummaryPage />,
    showBackBtn: false,
    showStepper: true,
    step: 2,
  },
  {
    path: SELECTED_INSURED_PATH,
    title: "route.title.selectedInsured",
    element: <SelectedInsuredPage />,
    showBackBtn: true,
    showStepper: true,
    step: 3,
  },
  {
    path: ID_HOME_PATH,
    title: "",
    showHeader: false,
    element: <IDHomePage />,
    showBackBtn: false,
    showStepper: false,
    step: 3,
  },
  {
    path: ID_PREVIEW_PATH,
    title: "route.title.idPreview",
    element: <IDPreviewPage />,
    showBackBtn: true,
    showStepper: true,
    step: 3,
  },
  {
    path: ID_REGISTER_PATH,
    title: "route.title.idRegister",
    element: <IDRegisterPage />,
    showBackBtn: true,
    showStepper: true,
    step: 3,
  },
  {
    path: KYC_PATH,
    title: "route.title.kyc",
    element: <KYCPage />,
    showBackBtn: false,
    showStepper: false,
    step: 2,
  },
  {
    path: SUMMARY_PATH,
    title: "route.title.summary",
    element: <OrderPage />,
    showBackBtn: false,
    showStepper: true,
    step: 3,
  },
  {
    path: CAR_DETECTION_PATH,
    title: "route.title.carDetection",
    element: <CarInfoPage />,
    showBackBtn: false,
    showStepper: true,
    step: 4,
  },
  {
    path: CAR_DETECTION_PREVIEW_PATH,
    title: "route.title.carDetectionPreview",
    element: <CarDetectionPreviewPage />,
    showBackBtn: true,
    showStepper: true,
    step: 4,
  },
  {
    path: CAR_SUCCESS_PATH,
    title: "route.title.carSuccess",
    element: <CarSuccessPage />,
    showBackBtn: false,
    showStepper: true,
    step: 5,
  },
  {
    path: MAIL_SUCCESS_PATH,
    title: "",
    element: <MailSuccessPage />,
    showBackBtn: false,
    showStepper: false,
    step: 1,
  },
  {
    path: SESSION_EXPIRE_PATH,
    title: "route.title.sessionExpired",
    element: <SessionTimeoutPage />,
    showBackBtn: false,
    showStepper: false,
    step: 1,
  },
];
