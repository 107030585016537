import React from "react";

import { BackIcon } from "components/Icon/Common";
import Stepper from "../Stepper";
interface Props {
  title: string;
  onClickBackBtn?: (() => void) | null;
  showStepper?: boolean;
  step?: number;
}

const MAX_STEP = 4;
const Header = ({
  onClickBackBtn,
  title = "",
  step = 0,
  showStepper = false,
}: Props) => {
  return (
    <div id="header" className="w-full bg-white shadow-md">
      {title && (
        <div className="max-w-screen-md mx-auto my-0 p-5 flex items-center justify-center relative">
          {onClickBackBtn && (
            <BackIcon
              className="absolute left-4 h-5 w-5"
              onClick={onClickBackBtn}
              data-testid="header-back-btn"
            />
          )}
          <h1 data-testid="header-title">{title}</h1>
        </div>
      )}
      {showStepper && (
        <>
          <div className="border-solid border-b border-gray-400" />
          <div className="max-w-screen-md mx-auto pt-5 pb-2">
            <Stepper maxStep={MAX_STEP} currentStep={step} />
          </div>
        </>
      )}
    </div>
  );
};

export default Header;
