export const formatNumber = (num = 0): string => {
  return num.toLocaleString();
};

export const isNumber = (val: string): boolean => {
  return /^[-]?\d+$/.test(val);
};

export const randomNumber = (length: number): string => {
  const crypto = window.crypto;
  const array = new BigUint64Array(1);
  crypto.getRandomValues(array);
  return array[0].toString().slice(0, length);
};
