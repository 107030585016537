import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { CAR_DETECTION_PATH } from "configs/page";
import { CameraType } from "configs/camera";
import Camera from "components/Camera";
import ImagePreview from "components/ImagePreview";
import { searchParamsState } from "states/searchParamsState";
import { dataURIToBlob } from "utils/file";
import { useCommonState } from "hooks/useCommonState";
import { useCarUpload } from "hooks/useCarDetection";

interface CarProps {
  position: string;
  carInspectionId: string;
}

const CarDetectionPreviewPage = (): JSX.Element => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const searchParam = useRecoilValue(searchParamsState);
  const { setLoading } = useCommonState();
  const { state } = useLocation();
  const carState = state as CarProps;
  const position = carState?.position;
  const carInspectionId = carState?.carInspectionId;
  const { carUploadMutate } = useCarUpload(carInspectionId);

  const onChange = (image: string) => {
    setOpen(false);
    if (image) {
      setImagePreview(image);
    }
  };

  const openCamera = () => {
    setOpen(true);
  };

  const onSubmit = async () => {
    if (imagePreview) {
      setLoading(true);
      const file = dataURIToBlob(imagePreview);
      const formData = new FormData();
      formData.append("image", file, "carPosition");
      formData.append("position", position);

      carUploadMutate(formData, {
        onSuccess() {
          navigate(CAR_DETECTION_PATH);
        },
        onSettled() {
          setLoading(false);
        },
      });
    }
  };

  useEffect(() => {
    return () => setLoading(false);
  }, [setLoading]);

  return (
    <>
      <ImagePreview
        src={imagePreview}
        alt="car-detection"
        onRetake={openCamera}
        onSubmit={onSubmit}
        setImagePreview={setImagePreview}
        debug={!!searchParam?.debug}
      />

      {open && (
        <Camera
          onChange={onChange}
          open={open}
          setOpen={setOpen}
          onClickBackBtn={() => {
            setOpen(false);
            navigate(CAR_DETECTION_PATH);
          }}
          type={CameraType.CarPosition}
          contourType={position}
        />
      )}
    </>
  );
};

export default CarDetectionPreviewPage;
