import { useRecoilState } from "recoil";
import { authState } from "states/authState";
import { loadingState } from "states/loadingState";
import { warningState } from "states/warningState";

export const useCommonState = () => {
  const [warningDialog, setWarningDialog] = useRecoilState(warningState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const [auth, setAuth] = useRecoilState(authState);
  return {
    warningDialog,
    setWarningDialog,
    loading,
    setLoading,
    auth,
    setAuth,
  };
};
