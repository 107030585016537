import React from "react";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "@heroicons/react/solid";

import Dialog from ".";
import { IConfirm } from "types/common.d";
import Button from "components/Button";

const DialogConfirm = ({
  title,
  message,
  children,
  open,
  onClose,
  onSubmit,
  position = "center",
}: IConfirm) => {
  const { t } = useTranslation();
  return (
    <Dialog
      title={title}
      isOpen={open}
      onCloseDialog={onClose}
      position={position}
    >
      {message && (
        <h2
          className="text-center pt-3 pb-1 text-primary-main-800"
          data-testid="dialog-confirm-desc"
        >
          {message}
        </h2>
      )}
      {children}
      <div className="flex justify-center mt-12">
        <Button
          className="text-center"
          variant="contained"
          color="primary"
          onClick={onSubmit}
          data-testid="dialog-confirm-submit-btn"
        >
          <span data-testid="dialog-confirm-submit-text">
            {t("confirmBtn")}
          </span>
          <CheckIcon stroke={"white"} width={16} height={16} className="ml-3" />
        </Button>
      </div>
    </Dialog>
  );
};

export default DialogConfirm;
