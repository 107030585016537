import React, { useState } from "react";
import InsuredImage from "assets/images/common/insured.png";
import Button from "components/Button";
import DialogContactStaff from "components/Dialog/DialogContactStaff";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { idCardState } from "states/idCardState";
import { ID_HOME_PATH } from "configs/page";
import { MailContactType } from "configs/common";

const SelectedInsuredPage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(idCardState);
  const [isOpenDialogContactStaff, setIsOpenDialogContactStaff] =
    useState<boolean>(false);

  const handleOnclick = (isInsurer: boolean) => {
    setUserInfo({
      ...userInfo,
      isInsurer: isInsurer,
    });

    if (isInsurer) {
      navigate(ID_HOME_PATH);
    } else {
      setIsOpenDialogContactStaff(true);
    }
  };

  return (
    <div className="flex flex-col items-center h-full">
      <div className="flex-1">
        <h1 className="flex-1 my-6" data-testid="insurance-title">
          {t("insurance.option.title")}
        </h1>
        <img
          src={InsuredImage}
          alt="insured icon"
          width={"100%"}
          data-testid="insurance-img"
        />
      </div>
      <div className="flex flex-1 w-full my-8 flex-col justify-end gap-4">
        <Link to={ID_HOME_PATH}>
          <Button
            fullWidth
            variant="gradient"
            color="primary"
            size="large"
            onClick={() => handleOnclick(true)}
            data-testid="insurance-self-btn"
          >
            {t("insurance.option.insured")}
          </Button>
        </Link>
        <Button
          fullWidth
          variant="gradient"
          color="secondary"
          size="large"
          onClick={() => handleOnclick(false)}
          data-testid="insurance-other-btn"
        >
          {t("insurance.option.notInsured")}
        </Button>
      </div>
      <DialogContactStaff
        contactType={MailContactType.InsuranceInterested}
        open={isOpenDialogContactStaff}
        onCloseDialog={() => {
          setIsOpenDialogContactStaff(false);
        }}
      />
    </div>
  );
};

export default SelectedInsuredPage;
