import { configs } from "configs/config";
import { axios } from "utils/axios";
import {
  IMtiModelListResponse,
  IMtiModel,
  IModel,
  IFetchModelList,
} from "types/model.d";

export const getModels = ({ queryKey }: IFetchModelList) => {
  const [, params] = queryKey;
  return axios.get<IMtiModelListResponse[]>(
    `${configs.apiUrl}/bpmp2/Cars/Models`,
    { params }
  );
};

export const convertModelData = (model: IMtiModel): IModel => ({
  makeCode: model.cMakeCode,
  family: model.cFamily,
  modelName: model.cModelName,
  bodyConfig: model.cBodyConfig,
  engineSize: model.cEngineSize,
  doorNum: model.cDoorNum,
  motorGroup: model.cMotorGroup,
  mtiKey: model.cMTIKey,
  priority: model.iPriority,
  isActive: model.cIsActive,
  fuelType: model.cFuelType,
});
