import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { generateTokenKYC, validateKYC } from "services/mti/kyc";
import {
  IKycGenerateTokenParams,
  IKycToken,
  IKycValidation,
} from "types/kyc.d";
import { useCommonState } from "./useCommonState";
import { useTranslation } from "react-i18next";
import { captureLogMessage, createExtras } from "utils/logging";
import { logTags } from "configs/logging";

export const useKYCGenerateToken = (): {
  tokenData: IKycToken | null;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  generateToken: UseMutateFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    IKycGenerateTokenParams,
    unknown
  >;
} => {
  const { t } = useTranslation();
  const { setWarningDialog } = useCommonState();
  const { data, isLoading, isError, error, mutate } = useMutation(
    (body: IKycGenerateTokenParams) => generateTokenKYC(body),
    {
      onSuccess: (resp, body) => {
        if (!resp.data.result.accessToken) {
          captureLogMessage(
            "cannot generate access token",
            {
              ...logTags.request,
              requestURL: resp.config.url ?? "",
            },
            createExtras(body, resp)
          );
          throw new Error("cannot generate access token");
        }
      },
      onError: () => {
        setWarningDialog({
          title: t("warning.common"),
          message: t("warning.retry"),
        });
      },
    }
  );

  const tokenData = data?.data?.result ?? null;
  return {
    generateToken: mutate,
    tokenData,
    isLoading,
    isError,
    error,
  };
};

export const useKYCValidation = (): {
  kycData: IKycValidation | null;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  validateKYC: UseMutateFunction<
    AxiosResponse<unknown, unknown>,
    unknown,
    string,
    unknown
  >;
} => {
  const { t } = useTranslation();
  const { setWarningDialog } = useCommonState();
  const { data, isLoading, isError, error, mutate } = useMutation(
    (accessToken: string) => validateKYC(accessToken),
    {
      onError: () => {
        setWarningDialog({
          title: t("warning.common"),
          message: t("warning.retry"),
        });
      },
    }
  );

  const kycData = data?.data?.result ?? null;
  return {
    validateKYC: mutate,
    kycData,
    isLoading,
    isError,
    error,
  };
};
