import React from "react";
import QRCode from "react-qr-code";

interface Props {
  size?: number;
  value: string;
  backgroundColor?: string;
  foregroundColor?: string;
  quantity?: string;
  className?: string;
}

const Qr = ({
  size,
  value,
  foregroundColor,
  quantity,
  className,
  backgroundColor = "#FFFFFF",
}: Props) => {
  size = size || 100;

  return (
    <div className={className ?? ``} data-testid="qr">
      <QRCode
        size={size}
        value={value}
        bgColor={backgroundColor || ``}
        fgColor={foregroundColor ?? ``}
        level={quantity || "L"}
      />
    </div>
  );
};

export default Qr;
