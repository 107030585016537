import { useResetRecoilState } from "recoil";
import { authState } from "states/authState";
import { carInfoState } from "states/carInfoState";
import { carPackageState } from "states/carPackageState";
import { idCardState } from "states/idCardState";
import { transactionState } from "states/transactionState";
import { loadingState } from "states/loadingState";
import { searchParamsState } from "states/searchParamsState";
import { warningState } from "states/warningState";
import { HASH_NO_BACK, LANDING_PATH } from "configs/page";

export const useResetState = () => {
  const resetAuth = useResetRecoilState(authState);
  const resetCarInfo = useResetRecoilState(carInfoState);
  const resetCarPackage = useResetRecoilState(carPackageState);
  const resetIdCard = useResetRecoilState(idCardState);
  const resetTransactionState = useResetRecoilState(transactionState);
  const resetLoading = useResetRecoilState(loadingState);
  const resetSearchParam = useResetRecoilState(searchParamsState);
  const resetWarning = useResetRecoilState(warningState);

  const logoutReset = (includeAuth = true) => {
    if (includeAuth) resetAuth();

    resetCarInfo();
    resetCarPackage();
    resetIdCard();
    resetTransactionState();
    resetLoading();
    resetWarning();
  };

  const goToHomePage = (isHash = false) => {
    const hash = isHash ? "#" + HASH_NO_BACK : "";
    window.location.replace(LANDING_PATH + hash);
  };

  const clearBackState = (isBack = true, isHash = false) => {
    logoutReset(false);
    if (isBack) window.history.back();
    goToHomePage(isHash);
  };

  const resetAll = () => {
    resetSearchParam();
    logoutReset();
  };

  return { logoutReset, resetAll, clearBackState, goToHomePage };
};
