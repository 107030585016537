import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { logTags } from "configs/logging";
import { postBookOCR } from "services/ai/book-ocr";
import { IBookOCRResponse } from "types/book-ocr.d";
import { formatFormDataToObject } from "utils/formatter";
import { captureLogMessage, createExtras } from "utils/logging";

export const useBookOCR = (): {
  bookOCRMutate: UseMutateFunction<
    AxiosResponse<unknown, unknown> | null,
    unknown,
    FormData,
    unknown
  >;
} => {
  const { mutate: bookOCRMutate } = useMutation(
    (body: FormData) => postBookOCR(body),
    {
      onSuccess: (resp, body) => {
        const respData = resp?.data as IBookOCRResponse;
        if (!respData?.data) {
          const bodyParam = formatFormDataToObject(body);
          captureLogMessage(
            "OCR return null response",
            {
              ...logTags.request,
              requestURL: resp.config.url ?? "",
            },
            createExtras(bodyParam, resp)
          );
          throw new Error("cannot extract data");
        }
      },
    }
  );
  return {
    bookOCRMutate,
  };
};
