import { configs } from "configs/config";
import { axios } from "utils/axios";
import {
  IMtiProvinceListResponse,
  IMtiProvince,
  IProvince,
  IFetchDistrictList,
  IMtiDistrictListResponse,
  IMtiDistrict,
  IDistrict,
  IFetchSubdistrictList,
  IMtiSubdistrictListResponse,
  IMtiSubdistrict,
  ISubdistrict,
} from "types/address.d";

export const getProvince = () => {
  return axios.get<IMtiProvinceListResponse[]>(
    `${configs.apiUrl}/bpmp2/Utilities/Province`
  );
};

export const convertProvinceData = (province: IMtiProvince): IProvince => ({
  provinceID: province.ProvinceID,
  provinceName: province.ProvinceName,
});

export const getDistrict = ({ queryKey }: IFetchDistrictList) => {
  const [, params] = queryKey;
  return axios.get<IMtiDistrictListResponse[]>(
    `${configs.apiUrl}/bpmp2/Utilities/Ampur`,
    { params }
  );
};

export const convertDistrictData = (Ampur: IMtiDistrict): IDistrict => ({
  districtID: Ampur.DistrictID,
  districtName: Ampur.DistrictName,
  provinceID: Ampur.ProvinceID,
});

export const getSubdistrict = ({ queryKey }: IFetchSubdistrictList) => {
  const [, params] = queryKey;
  return axios.get<IMtiSubdistrictListResponse[]>(
    `${configs.apiUrl}/bpmp2/Utilities/Tumbol`,
    { params }
  );
};

export const convertSubdistrictData = (
  Tumbol: IMtiSubdistrict
): ISubdistrict => ({
  subdistrictID: Tumbol.SubdistrictID,
  subdistrictName: Tumbol.SubdistrictName,
  districtID: Tumbol.DistrictID,
  provinceID: Tumbol.ProvinceID,
  zipcode: Tumbol.ZipCode,
});
