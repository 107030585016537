import { IOptionPackageConfig } from "types/common.d";

export enum CageAmt {
  N = "-1",
  Y = "0",
}

export enum CarType {
  Sedan = 1,
  Van = 2,
  Pickup = 3,
}

export const workshopConfig: { label: { [key: string]: string } } = {
  label: {
    N: "package.config.workshop.garageFull",
    Y: "package.config.workshop.shopFull",
  },
};

export const carTypeValue: { [key: string]: string } = {
  [CarType.Sedan]: "package.config.carType.sedan",
  [CarType.Van]: "package.config.carType.van",
  [CarType.Pickup]: "package.config.carType.pickup",
};

export const driverAgeValue: { [key: string]: string } = {
  "-1": "undefined",
  "20": "18-24",
  "30": "25-35",
  "40": "36-50",
  "60": "package.config.age.greaterThan50",
};

export const driverAgeOptions: IOptionPackageConfig[] = [
  { name: driverAgeValue[-1], value: -1 },
  { name: driverAgeValue[20], value: 20 },
  { name: driverAgeValue[30], value: 30 },
  { name: driverAgeValue[40], value: 40 },
  { name: driverAgeValue[60], value: 60 },
];

export const workshopOptionDefault: IOptionPackageConfig = {
  name: "package.config.workshop.both",
  value: -1,
};

export const workshopOptions: IOptionPackageConfig[] = [
  { name: "package.config.workshop.garage", value: 1 },
  { name: "package.config.workshop.shop", value: 2 },
  workshopOptionDefault,
];

export const useCarOptions: IOptionPackageConfig[] = [
  { name: "package.config.useCar.personal", value: 1 },
  { name: "package.config.useCar.commercial", value: 2 },
];

export const deductAmountODOptions: IOptionPackageConfig[] = [
  { name: "undefined", value: 0 },
  { name: "500", value: 500 },
  { name: "1,500", value: 1500 },
  { name: "2,000", value: 2000 },
  { name: "3,000", value: 3000 },
  { name: "4,000", value: 4000 },
  { name: "5,000", value: 5000 },
  { name: "6,000", value: 6000 },
  { name: "7,000", value: 7000 },
  { name: "8,000", value: 8000 },
  { name: "9,000", value: 9000 },
  { name: "10,000", value: 10000 },
];

export const deductAmountTPOptions: IOptionPackageConfig[] = [
  { name: "undefined", value: 0 },
  { name: "500", value: 500 },
  { name: "1,500", value: 1500 },
  { name: "2,000", value: 2000 },
  { name: "3,000", value: 3000 },
  { name: "4,000", value: 4000 },
  { name: "5,000", value: 5000 },
  { name: "6,000", value: 6000 },
  { name: "7,000", value: 7000 },
  { name: "8,000", value: 8000 },
  { name: "9,000", value: 9000 },
  { name: "10,000", value: 10000 },
];
