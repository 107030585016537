import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { KYC_PATH } from "configs/page";
import { MailContactType } from "configs/common";
import Button from "components/Button";
import DialogContactStaff from "components/Dialog/DialogContactStaff";
import { IIdInfoForm } from "types/id-info.d";
import { searchParamsState } from "states/searchParamsState";
import { idCardState } from "states/idCardState";
import { sentAddressValue } from "configs/sent-insurance-option";
import { IIDAllData } from "types/id-ocr.d";
import {
  checkBuyableAge,
  formatOCRResponseDateToString,
  isIdNumber,
  isStartWithZero,
} from "utils/formatter";
import AddressForm from "./components/AddressForm";
import UserForm from "./components/UserForm";
import SentOptionForm from "./components/SentOptionForm";
import { useCommonState } from "hooks/useCommonState";
import { useCheckBlackList } from "hooks/useTransaction";

interface OcrProps {
  ocrData: IIDAllData;
}

const IDRegisterPage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const searchParams = useRecoilValue(searchParamsState);
  const { setLoading } = useCommonState();
  const [userInfo, setUserInfo] = useRecoilState(idCardState);
  const [isOpenDialogContactStaff, setIsOpenDialogContactStaff] =
    useState<boolean>(false);
  const { state } = useLocation();

  const blackListMutation = useCheckBlackList();
  const ocrState = state as OcrProps;
  const ocrData = ocrState?.ocrData;

  const defaultValues: IIdInfoForm = {
    user: {
      id: userInfo.user.id || "",
      prefix: userInfo.user.prefix ?? null,
      firstName: userInfo.user.firstName || "",
      lastName: userInfo.user.lastName || "",
      birth: userInfo.user.birth ?? null,
      email: userInfo.user.email || "",
      tel: userInfo.user.tel || "",
    },
    address: userInfo.address || null,
    sentOption: userInfo?.sentOption || sentAddressValue.email,
    sentAddress: userInfo?.sentAddress,
    isSentAddressAsIDCard: userInfo.isSentAddressAsIDCard,
    isInsurer: userInfo.isInsurer ?? null,
  };

  const userSchema = yup.object({
    id: yup
      .string()
      .required(t("user.inputError.required.id"))
      .test("isIdNum", t("user.inputError.format.id"), (value) => {
        const id = value?.replaceAll("-", "") ?? "";
        return isIdNumber(id);
      })
      .length(13, t("user.inputError.format.id")),
    prefix: yup
      .object()
      .required(t("user.inputError.required.prefix"))
      .nullable(),
    firstName: yup
      .string()
      .required(t("user.inputError.required.firstName"))
      .nullable(),
    lastName: yup
      .string()
      .required(t("user.inputError.required.lastName"))
      .nullable(),
    birth: yup
      .date()
      .typeError(t("user.inputError.required.birth"))
      .required(t("user.inputError.required.birth"))
      .test("isBuyable", t("user.inputError.format.birth"), (value) => {
        if (!value) return false;
        return checkBuyableAge(value);
      }),
    email: yup
      .string()
      .email(t("user.inputError.format.email"))
      .required(t("user.inputError.required.email"))
      .nullable(),
    tel: yup
      .string()
      .required(t("user.inputError.required.tel"))
      .nullable()
      .test("isStartWithZero", t("user.inputError.format.tel"), (value) => {
        if (!value) return false;
        return isStartWithZero(value);
      })
      .length(10, t("user.inputError.format.tel")),
  });

  const addressSchema = yup.object({
    buildNo: yup
      .string()
      .required(t("address.inputError.required.buildNo"))
      .nullable(),
    moo: yup.string().nullable(),
    buildName: yup.string().nullable(),
    floor: yup.string().nullable(),
    room: yup.string().nullable(),
    soi: yup.string().nullable(),
    road: yup.string().nullable(),
    province: yup
      .object()
      .required(t("address.inputError.required.province"))
      .nullable(),
    district: yup
      .object()
      .required(t("address.inputError.required.district"))
      .nullable(),
    subdistrict: yup
      .object()
      .required(t("address.inputError.required.subdistrict"))
      .nullable(),
    zipcode: yup
      .string()
      .required(t("address.inputError.required.zipcode"))
      .nullable(),
  });

  const schema = yup
    .object({
      user: userSchema,
      address: addressSchema,
      sentAddress: addressSchema.when("isSentAddressAsIDCard", {
        is: (val: boolean) => val,
        then: yup.object().notRequired().nullable(),
        otherwise: addressSchema,
      }),
      sentOption: yup.string().required(t("sentOption")),
      isSentAddressAsIDCard: yup.boolean().nullable(),
    })
    .required();

  const methods = useForm<IIdInfoForm>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: "onChange",
  });

  const { setValue, handleSubmit, control } = methods;
  const watchSentOption = useWatch({ control: control, name: "sentOption" });
  const watchIsSentAddressAsIDCard = useWatch({
    control: control,
    name: "isSentAddressAsIDCard",
  });

  useEffect(() => {
    if (watchSentOption === sentAddressValue.email) {
      setValue("isSentAddressAsIDCard", true);
      setValue("sentAddress", null);
    }
  }, [setValue, watchSentOption]);

  useEffect(() => {
    if (watchIsSentAddressAsIDCard) {
      setValue("sentAddress", null);
    }
  }, [setValue, watchIsSentAddressAsIDCard]);

  useEffect(() => {
    if (ocrData) {
      if (ocrData?.dateOfBirth) {
        const formatBirthday = formatOCRResponseDateToString(
          ocrData.dateOfBirth
        );
        setValue("user.birth", new Date(formatBirthday));
      }
      setValue("user.id", ocrData?.thaiNationalId || "");
      setValue("user.firstName", ocrData?.nameTh?.firstName);
      setValue("user.lastName", ocrData?.nameTh?.lastName);

      if (ocrData?.address?.alley) {
        const soi = ocrData.address.alley.replace(/^ซ./, "").trim();
        setValue("address.soi", soi);
      }
      setValue("address.buildNo", ocrData?.address?.houseNo);
      setValue("address.buildName", ocrData?.address?.villageNo);
      setValue("address.road", ocrData?.address?.road);
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (blackListMutation.isBlackList === true) {
      setIsOpenDialogContactStaff(true);
    } else if (blackListMutation.isBlackList === false) {
      navigate(KYC_PATH);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blackListMutation.isBlackList]);

  useEffect(() => {
    setLoading(blackListMutation.isLoading);

    return () => setLoading(false);
  }, [setLoading, blackListMutation.isLoading]);

  const onSubmit = (dataForm: IIdInfoForm) => {
    const saveForm: IIdInfoForm = {
      user: {
        ...dataForm.user,
      },
      address: dataForm.address,
      sentOption: dataForm.sentOption,
      sentAddress: dataForm?.sentAddress ?? null,
      isSentAddressAsIDCard: dataForm.isSentAddressAsIDCard,
      isInsurer: userInfo.isInsurer,
    };
    setUserInfo(saveForm);

    blackListMutation.checkBlackListKYC({
      sIDNo: saveForm.user.id,
      sFirstName: saveForm.user.firstName,
      sLastName: saveForm.user.lastName,
    });
  };

  return (
    <div className="py-3">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <UserForm t={t} userPrefix={ocrData?.nameTh?.title} />

          <AddressForm t={t} ocrAddress={ocrData?.address} />
          <SentOptionForm t={t} />
          {watchSentOption !== sentAddressValue.email && (
            <AddressForm t={t} isOtherSentAddress={true} />
          )}
          <div className="py-3">
            <Button
              fullWidth
              type="submit"
              variant="gradient"
              color="primary"
              size="large"
              data-testid="id-register-submit-btn"
            >
              {t("confirmBtn")}
            </Button>
          </div>
        </form>
      </FormProvider>
      {!!searchParams?.debug && (
        <p className="break-all">{JSON.stringify(ocrData)}</p>
      )}
      <DialogContactStaff
        contactType={MailContactType.BlackList}
        open={isOpenDialogContactStaff}
        onCloseDialog={() => {
          setIsOpenDialogContactStaff(false);
        }}
      />
      <DevTool control={methods.control} />
    </div>
  );
};

export default IDRegisterPage;
