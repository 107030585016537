import { configs } from "configs/config";
import { axios } from "utils/axios";
import {
  IMtiPrefixListResponse,
  IMtiPrefix,
  IPrefix,
  IFetchPrefixList,
} from "types/user.d";

export const getPrefix = ({ queryKey }: IFetchPrefixList) => {
  const [, params] = queryKey;
  return axios.get<IMtiPrefixListResponse[]>(
    `${configs.apiUrl}/bpmp2/Utilities/Prefix`,
    { params }
  );
};

export const convertPrefixData = (prefix: IMtiPrefix): IPrefix => ({
  prefixCode: prefix.PrefixCode,
  prefixType: prefix.PrefixType,
  prefixDescription: prefix.PrefixDescription,
  gender: prefix.gender,
});
