import React from "react";
import * as yup from "yup";
import { useRecoilValue } from "recoil";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { addYears } from "date-fns";
import { CheckIcon } from "@heroicons/react/solid";
import { carInfoState } from "states/carInfoState";
import { useSendMailContact } from "hooks/useMail";
import { configs } from "configs/config";
import { MailContactType } from "configs/common";
import TextField from "components/Input/TextField";
import Button from "components/Button";
import TextArea from "components/Input/TextArea";
import Select from "components/Input/Select";
import Calendar from "components/Input/Calendar";
import {
  formatDate,
  formatToNumberOnly,
  isStartWithZero,
} from "utils/formatter";
import Dialog from ".";

interface IContactCarInfo {
  makeCode?: string;
  family?: string;
  modelName?: string;
  yearGroup?: number;
}

interface Props {
  open: boolean;
  onCloseDialog: () => void;
  contactType: MailContactType;
  refNo?: string;
  carInfo?: IContactCarInfo;
}

const PERIOD_OPTION = [
  { period: "undefined" },
  { period: "contact.period.morning" },
  { period: "contact.period.afternoon" },
];

interface IPeriod {
  period: string;
}

type IFormInput = {
  name: string;
  tel: string;
  email: string;
  detail: string;
  period: IPeriod | null;
  date: Date | null;
};

const DialogContactStaff = ({
  open,
  onCloseDialog,
  contactType,
  refNo,
  carInfo,
}: Props) => {
  const { t } = useTranslation();
  const { sendMailContact } = useSendMailContact();
  const carInfoSelected = useRecoilValue(carInfoState);
  const today = new Date();
  const maxDate = addYears(today, 1);

  const defaultValues: IFormInput = {
    name: "",
    tel: "",
    email: "",
    detail: "",
    period: null,
    date: null,
  };

  const schema = yup
    .object({
      name: yup.string().required(t("contactAdmin.inputError.required.name")),
      tel: yup
        .string()
        .required(t("contactAdmin.inputError.required.tel"))
        .test(
          "isStartWithZero",
          t("contactAdmin.inputError.formatError.tel"),
          (value) => {
            if (!value) return false;
            return isStartWithZero(value);
          }
        )
        .length(10, t("contactAdmin.inputError.formatError.tel")),
      email: yup
        .string()
        .required(t("contactAdmin.inputError.required.email"))
        .email(t("contactAdmin.inputError.formatError.email")),
      detail: yup
        .string()
        .required(t("contactAdmin.inputError.required.detail")),
      period: yup
        .object()
        .shape({
          period: yup.string(),
        })
        .nullable(),
      date: yup
        .date()
        .min(
          new Date(today.setHours(0, 0, 0, 0)),
          t("contactAdmin.inputError.formatError.minDate")
        )
        .max(
          new Date(maxDate.setHours(23, 59, 59, 999)),
          t("contactAdmin.inputError.formatError.maxDate")
        )
        .nullable(),
    })
    .required();

  const { control, handleSubmit, reset } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: "onChange",
  });

  const onSubmit = (dataForm: IFormInput) => {
    const formData = new FormData();
    const useState = !carInfo?.makeCode; // send mail from car info form page use data from props, other page use data from recoil state
    const makeCode = useState
      ? carInfoSelected.brand?.makeCode
      : carInfo?.makeCode;
    const family = useState ? carInfoSelected.model?.family : carInfo?.family;
    const modelName = useState
      ? carInfoSelected.model?.modelName
      : carInfo?.modelName;
    const yearGroup = useState
      ? carInfoSelected.year?.yearGroup
      : carInfo?.yearGroup;

    formData.append("CustomerName", dataForm.name);
    formData.append("CustomerTel", dataForm.tel.replaceAll("-", ""));
    formData.append("CustomerEmail", dataForm.email);
    formData.append("Detail", dataForm.detail);
    formData.append("ContactType", contactType);
    formData.append("Owner", configs.mtiApiPartnerCode);
    formData.append("ContactDate", formatDate(dataForm.date));
    formData.append("ContactTime", t(dataForm.period?.period ?? ""));

    if (refNo) formData.append("ReferenceNo", refNo);
    if (makeCode) formData.append("CarMakeCode", makeCode);
    if (family) formData.append("CarFamily", family);
    if (modelName) formData.append("CarModelName", modelName);
    if (yearGroup) formData.append("CarYearGroup", `${yearGroup}`);

    sendMailContact(formData);
  };

  const handleOnClose = () => {
    reset();
    onCloseDialog();
  };

  return (
    <Dialog
      title={t("contactAdmin.title")}
      isOpen={open}
      onCloseDialog={handleOnClose}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-1 gap-6"
      >
        <p className="text-center text-primary-main-800 font-semibold text-lg">
          {t("contactAdmin.subtitle")}
        </p>
        <div className="grid grid-cols-1 gap-4">
          <div data-testid="contactAdmin-name">
            <Controller
              control={control}
              name="name"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label={t("contactAdmin.input.name")}
                  onChange={onChange}
                  value={value}
                  placeholder={t("contactAdmin.input.name")}
                  type="text"
                  errorMsg={error?.message}
                />
              )}
            />
          </div>
          <div data-testid="contactAdmin-tel">
            <Controller
              control={control}
              name="tel"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label={t("contactAdmin.input.tel")}
                  onChange={(e) => {
                    const val = formatToNumberOnly(e?.target?.value ?? "");
                    onChange(val);
                  }}
                  value={value}
                  placeholder={t("contactAdmin.input.tel")}
                  type="text"
                  inputMode="tel"
                  errorMsg={error?.message}
                  maxLength={10}
                />
              )}
            />
          </div>
          <div data-testid="contactAdmin-email">
            <Controller
              control={control}
              name="email"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label={t("contactAdmin.input.email")}
                  onChange={onChange}
                  value={value}
                  placeholder={t("contactAdmin.input.email")}
                  type="text"
                  inputMode="email"
                  errorMsg={error?.message}
                />
              )}
            />
          </div>
        </div>
        <div data-testid="contactAdmin-detail">
          <Controller
            control={control}
            name="detail"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextArea
                label={t("contactAdmin.detail")}
                onChange={onChange}
                value={value}
                errorMsg={error?.message}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-1 gap-1">
          <h3 className="mb-3">{t("contactAdmin.timeDetail")}</h3>
          <div>
            <Controller
              control={control}
              name="period"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  label={t("contactAdmin.period")}
                  keyTitle="period"
                  options={PERIOD_OPTION}
                  onChange={onChange}
                  value={value}
                  errorMsg={error?.message}
                  placeholder={t("contactAdmin.period")}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="date"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Calendar
                  label={t("contactAdmin.date")}
                  onChange={onChange}
                  value={value}
                  minDate={today}
                  maxDate={maxDate}
                  errorMsg={error?.message}
                  placeholder={t("contactAdmin.date")}
                />
              )}
            />
          </div>
        </div>

        <div className="flex justify-center py-3">
          <Button
            type="submit"
            variant="gradient"
            color="primary"
            size="medium"
            data-testid="contactAdmin-submit-btn"
          >
            {t("confirmBtn")}
            <CheckIcon
              stroke={"white"}
              width={16}
              height={16}
              className="ml-3"
            />
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default DialogContactStaff;
