import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { RecoilRoot, useSetRecoilState } from "recoil";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  LANDING_PATH,
  SESSION_EXPIRE_PATH,
  CAR_DETECTION_HOME_PATH,
  ORDER_SUCCESS_PATH,
  CAR_DETECTION_PATH,
  CAR_DETECTION_PREVIEW_PATH,
  CAR_SUCCESS_PATH,
  ORDER_STATUS_PATH,
} from "configs/page";
import { configs } from "configs/config";
import { agentNumber, FlowType } from "configs/common";
import { routes, privateRoutes } from "configs/routes";
import ScrollToTop from "components/ScrollToTop";
import Layout from "components/Layout";
import NotFound from "components/NotFound";
import { searchParamsState } from "states/searchParamsState";
import { useCommonState } from "hooks/useCommonState";

const VERSION = "1.9.7";
const TIMEOUT_MS = 1800000; // 30 mins

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1 * 60 * 1000, // 1 min
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});
interface IPrivateRoute {
  children: JSX.Element;
}
interface ISearchParamsProp {
  children?: React.ReactNode;
}

const SearchParams = ({ children }: ISearchParamsProp): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const setSearchParam = useSetRecoilState(searchParamsState);

  useEffect(() => {
    const isProduction = configs.env === "production";
    const params = Object.fromEntries([...searchParams]);
    const paramsMapped = {
      ...params,
      agent: params?.agent || agentNumber.mti,
      // debug and skipStatus mode support only non prod
      ...(params?.debug && { debug: !isProduction }),
      ...(params?.skipStatus && { skipStatus: !isProduction }),
    };

    setSearchParam(paramsMapped);

    if (params?.flow === FlowType.CarDetection) {
      const searchPath = `?refNo=${params?.refNo || ""}&agent=${
        paramsMapped.agent
      }`;
      navigate(
        {
          pathname: CAR_DETECTION_HOME_PATH,
          search: searchPath,
        },
        { replace: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

const PrivateRoute = ({ children }: IPrivateRoute) => {
  // fake auth for prevent direct access to url
  // set true from landing page and car landing
  const { auth } = useCommonState();

  return auth ? children : <Navigate to={LANDING_PATH} replace />;
};

// last click will reset timeout
const Session = (): JSX.Element => {
  const navigate = useNavigate();
  const { auth, setLoading } = useCommonState();
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    const setTimer = () => {
      return setTimeout(() => {
        setLoading(false);
        navigate(SESSION_EXPIRE_PATH, { replace: true });
      }, TIMEOUT_MS);
    };

    let timer = setTimer();

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimer();
    };

    const clearTimer = () => {
      clearTimeout(timer);
      window.removeEventListener("click", resetTimer);
    };

    window.addEventListener("click", resetTimer);

    if (!auth) {
      clearTimer();
    }

    if (
      path === CAR_DETECTION_HOME_PATH ||
      path === CAR_DETECTION_PATH ||
      path === CAR_DETECTION_PREVIEW_PATH ||
      path === CAR_SUCCESS_PATH ||
      path === ORDER_STATUS_PATH ||
      path === ORDER_SUCCESS_PATH ||
      path === "" ||
      path === LANDING_PATH
    ) {
      clearTimer();
    }
    return () => clearTimer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, path]);

  return <></>;
};

function App(): JSX.Element {
  return (
    <div className="w-full h-viewport">
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <SearchParams>
              <ScrollToTop />
              <Session />
              <Routes>
                {privateRoutes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <PrivateRoute>
                        <Layout
                          title={route.title}
                          showBackBtn={route.showBackBtn}
                          showStepper={route.showStepper}
                          showHeader={route.showHeader}
                          step={route.step}
                        >
                          {route.element}
                        </Layout>
                      </PrivateRoute>
                    }
                  />
                ))}
                {routes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      route.layout ? (
                        <Layout
                          title={route.title}
                          showBackBtn={route.showBackBtn}
                          showStepper={route.showStepper}
                          showHeader={route.showHeader}
                          step={route.step}
                        >
                          {route.element}
                        </Layout>
                      ) : (
                        route.element
                      )
                    }
                  />
                ))}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </SearchParams>
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={configs.env === "development"} />
        </QueryClientProvider>
      </RecoilRoot>
      <div className="fixed bottom-2 right-2 text-gray z-50">v: {VERSION}</div>
    </div>
  );
}

export default App;
