import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { CAR_BOOK_REGISTER_PATH, LANDING_PATH } from "configs/page";
import { configs } from "configs/config";
import { CameraType } from "configs/camera";
import { useBookOCR } from "hooks/useBookOCR";
import Camera from "components/Camera";
import ImagePreview from "components/ImagePreview";
import { IBookOCRResponse } from "types/book-ocr.d";
import { searchParamsState } from "states/searchParamsState";
import { dataURIToBlob } from "utils/file";
import { useCommonState } from "hooks/useCommonState";

const BookPreviewPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const { bookOCRMutate } = useBookOCR();
  const searchParam = useRecoilValue(searchParamsState);
  const { setLoading, setWarningDialog } = useCommonState();

  const onChange = (image: string) => {
    setOpen(false);
    if (image) {
      setImagePreview(image);
    }
  };

  const openCamera = () => {
    setOpen(true);
  };

  const onSubmit = async () => {
    if (imagePreview) {
      setLoading(true);
      const file = dataURIToBlob(imagePreview);
      const formData = new FormData();
      formData.append("image", file, "book");
      formData.append("owner", configs.mtiApiPartnerCode);
      bookOCRMutate(formData, {
        onSuccess(resp) {
          const ocrResp = resp?.data as IBookOCRResponse;
          const ocrData = ocrResp?.data;
          const usedData = {
            brandCar: ocrData?.brandCar,
            brandEngine: ocrData?.brandEngine,
            carNumber: ocrData?.carNumber,
            color: ocrData?.color,
            engineNumber: ocrData?.engineNumber,
            engineSize: ocrData?.engineSize,
            model: ocrData?.model,
            province: ocrData?.province,
            registrationDate: ocrData?.registrationDate,
            registrationNumberCar: ocrData?.registrationNumberCar,
            typeFuel: ocrData?.typeFuel,
            yearModel: ocrData?.yearModel,
          };
          navigate(CAR_BOOK_REGISTER_PATH, {
            state: {
              ocrData: usedData,
            },
          });
        },
        onError() {
          setWarningDialog({
            title: t("carInfo.ocr.fail"),
            onClose: () => {
              navigate(CAR_BOOK_REGISTER_PATH);
            },
          });
        },
        onSettled() {
          setLoading(false);
        },
      });
    }
  };

  useEffect(() => {
    return () => setLoading(false);
  }, [setLoading]);

  return (
    <>
      <ImagePreview
        src={imagePreview}
        alt="car-book"
        onRetake={openCamera}
        onSubmit={onSubmit}
        setImagePreview={setImagePreview}
        debug={!!searchParam?.debug}
      />

      {open && (
        <Camera
          onChange={onChange}
          open={open}
          setOpen={setOpen}
          onClickBackBtn={() => {
            setOpen(false);
            navigate(LANDING_PATH);
          }}
          type={CameraType.CarBook}
        />
      )}
    </>
  );
};

export default BookPreviewPage;
