import { useQuery } from "@tanstack/react-query";
import { getRegProvinces } from "services/mti/reg-province";
import { IRegProvinceListQueryKey } from "types/reg-province.d";

export const useRegProvinces = (isAutoFetch = true) => {
  const queryKey: IRegProvinceListQueryKey = ["regProvinces"];
  const { data, isLoading, isError, error, refetch } = useQuery(
    queryKey,
    getRegProvinces,
    { enabled: isAutoFetch }
  );

  const regProvinces = data?.data;

  return { data: regProvinces, isLoading, isError, error, refetch };
};
