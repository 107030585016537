import React, { useEffect, useState } from "react";
import { TFunction } from "react-i18next";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import Select from "components/Input/Select";
import TextField from "components/Input/TextField";
import { useProvince, useDistrict, useSubdistrict } from "hooks/useAddress";
import Toggle from "components/Input/Toggle";
import Divider from "components/Divider";
import { IOCRAddress } from "types/id-ocr.d";

type Props = {
  t: TFunction;
  isOtherSentAddress?: boolean;
  ocrAddress?: IOCRAddress;
};

const AddressForm = ({
  t,
  isOtherSentAddress,
  ocrAddress,
}: Props): JSX.Element => {
  const { control, setValue, clearErrors } = useFormContext();
  const [isFinishPresetOCR, setIsFinishPresetOCR] = useState(
    isOtherSentAddress ?? !ocrAddress
  );

  const addressKey = isOtherSentAddress ? "sentAddress" : "address";

  const provinceSelected = useWatch({
    control: control,
    name: `${addressKey}.province`,
  });
  const districtSelected = useWatch({
    control: control,
    name: `${addressKey}.district`,
  });
  const subdistrictSelected = useWatch({
    control: control,
    name: `${addressKey}.subdistrict`,
  });

  const isSentAddressAsIDCardSelected = useWatch({
    control: control,
    name: "isSentAddressAsIDCard",
  });

  const { data: provinceList } = useProvince();

  const { data: districtList } = useDistrict(
    {
      provinceid: provinceSelected?.provinceID ?? "",
    },
    !!provinceSelected
  );

  const { data: subdistrictList } = useSubdistrict(
    {
      provinceid: districtSelected?.provinceID ?? "",
      districtid: districtSelected?.districtID ?? "",
    },
    !!districtSelected
  );

  useEffect(() => {
    if (isOtherSentAddress) return;
    if (
      ocrAddress &&
      provinceList?.length &&
      !isFinishPresetOCR &&
      !provinceSelected
    ) {
      const ocrProvinceOption = provinceList.find((option) =>
        ocrAddress?.province?.includes(option?.provinceName)
      );
      if (ocrProvinceOption?.provinceID)
        setValue(`${addressKey}.province`, ocrProvinceOption);
      else setIsFinishPresetOCR(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ocrAddress, provinceList]);

  useEffect(() => {
    if (isOtherSentAddress) return;
    if (
      ocrAddress &&
      districtList?.length &&
      !isFinishPresetOCR &&
      !districtSelected
    ) {
      const ocrDistrictOption = districtList.find((option) =>
        ocrAddress?.district?.includes(option?.districtName)
      );
      if (ocrDistrictOption?.districtID)
        setValue(`${addressKey}.district`, ocrDistrictOption);
      else setIsFinishPresetOCR(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ocrAddress, districtList]);

  useEffect(() => {
    if (isOtherSentAddress) return;
    if (
      ocrAddress &&
      subdistrictList?.length &&
      !isFinishPresetOCR &&
      !subdistrictSelected
    ) {
      const ocrSubDistrictOption = subdistrictList.find((option) =>
        ocrAddress?.subDistrict?.includes(option?.subdistrictName)
      );
      if (ocrSubDistrictOption?.subdistrictID)
        setValue(`${addressKey}.subdistrict`, ocrSubDistrictOption);
      setIsFinishPresetOCR(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOtherSentAddress, ocrAddress, subdistrictList]);

  useEffect(() => {
    if (subdistrictSelected?.zipcode) {
      setValue(`${addressKey}.zipcode`, subdistrictSelected?.zipcode);
      clearErrors(`${addressKey}.zipcode`);
    }
  }, [addressKey, clearErrors, setValue, subdistrictSelected]);

  return (
    <>
      <Divider className="my-3 border-gray-50" size="large" />
      <h2
        className="text-primary-input-label py-3"
        data-testid={`id-register-user-address-label${
          isOtherSentAddress ? "-policy" : ""
        }`}
      >
        {isOtherSentAddress
          ? t("user.addressByPolicy")
          : t("user.addressByIdCard")}
      </h2>
      {isOtherSentAddress && (
        <div
          className="py-3 flex flex-row gap-4"
          data-testid="id-register-user-address-send"
        >
          <Controller
            control={control}
            name={"isSentAddressAsIDCard"}
            render={({ field: { onChange, value } }) => {
              return (
                <Toggle
                  label={t("address.sentByIdCard")}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </div>
      )}

      {(!isSentAddressAsIDCardSelected || !isOtherSentAddress) && (
        <>
          <div className="py-3" data-testid="id-register-user-address-buildNo">
            <Controller
              control={control}
              name={`${addressKey}.buildNo`}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    label={t("address.buildNo")}
                    onChange={onChange}
                    value={value ?? ""}
                    placeholder={t("address.buildNo")}
                    errorMsg={error?.message}
                  />
                );
              }}
            />
          </div>
          <div className="py-3" data-testid="id-register-user-address-moo">
            <Controller
              control={control}
              name={`${addressKey}.moo`}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    label={t("address.moo")}
                    onChange={onChange}
                    value={value ?? ""}
                    placeholder={t("address.moo")}
                    errorMsg={error?.message}
                  />
                );
              }}
            />
          </div>
          <div
            className="py-3"
            data-testid="id-register-user-address-buildName"
          >
            <Controller
              control={control}
              name={`${addressKey}.buildName`}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    label={t("address.buildName")}
                    onChange={onChange}
                    value={value ?? ""}
                    placeholder={t("address.buildName")}
                    errorMsg={error?.message}
                  />
                );
              }}
            />
          </div>
          <div className="py-3 flex flex-row justify-between w-full gap-4">
            <div
              className="flex-1"
              data-testid="id-register-user-address-floor"
            >
              <Controller
                control={control}
                name={`${addressKey}.floor`}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      label={t("address.floor")}
                      onChange={onChange}
                      value={value ?? ""}
                      placeholder={t("address.floor")}
                      errorMsg={error?.message}
                    />
                  );
                }}
              />
            </div>
            <div className="flex-1" data-testid="id-register-user-address-room">
              <Controller
                control={control}
                name={`${addressKey}.room`}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      label={t("address.room")}
                      onChange={onChange}
                      value={value ?? ""}
                      placeholder={t("address.room")}
                      errorMsg={error?.message}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="py-3" data-testid="id-register-user-address-soi">
            <Controller
              control={control}
              name={`${addressKey}.soi`}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    label={t("address.soi")}
                    onChange={onChange}
                    value={value ?? ""}
                    placeholder={t("address.soi")}
                    errorMsg={error?.message}
                  />
                );
              }}
            />
          </div>
          <div className="py-3" data-testid="id-register-user-address-road">
            <Controller
              control={control}
              name={`${addressKey}.road`}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    label={t("address.road")}
                    onChange={onChange}
                    value={value ?? ""}
                    placeholder={t("address.road")}
                    errorMsg={error?.message}
                  />
                );
              }}
            />
          </div>
          <div className="py-3" data-testid="id-register-user-address-province">
            <Controller
              control={control}
              name={`${addressKey}.province`}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  label={t("address.province")}
                  keyTitle={"provinceName"}
                  onChange={(e) => {
                    setValue(`${addressKey}.zipcode`, "");
                    setValue(`${addressKey}.subdistrict`, null);
                    setValue(`${addressKey}.district`, null);
                    onChange(e);
                  }}
                  value={value}
                  options={provinceList}
                  placeholder={t("address.province")}
                  errorMsg={error?.message}
                />
              )}
            />
          </div>
          <div className="py-3" data-testid="id-register-user-address-district">
            <Controller
              control={control}
              name={`${addressKey}.district`}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  label={t("address.district")}
                  keyTitle={"districtName"}
                  onChange={(e) => {
                    setValue(`${addressKey}.zipcode`, "");
                    setValue(`${addressKey}.subdistrict`, null);
                    onChange(e);
                  }}
                  value={value}
                  options={districtList}
                  placeholder={t("address.district")}
                  errorMsg={error?.message}
                />
              )}
            />
          </div>
          <div
            className="py-3"
            data-testid="id-register-user-address-subdistrict"
          >
            <Controller
              control={control}
              name={`${addressKey}.subdistrict`}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  label={t("address.subdistrict")}
                  keyTitle={"subdistrictName"}
                  onChange={onChange}
                  value={value}
                  options={subdistrictList}
                  placeholder={t("address.subdistrict")}
                  errorMsg={error?.message}
                />
              )}
            />
          </div>
          <div className="py-3" data-testid="id-register-user-address-zipcode">
            <Controller
              control={control}
              name={`${addressKey}.zipcode`}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <TextField
                  label={t("address.zipcode")}
                  onChange={onChange}
                  value={subdistrictSelected?.zipcode ?? ""}
                  placeholder={t("address.zipcode")}
                  disabled
                  errorMsg={error?.message}
                />
              )}
            />
          </div>
        </>
      )}
    </>
  );
};

export default AddressForm;
