import React, { useEffect, useState } from "react";
import Toggle from "components/Input/Toggle";
import { TFunction } from "i18next";
import { ICarInfo, ICarUniqueInfoForm } from "types/car-info.d";
import { Controller, UseFormReturn } from "react-hook-form";
import Select from "components/Input/Select";
import { useColors } from "hooks/useColor";
import TextField from "components/Input/TextField";
import { useCommonState } from "hooks/useCommonState";
import { formatToLicensePlate } from "utils/formatter";

export interface ICarUniqueProps {
  t: TFunction;
  carInfo: ICarInfo | null;
  methods: UseFormReturn<ICarUniqueInfoForm>;
}

const CarUniqueDetail = ({
  t,
  carInfo,
  methods,
}: ICarUniqueProps): JSX.Element => {
  const { setLoading } = useCommonState();
  const { colors, isLoading } = useColors();
  const { control, setValue, watch, clearErrors } = methods;
  const watchIsRedPlate = watch("isNew");
  const [prevPlateNumber, setPrevPlateNumber] = useState(
    watchIsRedPlate
      ? ""
      : carInfo?.carUniqueInfo?.plateNumber ??
          carInfo?.ocrData?.registrationNumberCar ??
          ""
  );

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  useEffect(() => {
    if (watchIsRedPlate) {
      setValue("plateNumber", "NEW");
      clearErrors("plateNumber");
    } else {
      setValue("plateNumber", prevPlateNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchIsRedPlate, setValue]);

  useEffect(() => {
    if (!carInfo?.ocrData || carInfo?.carUniqueInfo?.chassisSerialNumber)
      return;
    setValue("plateNumber", carInfo.ocrData?.registrationNumberCar || "");

    const presetColor = colors?.find(
      (color) => color?.ColorNameTH === carInfo.ocrData?.color
    );
    setValue("color", presetColor ?? null);

    setValue("chassisSerialNumber", carInfo.ocrData?.carNumber || "");
    setValue("engineSerialNumber", carInfo.ocrData?.engineNumber || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colors, setValue]);

  return (
    <>
      <div
        className="flex flex-row-reverse py-4 gap-x-2 justify-end"
        data-testid="car-summary-redPlate"
      >
        <Controller
          control={control}
          name="isNew"
          render={({ field: { onChange, value } }) => (
            <Toggle
              label={t("carInfo.redPlate")}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </div>
      <div className="py-3" data-testid="car-summary-plateNumber">
        <Controller
          control={control}
          name="plateNumber"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t("carInfo.plateNumber")}
              onChange={(e) => {
                const formatVal = formatToLicensePlate(e?.target?.value ?? "");
                setPrevPlateNumber(formatVal);
                onChange(formatVal);
              }}
              value={value}
              placeholder={t("carInfo.plateNumber")}
              type="text"
              errorMsg={error?.message}
              disabled={watchIsRedPlate}
            />
          )}
        />
      </div>
      <div className="py-3" data-testid="car-summary-color">
        <Controller
          control={control}
          name="color"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              label={t("carInfo.color")}
              keyTitle="ColorNameTH"
              onChange={onChange}
              value={value}
              options={colors}
              placeholder={t("carInfo.color")}
              errorMsg={error?.message}
            />
          )}
        />
      </div>
      <div className="py-3" data-testid="car-summary-chassisSerialNumber">
        <Controller
          control={control}
          name="chassisSerialNumber"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t("carInfo.chassisSerialNumber")}
              onChange={onChange}
              value={value}
              placeholder={t("carInfo.chassisSerialNumber")}
              type="text"
              maxLength={30}
              errorMsg={error?.message}
            />
          )}
        />
      </div>
      <div className="py-3" data-testid="car-summary-engineSerialNumber">
        <Controller
          control={control}
          name="engineSerialNumber"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t("carInfo.engineSerialNumber")}
              onChange={onChange}
              value={value}
              placeholder={t("carInfo.engineSerialNumber")}
              type="text"
              errorMsg={error?.message}
            />
          )}
        />
      </div>
    </>
  );
};
export default CarUniqueDetail;
