import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosError } from "axios";
import { useCommonState } from "hooks/useCommonState";
import { useCheckPolicy, useRequestGeneratePolicy } from "hooks/useTransaction";
import { configs } from "configs/config";
import { logTags } from "configs/logging";
import { carPolicyStatus } from "configs/car";
import {
  ORDER_SUCCESS_PATH,
  ORDER_STATUS_PATH,
  NOTFOUND_PATH,
} from "configs/page";
import { captureLogMessage } from "utils/logging";

/*
  NOTE: logic
  STEP 1. check status payment - call api /CheckPolicyStatus payment check payment status = true
  STEP 2. request to generate policy - call api /RequestGeneratePolicyByPropId (if buy insurance and ctp call 2 proposal)
*/

const PaymentResultPage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    policyData,
    checkPolicy,
    isLoading: isLoadingGetPolicy,
  } = useCheckPolicy();
  const { requestGeneratePolicyAsync, isLoading: isLoadingGenPolicy } =
    useRequestGeneratePolicy();
  const { setLoading, setWarningDialog } = useCommonState();
  const [searchParams] = useSearchParams();
  const currentParams = Object.fromEntries([...searchParams]);
  const refNoParams = currentParams?.refNo;
  const propIdParams = currentParams?.propId ?? "";
  const propIds = propIdParams.split(",");

  useEffect(() => {
    setLoading(isLoadingGenPolicy || isLoadingGetPolicy);
  }, [isLoadingGenPolicy, isLoadingGetPolicy, setLoading]);

  useEffect(() => {
    if (refNoParams && propIdParams) getPolicyData(refNoParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refNoParams, propIdParams]);

  useEffect(() => {
    if (policyData?.payment) {
      if (policyData.payment?.status) {
        requestPolicy(refNoParams, propIds);
      } else {
        navigate(
          `${ORDER_STATUS_PATH}?status=${carPolicyStatus.waitingPayment}`
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyData]);

  useEffect(() => {
    if (!refNoParams || !propIdParams) navigate(NOTFOUND_PATH);
  }, [navigate, propIdParams, refNoParams]);

  const getPolicyData = async (refNo: string) => {
    // STEP 1
    checkPolicy(
      {
        referenceNo: refNo,
        owner: configs.mtiApiPartnerCode,
      },
      {
        onError: () => {
          showModalError(() => {
            getPolicyData(refNoParams);
          });
        },
      }
    );
  };

  const requestPolicy = async (refNo: string, propIds: string[]) => {
    // STEP 2
    const policyWithMutation = propIds.map((propId) => {
      return requestGeneratePolicyAsync({ PropId: propId });
    });

    try {
      const respList = await Promise.all(policyWithMutation);
      const errorList = (respList || []).filter((resp) => !resp?.data?.Success);

      if (errorList.length === 0) {
        navigate(`${ORDER_SUCCESS_PATH}?refNo=${refNo}`);
        return;
      }

      const errorDescList = (respList || []).map(
        (resp) => resp?.data?.PropId + " " + resp?.data?.Description || ""
      );
      if (configs.env !== "production") {
        alert("error: " + errorDescList.toString());
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const logData: object = respList.reduce((acc: any, cur, i) => {
        acc[i] = {
          respData: cur?.data,
          status: cur?.status,
          header: cur?.headers,
        };
        return acc;
      }, {});

      const errMsg = "cannot generate policy";
      captureLogMessage(
        errMsg,
        {
          ...logTags.request,
          requestURL: respList?.[0]?.config?.url ?? "",
        },
        logData
      );
      throw new Error(errMsg);
    } catch (err) {
      const error = err as AxiosError;
      if (configs.env !== "production") {
        if (error?.message || error?.code) {
          alert("error: " + error?.message || error?.code || "");
        }
      }
      showModalError(() => {
        requestPolicy(refNoParams, propIds);
      });
    }
  };

  const showModalError = (onRetry?: () => void) => {
    setWarningDialog({
      title: t("genPolicy.fail.title"),
      message: t("order.warning.detail", {
        contactNumber: configs.contactNumber,
        officeHours: configs.officeHours,
      }),
      onSubmit: typeof onRetry === "function" ? () => onRetry() : undefined,
      onCancel: () => {
        window.location.href = configs.telLink;
        setWarningDialog(null);
      },
      showClose: false,
      enableCloseOnClickOutside: false,
      textSubmit: t("retryBtn"),
      textCancel: t("order.warning.contact", {
        contactNumber: configs.contactNumber,
      }),
    });
  };

  return <></>;
};

export default PaymentResultPage;
