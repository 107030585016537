import React from "react";
import { Switch } from "@headlessui/react";
import Label from "../Label";

type Props = {
  className?: string;
  classNameLabel?: string;
  label?: string;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  value: boolean;
};

interface IToggleConfig {
  [key: string]: string;
}

const toggleConfig: IToggleConfig = {
  default: "bg-primary-main-300",
  disabled: "bg-input-disabled-bg",
  enabled: "bg-primary",
};

const Toggle = ({
  label,
  onChange,
  disabled,
  value: enabled,
  className = "",
  classNameLabel = "",
}: Props) => {
  let inputCss = "";
  if (disabled) inputCss = toggleConfig.disabled;
  else if (enabled) inputCss = toggleConfig.enabled;
  else inputCss = toggleConfig.default;

  return (
    <>
      {label && (
        <Label
          disabled={disabled}
          className={classNameLabel}
          data-testid="toggle-label"
        >
          {label}
        </Label>
      )}
      <Switch
        data-testid="toggle-input"
        checked={enabled}
        disabled={disabled}
        onChange={onChange}
        className={`relative inline-flex h-[28px] w-[44px] shrink-0 cursor-pointer rounded-full border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 ${inputCss} ${className}`}
      >
        <span
          className={`${
            enabled ? "translate-x-[16px]" : "translate-x-0"
          } mt-[2px] ml-[2px] pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
    </>
  );
};

export default Toggle;
