import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LANDING_PATH } from "configs/page";
import Button from "components/Button";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="h-full flex flex-col justify-center items-center">
      <div className="flex justify-center items-center py-4 gap-3 text-3xl">
        <span className="text-black">404</span>
        <span className="text-gray">|</span>
        <span className="text-black">{t("notfound")}</span>
      </div>
      <Link to={LANDING_PATH}>
        <Button variant="contained" color="primary" className="my-3">
          {t("backHomeBtn")}
        </Button>
      </Link>
    </div>
  );
};

export default NotFound;
