import React from "react";
import { CheckIcon, SolidCircleIcon } from "components/Icon/Common";
import Label from "components/Input/Label";

type Props = {
  children?: React.ReactNode;
  label?: string;
  name: string;
  className?: string;
  classNameLabel?: string;
  errorMsg?: string;
  value?: string | number | readonly string[];
  checked?: boolean;
  disabled?: boolean;
  variant?: "check" | "dot";
  onChange?: () => void;
};

const CheckBox = ({
  children,
  label,
  name,
  className = "",
  classNameLabel,
  checked,
  errorMsg,
  value,
  disabled,
  variant = "check",
  onChange,
}: Props) => {
  const isError = !!errorMsg;
  let checkBoxCss = "text-primary-input-accent";
  if (disabled) {
    checkBoxCss = "text-primary-input-disabled-text";
  }
  return (
    <div className={className}>
      {label && (
        <Label
          disabled={disabled}
          className={`my-2 ${classNameLabel}`}
          data-testid="checkbox-label"
        >
          {label}
        </Label>
      )}
      <label
        htmlFor={name ? `${name}-checkbox` : "checkbox"}
        className={`flex cursor-pointer select-none items-center ${checkBoxCss}`}
      >
        <div className="relative" data-testid="checkbox-input">
          <input
            id={name ? `${name}-checkbox` : "checkbox"}
            type="checkbox"
            checked={checked}
            value={value}
            name={name}
            className={`sr-only`}
            disabled={disabled}
            onChange={onChange}
          />
          <div
            className={`mr-2 flex h-7 w-7 items-center justify-center rounded-full border-2 ${checkBoxCss}`}
          >
            {checked && (
              <span className={`${disabled ? "opacity-60" : ""}`}>
                {variant === "check" ? <CheckIcon /> : <SolidCircleIcon />}
              </span>
            )}
          </div>
        </div>
        {children}
      </label>
      {isError && (
        <span className="text-error" data-testid="checkbox-error">
          {errorMsg}
        </span>
      )}
    </div>
  );
};
export default CheckBox;
