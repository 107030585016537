import React from "react";
import { useTranslation } from "react-i18next";
import Logo from "assets/images/logos/logo-with-text.png";
import Dialog from ".";
import Button from "components/Button";
import { IConfirmBack } from "types/common.d";

const DialogConfirmBack = ({
  open,
  onClose,
  onSubmit,
  onCancel,
}: IConfirmBack) => {
  const { t } = useTranslation();

  return (
    <Dialog
      isOpen={open}
      onCloseDialog={onClose}
      backgroundColor="gray"
      borderColor="none"
    >
      <div>
        <div className="flex items-center flex-col">
          <img src={Logo} alt="dialog warning logo" width="64" />
          <h2 className="text-primary text-center pt-3 pb-6">
            {t("order.back.warning")}
          </h2>
        </div>
        <div className="flex justify-center gap-2 my-3">
          <Button variant="gradient" color="secondary" onClick={onSubmit}>
            {t("confirmBtn")}
          </Button>
          <Button variant="gradient" color="primary" onClick={onCancel}>
            {t("cancelBtn")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DialogConfirmBack;
