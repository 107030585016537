import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { carInfoState } from "states/carInfoState";
import { carPackageState } from "states/carPackageState";
import { idCardState } from "states/idCardState";
import {
  LANDING_PATH,
  CAR_PACKAGE_PATH,
  CAR_PACKAGE_DETAIL_PATH,
  CAR_PACKAGE_CONFIRM_PATH,
  CAR_SUMMARY_PATH,
  ID_REGISTER_PATH,
  KYC_PATH,
  SUMMARY_PATH,
  SELECTED_INSURED_PATH,
  ID_HOME_PATH,
} from "configs/page";

export const useValidateRouter = () => {
  const location = useLocation();
  const path = location.pathname;

  const valueCarInfo = useRecoilValue(carInfoState);
  const valueCarPackage = useRecoilValue(carPackageState);
  const valueIdCard = useRecoilValue(idCardState);

  const gotoHomepage = () => {
    window.location.href = LANDING_PATH;
  };

  useEffect(() => {
    if (path === CAR_PACKAGE_PATH || path === CAR_PACKAGE_DETAIL_PATH) {
      if (!valueCarInfo.brand) return gotoHomepage();
    }
    if (path === CAR_PACKAGE_CONFIRM_PATH) {
      if (!valueCarPackage) return gotoHomepage();
    }
    if (
      path === CAR_SUMMARY_PATH ||
      path === ID_HOME_PATH ||
      path === SELECTED_INSURED_PATH ||
      path === ID_REGISTER_PATH
    ) {
      if (!valueCarInfo.brand || !valueCarPackage) return gotoHomepage();
    }
    if (path === KYC_PATH) {
      if (!valueIdCard.user?.id) return gotoHomepage();
    }
    if (path === SUMMARY_PATH) {
      if (!valueCarInfo.brand || !valueCarPackage || !valueIdCard.user?.id)
        return gotoHomepage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return null;
};
