/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { configs } from "configs/config";
import {
  cameraConfig,
  carPositionContour,
  CameraType,
  imageType,
} from "configs/camera";
import { checkIsIOS } from "utils/user-agent";
import { captureLogMessage } from "utils/logging";
import { logTags } from "configs/logging";
import { carConfig } from "configs/car";
interface Props {
  onChange: (image: string) => void;
  onClickBackBtn?: () => void;
  type?: string;
  contourType?: string;
}

const WebcamCapture = ({
  onClickBackBtn,
  onChange,
  type = "",
  contourType = "",
}: Props) => {
  const { t } = useTranslation();
  const webcamRef = useRef<HTMLVideoElement | null>(null);
  const panelRef = useRef(null);

  const isLandscape = () =>
    window.matchMedia("(orientation:landscape)").matches;
  const [orientation, setOrientation] = useState(
    isLandscape() ? "landscape" : "portrait"
  );
  const windowW = window.innerWidth;
  const windowH = window.innerHeight;
  const isPortrait = orientation === "portrait";

  const aspectRatio = cameraConfig[type]?.aspectRatio || 1.33333333;
  const ratioW = cameraConfig[type]?.ratioW || 4;
  const ratioH = cameraConfig[type]?.ratioH || 3;
  const camW = cameraConfig[type]?.width || 960;
  const camH = cameraConfig[type]?.height || 720;
  // car position front/back/dashboard for rotate contour by screen orientation
  // other position fix landscape contour
  const isRotatablePosition =
    contourType === carConfig.front.position ||
    contourType === carConfig.back.position ||
    contourType === carConfig.dashboard.position;

  const isCarContourRotatable = isPortrait && !isRotatablePosition;

  const capture = useCallback(() => {
    try {
      const webcamHeight = webcamRef?.current?.videoHeight ?? 0;
      const webcamWidth = webcamRef?.current?.videoWidth ?? 0;
      const isImagePortrait = webcamHeight > webcamWidth;
      const isRequiredRotate =
        type === CameraType.CarPosition &&
        isImagePortrait &&
        !isRotatablePosition;
      const canvas = document.createElement("canvas");
      if (isRequiredRotate) {
        canvas.width = webcamHeight;
        canvas.height = webcamWidth;
      } else {
        canvas.width = webcamWidth;
        canvas.height = webcamHeight;
      }
      const context = canvas.getContext("2d");
      if (context && webcamRef?.current) {
        if (isRequiredRotate) {
          context.translate(canvas.width / 2, canvas.height / 2);
          context.rotate(Math.PI / 2);
          context.drawImage(
            webcamRef.current,
            -canvas.height / 2,
            -canvas.width / 2
          );
        } else {
          context.drawImage(
            webcamRef.current,
            0,
            0,
            canvas.width,
            canvas.height
          );
        }
        const img = canvas.toDataURL(imageType.jpg);
        if (img) {
          stopCamera();
          onChange(img);
          context.clearRect(0, 0, canvas.width, canvas.height);
        } else {
          context.clearRect(0, 0, canvas.width, canvas.height);
          stopCamera();
          onClickBackBtn?.();
          throw new Error("capture image failed");
        }
      }
    } catch (error: any) {
      console.error("capture image failed");
      captureLogMessage("capture image failed", logTags.camera);
      alert("capture image failed");
      return;
    }
  }, [webcamRef, onChange]);

  const onWindowResize = () => {
    clearTimeout(window?.resizeLag);
    window.resizeLag = setTimeout(() => {
      delete window.resizeLag;
      setOrientation(isLandscape() ? "landscape" : "portrait");
    }, 500);
  };

  const getVideo = async () => {
    if (navigator.mediaDevices) {
      try {
        const isLocal = configs.env === "localhost";
        const devices = await navigator.mediaDevices.enumerateDevices();
        const vDevices = devices.filter(
          ({ label, kind }) =>
            kind === "videoinput" &&
            label.toLowerCase().includes("back") &&
            !label.toLowerCase().includes("wide")
        );
        let constraints = {};
        if (!checkIsIOS() && vDevices.length > 1) {
          constraints = {
            audio: false,
            video: {
              deviceId: vDevices[vDevices.length - 1]?.deviceId,
              width: { ideal: camW },
              height: { ideal: camH },
              aspectRatio,
              zoom: 1,
              facingMode: { exact: "environment" },
            },
          };
        } else if (isLocal) {
          constraints = {
            audio: false,
            video: {
              deviceId: vDevices[0]?.deviceId,
              width: { ideal: camW },
              height: { ideal: camH },
              aspectRatio,
              zoom: 1,
              facingMode: { ideal: "environment" },
            },
          };
        } else {
          constraints = {
            audio: false,
            video: {
              deviceId: vDevices[0]?.deviceId,
              width: { ideal: camW },
              height: { ideal: camH },
              aspectRatio,
              zoom: 1,
              facingMode: { exact: "environment" },
            },
          };
        }

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (webcamRef.current) {
          webcamRef.current.srcObject = stream;
        }
      } catch (error: any) {
        const errMsg = error?.message ? `\n${error.message}` : "";
        const errName = error?.name ? `\n${error.name}` : "";
        const errConstraint = error?.constraint
          ? ` - ${error?.constraint}`
          : "";

        captureLogMessage("camera notWorking", logTags.camera);
        alert(t("webcam.notWorking") + errName + errMsg + errConstraint);
      }
    }
  };

  useEffect(() => {
    getVideo();
  }, [webcamRef]);

  useEffect(() => {
    onWindowResize();
    window.addEventListener("resize", onWindowResize);
    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  const stopCamera = () => {
    const stream = webcamRef?.current?.srcObject as MediaStream | null;

    if (stream) {
      const tracks = stream?.getTracks();

      tracks.forEach((track) => track.stop());
      if (webcamRef?.current?.srcObject) {
        webcamRef.current.srcObject = null;
      }
    }
  };

  const navDevices = navigator.mediaDevices;
  const supportedConstraints = navDevices?.getSupportedConstraints();

  if (!supportedConstraints) {
    captureLogMessage("camera not support", logTags.camera);
    alert(`${t("webcam.notWorking")} \n(camera not support)`);
  } else {
    if (!Object.keys(supportedConstraints).includes("deviceId")) {
      captureLogMessage("camera deviceId not support", logTags.camera);
      alert(`${t("webcam.notWorking")} \n(deviceId not support)`);
    }
    if (!Object.keys(supportedConstraints).includes("aspectRatio")) {
      captureLogMessage("camera aspectRatio not support", logTags.camera);
      alert(`${t("webcam.notWorking")} \n(aspectRatio not support)`);
    }
    if (!Object.keys(supportedConstraints).includes("width")) {
      captureLogMessage("camera width not support", logTags.camera);
      alert(`${t("webcam.notWorking")} \n(width not support)`);
    }
    if (!Object.keys(supportedConstraints).includes("height")) {
      captureLogMessage("camera height not support", logTags.camera);
      alert(`${t("webcam.notWorking")} \n(height not support)`);
    }
    if (!Object.keys(supportedConstraints).includes("facingMode")) {
      captureLogMessage("camera facingMode not support", logTags.camera);
      alert(`${t("webcam.notWorking")} \n(facingMode not support)`);
    }
  }

  let camWidth = 0;
  let camHeight = 0;
  let contourCss = "";
  let captureCss = "";
  let captureTextCss = "";
  let backBtnCss = "";
  if (isPortrait) {
    camWidth = windowW;
    camHeight = (windowW / ratioH) * ratioW;
    contourCss = isRotatablePosition
      ? `inset-y-0`
      : `-rotate-90 origin-top-right top-0`;
    captureCss = "w-full my-2 mx-auto";
    captureTextCss = "m-1";
    backBtnCss = "px-4 py-2";
  } else {
    camWidth = (windowH / ratioH) * ratioW;
    camHeight = windowH;
    contourCss = "inset-y-0";
    captureCss = "h-full mx-0 -rotate-90 right-0";
    captureTextCss = "m-1 origin-center";
    backBtnCss = "px-4 py-2";
  }

  return (
    <div
      ref={panelRef}
      id="video-stream"
      className="bg-black h-full w-full relative"
    >
      <div
        className={`absolute flex flex-row w-full bg-transparent justify-between z-50 text-white ${backBtnCss}`}
      >
        {onClickBackBtn && (
          <ArrowLeftIcon
            className={`h-7 w-7 md:w-10 md:h-10 z-20`}
            onClick={() => {
              stopCamera();
              onClickBackBtn();
            }}
            data-testid="webcam-back-btn"
          />
        )}
      </div>
      <div
        className={`relative ${isPortrait ? "" : "my-0 mx-auto"}`}
        style={{
          width: `${camWidth}px`,
          height: `${camHeight}px`,
        }}
      >
        <video
          className={`${isPortrait ? "" : "my-0 mx-auto"}`}
          muted
          autoPlay
          playsInline
          ref={webcamRef}
          style={{
            width: `${camWidth}px`,
            height: `${camHeight}px`,
          }}
          data-testid="webcam-camera"
        />
        {type === CameraType.CarBook && (
          <div
            className={`absolute inset-0 m-auto p-10 ${
              isPortrait ? "h-fit" : "w-fit"
            }`}
          >
            <div
              className={`border-2 border-white aspect-[3/3.3] ${
                isPortrait ? "w-full h-auto" : "w-auto h-full"
              }`}
              data-testid="webcam-contour"
            />
          </div>
        )}
        {type === CameraType.IdCard && (
          <img
            src={cameraConfig[type]?.img}
            className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full scale-75 border-solid border-2 border-white object-contain`}
            alt="card-contour"
            data-testid="webcam-contour"
          />
        )}
        {type === CameraType.CarPosition && carPositionContour[contourType] && (
          <img
            src={carPositionContour[contourType]}
            className={`absolute p-10 ${contourCss}`}
            alt="car-contour"
            data-testid="webcam-contour"
            style={{
              width: `${isCarContourRotatable ? camHeight : camWidth}px`,
              height: `${isCarContourRotatable ? camWidth : camHeight}px`,
              right: `${isCarContourRotatable ? camWidth : 0}px`,
            }}
          />
        )}
      </div>
      <div
        className={`fixed flex flex-col justify-center bottom-0 items-center ${captureCss}`}
      >
        <p
          className={`text-white body-article ${captureTextCss} m-1`}
          data-testid="webcam-capture-text"
        >
          {t("photo.manual")}
        </p>
        <button
          className={`bg-white rounded-full w-14 h-14 z-20 origin-center my-2 mx-auto`}
          onClick={capture}
          data-testid="webcam-capture-btn"
        />
      </div>
    </div>
  );
};

export default WebcamCapture;
