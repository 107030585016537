import React from "react";
import { useTranslation } from "react-i18next";
import Logo from "assets/images/logos/logo-with-text.png";
import Button from "components/Button";
import Card from "components/Card";

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  disabledSubmitBtn?: boolean;
};

const KYCConfirmCard = ({
  onConfirm,
  onCancel,
  disabledSubmitBtn,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Card borderColor="none" shadow="top" backgroundColor="gray">
      <div className="flex items-center flex-col">
        <img src={Logo} alt="kyc condition" width="64" data-testid="kyc-logo" />
        <h1 className="py-4" data-testid="kyc-title">
          {t("kyc")}
        </h1>
        <h2
          className="text-primary-text-700 text-center"
          data-testid="kyc-condition"
        >
          {t("kyc.condition")}
        </h2>
      </div>
      <h3 className="text-[#E2231A] pt-4 pb-16" data-testid="kyc-desc">
        {t("kyc.warningText")}
      </h3>
      <div className="flex gap-3 justify-center py-2">
        <Button
          variant="gradient"
          color="secondary"
          onClick={onCancel}
          data-testid="kyc-cancel-btn"
        >
          {t("kyc.cancel")}
        </Button>
        <Button
          variant="gradient"
          color="primary"
          onClick={onConfirm}
          disabled={disabledSubmitBtn}
          data-testid="kyc-confirm-btn"
        >
          {t("kyc.confirm")}
        </Button>
      </div>
    </Card>
  );
};

export default KYCConfirmCard;
