export const sentAddressValue = {
  email: "1",
  mail: "2",
  both: "3",
};

export const sentAddressLabel: { [key: string]: string } = {
  [sentAddressValue.email]: "policy.sent.email",
  [sentAddressValue.mail]: "policy.sent.mail",
  [sentAddressValue.both]: "policy.sent.both",
};

export const sentAddressOptions = [
  {
    label: sentAddressLabel[sentAddressValue.email],
    value: sentAddressValue.email,
  },
  {
    label: sentAddressLabel[sentAddressValue.mail],
    value: sentAddressValue.mail,
  },
  {
    label: sentAddressLabel[sentAddressValue.both],
    value: sentAddressValue.both,
  },
];
