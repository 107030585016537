import { useQuery } from "@tanstack/react-query";
import {
  convertProvinceData,
  getProvince,
  getDistrict,
  convertDistrictData,
  convertSubdistrictData,
  getSubdistrict,
} from "services/mti/address";

import {
  IMtiDistrictListParams,
  IDistrictListQueryKey,
  IMtiSubdistrictListParams,
  ISubdistrictListQueryKey,
} from "types/address.d";

export const useProvince = (isAutoFetch = true) => {
  const queryKey = ["province"];
  const { data, isLoading, isError, error, refetch } = useQuery(
    queryKey,
    getProvince,
    { enabled: isAutoFetch }
  );

  const res = data?.data?.[0] ?? null;
  const province = res?.Province?.map(convertProvinceData);

  return { data: province, isLoading, isError, error, refetch };
};

export const useDistrict = (
  param: IMtiDistrictListParams,
  isAutoFetch = true
) => {
  const queryKey: IDistrictListQueryKey = ["district", param];
  const { data, isLoading, isError, error, refetch } = useQuery(
    queryKey,
    getDistrict,
    { enabled: isAutoFetch }
  );

  const res = data?.data?.[0] ?? null;
  const district = res?.Ampur?.map(convertDistrictData);

  return { data: district, isLoading, isError, error, refetch };
};

export const useSubdistrict = (
  param: IMtiSubdistrictListParams,
  isAutoFetch = true
) => {
  const queryKey: ISubdistrictListQueryKey = ["subdistrict", param];
  const { data, isLoading, isError, error, refetch } = useQuery(
    queryKey,
    getSubdistrict,
    { enabled: isAutoFetch }
  );

  const res = data?.data?.[0] ?? null;
  const subdistrict = res?.Tumbol?.map(convertSubdistrictData);

  return { data: subdistrict, isLoading, isError, error, refetch };
};
