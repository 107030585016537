import React from "react";
import { AdapterSystem, FlowType } from "configs/common";
import { AxiosError } from "axios";

/* eslint-disable @typescript-eslint/no-explicit-any */
export enum StepType {
  Camera = "camera",
  Typing = "typing",
}

export enum Status {
  Active = "ACTIVE",
  ModelActiveConfig = "ACTIVE-BODYCONFIG-CC-DOOR",
}

export enum Language {
  th = "TH",
  en = "EN",
}

declare global {
  interface Window {
    resizeLag: any;
  }
}

export interface IMtiResult {
  ResultCode: number;
  ResultDescription: string;
  Errors: unknown | null;
}

export interface IOption {
  name: string;
  value: string | number | boolean;
  [key: string]: string | number | boolean;
}

export interface IOptionPackageConfig {
  name: string;
  value: number;
}

export interface ISearchParams {
  agent?: string;
  debug?: boolean;
  flow?: FlowType;
  skipStatus?: boolean;
  [key: string]: any;
}

export interface IWarning {
  title?: string;
  subTitle?: string;
  message?: string;
  children?: React.ReactNode;
  position?: "top" | "center" | "bottom";
  onClose?: () => void;
  onSubmit?: () => void;
  textSubmit?: string;
  onCancel?: () => void;
  textCancel?: string;
  showClose?: boolean;
  enableCloseOnClickOutside?: boolean;
}

export interface IConfirm {
  title?: string;
  message?: string;
  children?: React.ReactNode;
  position?: "top" | "center" | "bottom";
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export interface IConfirmBack {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onCancel: () => void;
}

export interface IAdapterResponseError {
  code: string;
  message: string;
  system: AdapterSystem;
}

export type IAdapterError = AxiosError<IAdapterResponseError>;
