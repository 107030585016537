import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { getBrands, convertBrandData } from "services/mti/brand";
import { IMtiBrandListParams, IBrandListQueryKey } from "types/brand.d";
import { useCommonState } from "./useCommonState";

export const useBrands = (params: IMtiBrandListParams, isAutoFetch = true) => {
  const { setWarningDialog } = useCommonState();
  const { t } = useTranslation();
  const queryKey: IBrandListQueryKey = ["brands", params];
  const { data, isLoading, isError, error, refetch } = useQuery(
    queryKey,
    getBrands,
    {
      enabled: isAutoFetch,
      onError: () => {
        setWarningDialog({
          title: t("warning.common"),
          message: t("warning.retry"),
        });
      },
    }
  );

  const res = data?.data?.[0] ?? null;
  const brands = res?.CarBrands?.map(convertBrandData);

  return { data: brands, isLoading, isError, error, refetch };
};
