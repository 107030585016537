import React from "react";
import { useTranslation } from "react-i18next";
import { driverAgeValue } from "configs/package";
import { ANNO_DOMINI, BUDDHIST_CALENDAR } from "configs/date";
import Card from "components/Card";
import Divider from "components/Divider";
import { IPackageForm } from "types/package.d";
import { ICarInfo } from "types/car-info";
import { formatNumber } from "utils/number";
import { convertYearString, getCarTitle } from "utils/formatter";

type Props = {
  packageData: IPackageForm;
  carInfo: ICarInfo;
};

const PackageDetail = ({ packageData, carInfo }: Props) => {
  const { t } = useTranslation();
  const packageDetail = packageData.package;
  const { workshopTxt, deductAmountOD, sumInsure, totPremiumCTP } =
    packageDetail ?? {};
  const { coverType } = packageDetail?.packageSuggest ?? {};
  const carTitle = getCarTitle(carInfo);
  const { driverAge, hasCTP } = packageData?.customPackage ?? {};

  if (!packageDetail) {
    return null;
  }

  return (
    <Card borderColor="none" shadow="top" data-testid="summary-package-detail">
      <div className="text-center">
        <h1>
          {t("package.insuranceClass")} {coverType}
        </h1>
        <h2 className="text-primary-main-800">{carTitle}</h2>
      </div>
      <Divider className="border-primary-main-300 my-2" />
      <div className="flex justify-between gap-1">
        <p>{t("package.insuranceFixType")}</p>
        <p className="text-primary">{workshopTxt}</p>
      </div>
      <div className="flex justify-between gap-1">
        <p className="flex-1">{t("package.insured")}</p>
        <p className="text-primary">
          {formatNumber(sumInsure)}{" "}
          <span className="text-gray-500">{t("thb")}</span>
        </p>
      </div>
      {/* <div className="flex justify-between gap-1">
        <p className="flex-1">{t("package.deductAmountTPTxt")}</p>
        <p className=" text-primary">
          {formatNumber(deductAmountTP)}{" "}
          <span className="text-gray-500">{t("thb")}</span>
        </p>
      </div> */}
      <div className="flex justify-between gap-1">
        <p className="flex-1">{t("package.deductAmountODTxt")}</p>
        <p className=" text-primary">
          {formatNumber(deductAmountOD)}{" "}
          <span className="text-gray-500">{t("thb")}</span>
        </p>
      </div>
      {hasCTP && (
        <div className="flex justify-between gap-1">
          <p className="flex-1">{t("package.act")}</p>
          <p className=" text-primary">
            {formatNumber(totPremiumCTP)}{" "}
            <span className="text-gray-500">{t("thb")}</span>
          </p>
        </div>
      )}
      <div className="flex justify-between gap-1">
        <p className="flex-1">{t("package.insurancePeriod")}</p>
        <p className=" text-primary">
          {convertYearString(
            ANNO_DOMINI,
            BUDDHIST_CALENDAR,
            carInfo?.insuranceCoverageStartDate
          )}{" "}
          -{" "}
          {convertYearString(
            ANNO_DOMINI,
            BUDDHIST_CALENDAR,
            carInfo?.insuranceCoverageEndDate
          )}
        </p>
      </div>
      <div className="flex justify-between gap-1">
        <p>{t("package.driverAge")}</p>
        <p className=" text-primary">
          {driverAge?.value
            ? t(driverAgeValue[driverAge?.value])
            : t("undefined")}
          <span className="text-gray-500"></span>
        </p>
      </div>
      <div className="flex justify-between gap-1">
        <p>{t("carInfo.licensePlate")}</p>
        <p className=" text-primary">
          {carInfo?.carUniqueInfo?.isNew
            ? t("carInfo.redPlateShort")
            : carInfo.carUniqueInfo?.plateNumber}
        </p>
      </div>
    </Card>
  );
};

export default PackageDetail;
