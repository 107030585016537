import { carPolicyStatus } from "configs/car";
import { ICarDetectionImagePosition } from "types/car-detection.d";

export const isPolicyConfirmed = (status: string): boolean => {
  return status === carPolicyStatus.confirm;
};

export const getAllImage = (carImage: ICarDetectionImagePosition) => {
  return carImage
    ? {
        ...carImage.default,
        ...carImage.additional,
      }
    : null;
};
