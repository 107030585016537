import IMask from "imask";
import { CarInsuranceType } from "configs/car";
import { ICarInfo } from "types/car-info.d";
import {
  ANNO_DOMINI,
  BUDDHIST_CALENDAR,
  BUDDHIST_FROM_ANNODIMINI,
} from "configs/date";

export const formatTHPhone = (val: string) => {
  if (!val) return "";
  const masked = new IMask.MaskedPattern({ mask: "000-000-0000" });
  return masked.resolve(val);
};

export const formatTHIDCard = (val: string) => {
  if (!val) return "";
  const masked = new IMask.MaskedPattern({ mask: "0-0000-00000-00-0" });

  return masked.resolve(val);
};

export const formatOCRResponseDateToString = (val: string) => {
  if (!val) return "";
  const masked = new IMask.MaskedPattern({ mask: "0000-00-00" });

  return masked.resolve(val);
};

export const roundUpNearest100 = (val: number) => {
  if (!val) return 0;
  return Math.ceil(val / 100) * 100;
};

export const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, "0");
};

export const formatDate = (
  date?: Date | null,
  format = "dd/mm/yyyy",
  type = ""
) => {
  if (!date) return "";

  const day = padTo2Digits(date.getDate());
  const month = padTo2Digits(date.getMonth() + 1);
  let year = date.getFullYear();

  if (format === "yyyymmdd") {
    return [year, month, day].join("");
  }

  if (type === BUDDHIST_CALENDAR) {
    year = year + BUDDHIST_FROM_ANNODIMINI;
  }

  return [day, month, year].join("/");
};

export const formatTime = (date?: Date | null) => {
  if (!date) return "";

  const hour = padTo2Digits(date.getHours());
  const minute = padTo2Digits(date.getMinutes());
  const second = padTo2Digits(date.getSeconds());

  return [hour, minute, second].join(":");
};

export const convertYearString = (
  fromFormat: string,
  toFormat: string,
  date?: Date | null
) => {
  if (!date) return "";
  const day = date.getDate();
  const month = date.getMonth() + 1;
  let year = date.getFullYear();
  if (fromFormat === ANNO_DOMINI && toFormat === BUDDHIST_CALENDAR) {
    year = year + BUDDHIST_FROM_ANNODIMINI;
  } else if (fromFormat === BUDDHIST_CALENDAR && toFormat === ANNO_DOMINI) {
    year = year - BUDDHIST_FROM_ANNODIMINI;
  }
  return [padTo2Digits(day), padTo2Digits(month), year].join("/");
};

export const removeObjEmpty = (obj: object) => {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => v));
};

export const getCarModelFullName = (modelName: string): string => {
  if (!modelName) return "";

  return modelName.split(":")?.shift()?.trim() ?? "";
};

export const getCarTitle = (carInfo: ICarInfo): string => {
  if (!carInfo) return "";

  return `${carInfo.brand?.brand ?? ""} ${getCarModelFullName(
    carInfo.model?.modelName ?? ""
  )} ${carInfo.year?.yearGroup ?? ""}`;
};

export const formatFormDataToObject = (data: FormData) => {
  const object: { [key: string]: unknown } = {};
  data.forEach((value, key) => (object[key] = value));
  return object;
};

export const isFormatCMI = (proposalId: string) => {
  return proposalId.includes(CarInsuranceType.CMI);
};

export const isFormatVMI = (proposalId: string) => {
  return proposalId.includes(CarInsuranceType.VMI);
};

export const isIdNumber = (id: string) => {
  if (id.length !== 13) return false;
  // STEP 1 - get only first 12 digits
  let sum = 0;
  for (let i = 0; i < 12; i++) {
    // STEP 2 - multiply each digit with each index (reverse)
    // STEP 3 - sum multiply value together
    sum += parseInt(id.charAt(i)) * (13 - i);
  }
  // STEP 4 - mod sum with 11
  const mod = sum % 11;
  // STEP 5 - subtract 11 with mod, then mod 10 to get unit
  const check = (11 - mod) % 10;
  // STEP 6 - if check is match the digit 13th is correct
  if (check === parseInt(id.charAt(12))) {
    return true;
  }
  return false;
};

export const checkBuyableAge = (age: Date) => {
  return new Date().getFullYear() - age.getFullYear() + 1 >= 18;
};

export const isStartWithZero = (text: string) => {
  return text?.startsWith("0");
};

export const formatToNumberOnly = (val: string) => {
  return val.replace(/\D/g, "");
};

export const formatToLicensePlate = (val: string) => {
  return val.replace(/[^ก-ฮ0-9 -]/g, "");
};
