import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "components/Card";
import DetailContact from "components/Contact";
import Logo from "assets/images/logos/logo-with-text.png";
import { useResetState } from "hooks/useResetState";
import { LANDING_PATH } from "configs/page";

interface CarProps {
  isException: boolean;
}

const CarSuccessPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { logoutReset } = useResetState();
  const { state } = useLocation();
  const carState = state as CarProps;
  const isException = carState?.isException;

  useEffect(() => {
    return () => {
      logoutReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    window.location.href = LANDING_PATH;
  };

  return (
    <>
      {!isException && (
        <Card
          shadow="top"
          backgroundColor="gray"
          borderColor="gray"
          onClose={onClose}
        >
          <div className="flex items-center flex-col">
            <img
              src={Logo}
              alt="car submit success"
              width="64"
              data-testid="cardetection-success-logo"
            />
            <h1
              className="text-center py-3"
              data-testid="cardetection-success-title"
            >
              {t("car.success.title")}
            </h1>
            <h2
              className="text-primary text-center pb-2"
              data-testid="cardetection-success-desc"
            >
              {t("car.success.subtitle")}
            </h2>
            <DetailContact />
          </div>
        </Card>
      )}
      {isException && (
        <Card
          shadow="top"
          backgroundColor="gray"
          borderColor="none"
          onClose={onClose}
        >
          <div className="flex items-center flex-col">
            <img
              src={Logo}
              alt="car submit success with exception"
              width="64"
              data-testid="cardetection-success-logo"
            />
            <h1
              className="text-center py-3"
              data-testid="cardetection-success-title"
            >
              {t("car.successWithException.title")}
            </h1>
            <h2
              className="text-primary text-center pt-3 pb-6"
              data-testid="cardetection-success-desc"
            >
              {t("car.successWithException.message")}
            </h2>
          </div>
        </Card>
      )}
    </>
  );
};

export default CarSuccessPage;
