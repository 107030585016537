import { useQuery, useQueries } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
  getPackages,
  getPackageSuggests,
  getPackageDetail,
  convertPackageData,
  convertPackageSuggestData,
  convertPackageDetailData,
} from "services/mti/car-package";
import {
  IMtiPackageListParams,
  IMtiPackageSuggestListParams,
  IMtiPackageDetailParams,
} from "types/package.d";
import { useCommonState } from "./useCommonState";

export const usePackageSuggest = (
  params: IMtiPackageSuggestListParams,
  isAutoFetch = true
) => {
  const { setWarningDialog } = useCommonState();
  const { t } = useTranslation();
  const { data, isLoading, isError, error, refetch } = useQuery(
    ["packageSuggest", params],
    () => getPackageSuggests(params),
    {
      enabled: isAutoFetch,
      onError: () => {
        setWarningDialog({
          title: t("package.suggest.notFound"),
          message: t("package.suggest.notFoundDesc"),
        });
      },
    }
  );

  const res = data?.data?.[0] ?? null;
  const packageSuggestList = res?.CarPackagesSuggest?.map(
    convertPackageSuggestData
  );

  return { packageSuggestList, isLoading, isError, error, refetch };
};

export const usePackages = (
  paramsList: IMtiPackageListParams[],
  isAutoFetch = true
) => {
  const results = useQueries({
    queries: paramsList.map((params) => {
      return {
        queryKey: ["packages", params],
        queryFn: () => getPackages(params),
        enabled: isAutoFetch,
      };
    }),
  });

  const resultsMapped = results.map((result) => {
    const res = result?.data?.data?.[0] ?? null;
    const packageList = res?.CarPackages?.map(convertPackageData);

    return {
      packageList,
      isLoading: result.isLoading,
      isError: result.isError,
      error: result.error,
      refetch: result.refetch,
    };
  });

  return resultsMapped;
};

export const usePackageDetail = (
  params: IMtiPackageDetailParams,
  isAutoFetch = true
) => {
  const { data, isLoading, isError, error, refetch } = useQuery(
    ["packageDetail", params],
    () => getPackageDetail(params),
    { enabled: isAutoFetch }
  );

  const res = data?.data?.[0] ?? null;
  const packageDetailList = res?.PackageCoverDetails?.map(
    convertPackageDetailData
  );

  const packageDetail = res
    ? {
        coverageUrl: res.CoverageUrl || "",
        packageDetailList,
      }
    : null;

  return { packageDetail, isLoading, isError, error, refetch };
};
