import React, { Fragment, useRef } from "react";
import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import Card from "components/Card";

interface Props {
  title?: string;
  isOpen: boolean;
  onCloseDialog: () => void;
  children: React.ReactNode;
  className?: string;
  position?: "top" | "bottom" | "center";
  borderColor?: "primary" | "gray" | "darkPrimary" | "none";
  backgroundColor?: "white" | "gray";
  showClose?: boolean;
  enableCloseOnClickOutside?: boolean;
}

const Dialog = ({
  title,
  isOpen,
  className,
  children,
  onCloseDialog,
  position = "center",
  borderColor = "primary",
  backgroundColor = "white",
  showClose = true,
  enableCloseOnClickOutside = true,
}: Props) => {
  const childrenRef = useRef(null);
  let positionCss = "items-center";
  if (position === "top") positionCss = "items-start";
  if (position === "bottom") positionCss = "items-end";

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <HeadlessDialog
        as="div"
        className={`relative z-10`}
        onClose={enableCloseOnClickOutside ? onCloseDialog : () => null}
        initialFocus={childrenRef}
      >
        <div ref={childrenRef} className="fixed inset-0 backdrop-blur">
          <div
            className={`flex min-h-full justify-center p-4 text-center ${positionCss}`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <HeadlessDialog.Panel className="relative w-full max-w-md transform overflow-hidden text-left align-middle transition-all p-2">
                <Card
                  heightFullMax
                  title={title}
                  shadow="top"
                  borderColor={borderColor}
                  backgroundColor={backgroundColor}
                  onClose={showClose ? onCloseDialog : undefined}
                  className={className ?? ``}
                >
                  {children}
                </Card>
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition>
  );
};

export default Dialog;
