import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Card from "components/Card";
import Logo from "assets/images/logos/logo-with-text.png";
import { useResetState } from "hooks/useResetState";
import { useCommonState } from "hooks/useCommonState";
import DetailContact from "components/Contact";

const SessionExpiredPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { logoutReset, clearBackState } = useResetState();
  const { setWarningDialog } = useCommonState();
  const onClose = useRef(false);

  const clearData = () => {
    onClose.current = true;
    logoutReset();
  };

  useEffect(() => {
    setWarningDialog(null);
    window.onpopstate = () => {
      if (!onClose.current) {
        clearBackState(false, true);
      } else {
        onClose.current = false;
      }
    };

    return () => {
      clearData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card shadow="top" borderColor="gray" onClose={clearData}>
      <div className="flex items-center flex-col">
        <img src={Logo} alt="session expired icon" width="64" />
        <h1 className="text-center py-3">{t("sessionExpired.title")}</h1>
        <DetailContact />
      </div>
    </Card>
  );
};

export default SessionExpiredPage;
