import { useQuery } from "@tanstack/react-query";
import { getModels, convertModelData } from "services/mti/model";
import { IMtiModelListParams, IModelListQueryKey } from "types/model.d";

export const useModels = (params: IMtiModelListParams, isAutoFetch = true) => {
  const queryKey: IModelListQueryKey = ["models", params];
  const { data, isLoading, isError, error, refetch } = useQuery(
    queryKey,
    getModels,
    { enabled: isAutoFetch }
  );

  const res = data?.data?.[0] ?? null;
  const models = res?.CarModels?.map(convertModelData);

  return { data: models, isLoading, isError, error, refetch };
};
