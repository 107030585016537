import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { AxiosError } from "axios";
import { transactionState } from "states/transactionState";
import { carPackageState } from "states/carPackageState";
import { carInfoState } from "states/carInfoState";
import { idCardState } from "states/idCardState";
import { searchParamsState } from "states/searchParamsState";
import {
  HASH_NO_BACK,
  HASH_NO_BACK_AGAIN,
  SUMMARY_PATH,
  PAYMENT_RESULT_PATH,
} from "configs/page";
import { configs } from "configs/config";
import { ConsentCode } from "configs/common";
import { paymentMethod } from "configs/payment";
import { LicensePlateType, PolicyTypeCd, CarInsuranceType } from "configs/car";
import { logTags } from "configs/logging";
import {
  sentAddressLabel,
  sentAddressValue,
} from "configs/sent-insurance-option";
import { useGenerateProposalId, useGeneratePolicy } from "hooks/useTransaction";
import { useGeneratePaymentUrl } from "hooks/usePayment";
import { useCommonState } from "hooks/useCommonState";
import { useResetState } from "hooks/useResetState";
import Button from "components/Button";
import DialogConfirmBack from "components/Dialog/DialogConfirmBack";
import {
  formatDate,
  formatTime,
  getCarModelFullName,
  isFormatCMI,
} from "utils/formatter";
import { formatNumber, randomNumber } from "utils/number";
import { captureLogMessage } from "utils/logging";
import { IOption } from "types/common.d";
import {
  IMtiGeneratePolicyParams,
  IMtiGenerateProposalIdRefNo,
  IProposalIdData,
} from "types/transaction.d";
import { IOrderForm } from "types/order.d";
import { IPaymentParams } from "types/payment.d";
import { IAddress } from "types/address.d";
import PackageDetail from "./components/PackageDetail";
import PackageAmountDetail from "./components/PackageAmountDetail";
import UserDetail from "./components/UserDetail";
import DetailCard from "./components/DetailCard";
import OrderForm from "./components/OrderForm";

const FORMAT_DATE = "yyyymmdd";

enum ModalErrorType {
  payment = "payment",
  genPolicy = "genPolicy",
}

interface IPaymentData {
  paymentUrl: string;
  paymentKey: string;
}

/*
  NOTE: logic
  STEP 1. generate refNo and proposalId - call api /GeneratePaymentRefNoAndProposalId (if buy insurance and ctp create 2 proposal)
  STEP 2. check policy and check blacklist - call api /CheckAndRequestGeneratePolicy send body PolicyRequest = false (if buy insurance and ctp call 2 proposal)
  STEP 3. generate payment url - call api /GeneratePaymentUrl
  STEP 4. redirect to payment url
*/

const OrderPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const timestampRef = useRef(Date.now());
  const refNoRef = useRef<string>("");
  const proposalIdsDataRef = useRef<IProposalIdData[]>([]);
  const [paymentSelected, setPaymentSelected] = useState<IPaymentData | null>(
    null
  );
  const { setLoading, setWarningDialog } = useCommonState();
  const [transactionInfo, setTransactionState] =
    useRecoilState(transactionState);
  const searchParam = useRecoilValue(searchParamsState);
  const carPackageSelected = useRecoilValue(carPackageState);
  const carInfo = useRecoilValue(carInfoState);
  const userInfo = useRecoilValue(idCardState);
  const {
    proposalData,
    generateProposalId,
    isLoading: isLoadingProposal,
    error: errProposal,
  } = useGenerateProposalId();
  const {
    checkAndGeneratePolicyAsync,
    isLoading: isLoadingPolicy,
    error: errPolicy,
  } = useGeneratePolicy();
  const {
    paymentData,
    generatePayment,
    isLoading: isLoadingPayment,
    error: errPayment,
  } = useGeneratePaymentUrl();
  const { clearBackState } = useResetState();

  const packageDetail = carPackageSelected?.package;
  const { totPremiumVol, totPremiumCTP } = packageDetail ?? {};
  const totalPrice =
    (totPremiumVol ?? 0) +
    (carPackageSelected?.hasCTP ? totPremiumCTP ?? 0 : 0);
  const paymentRefNo = proposalData?.paymentRefno ?? "";
  const [openConfirmBackDialog, setOpenConfirmBackDialog] =
    useState<boolean>(false);

  // BACK Logic
  const isSubmitConfirmBackDialog = useRef(false);
  useEffect(() => {
    window.onpopstate = () => {
      if (
        window.location.hash !== HASH_NO_BACK &&
        window.location.hash !== HASH_NO_BACK_AGAIN
      ) {
        if (isSubmitConfirmBackDialog.current === true) {
          setLoading(true);
        } else if (paymentSelected) {
          setPaymentSelected(null);
          navigate(SUMMARY_PATH);
        } else {
          setOpenConfirmBackDialog(true);
          navigate(SUMMARY_PATH);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSelected]);

  const showModalError = (type?: string, onRetry?: () => void) => {
    if (type === ModalErrorType.payment) {
      setWarningDialog({
        title: t("payment.fail.title"),
        subTitle: t("payment.fail.subTitle"),
        message: t("payment.fail.message", {
          contactNumber: configs.contactNumber,
          officeHours: configs.officeHours,
        }),
        showClose: false,
        onSubmit: typeof onRetry === "function" ? () => onRetry() : undefined,
        textSubmit: t("retryPaymentBtn"),
      });
    } else {
      setWarningDialog({
        title: t("order.warning.title"),
        subTitle: t("order.warning.detail", {
          contactNumber: configs.contactNumber,
          officeHours: configs.officeHours,
        }),
        onSubmit: () => {
          window.location.href = "/";
        },
        onCancel: () => {
          window.location.href = configs.telLink;
          setWarningDialog(null);
        },
        showClose: false,
        textSubmit: t("confirmBtn"),
        textCancel: t("order.warning.contact", {
          contactNumber: configs.contactNumber,
        }),
      });
    }
  };

  const fetchCheckAndGeneratePolicy = async (
    paymentRefNo: string,
    proposalList: IProposalIdData[],
    isRequestPolicy: boolean,
    callback: () => void
  ) => {
    const policyWithMutation = proposalList.map((prop) => {
      const bodyPolicy = convertOrderParams(
        paymentRefNo,
        prop.proposalId,
        prop.refNo,
        isRequestPolicy,
        prop.isCMI
      );

      return checkAndGeneratePolicyAsync(bodyPolicy);
    });

    try {
      const respList = await Promise.all(policyWithMutation);
      const errorList = (respList || []).filter((resp) => !resp?.data?.Success);

      if (errorList.length === 0) {
        if (!isRequestPolicy) {
          setTransactionState({
            refNo: refNoRef.current,
            paymentRefNo,
            proposals: proposalIdsDataRef.current || [],
          });
        }

        callback();
      } else {
        const errorDescList = (respList || []).map(
          (resp) => resp?.data?.PropId + " " + resp?.data?.Description || ""
        );
        if (configs.env !== "production") {
          alert("error: " + errorDescList.toString());
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const logData: object = respList.reduce((acc: any, cur, i) => {
          acc[i] = {
            bodyParam: JSON.parse(`${cur?.config?.data}` || "{}"),
            respData: cur?.data,
            status: cur?.status,
            header: cur?.headers,
          };

          return acc;
        }, {});

        const errMsg = isRequestPolicy
          ? "cannot generate policy"
          : "cannot check policy";

        captureLogMessage(
          errMsg,
          {
            ...logTags.request,
            requestURL: respList?.[0]?.config?.url ?? "",
          },
          logData
        );

        throw new Error(errMsg);
      }
    } catch (err) {
      const error = err as AxiosError;
      if (configs.env !== "production") {
        if (error?.message || error?.code) {
          alert("error: " + error?.message || error?.code || "");
        }
      }

      showModalError();
    }
  };

  const fetchPayment = (paymentRefNo: string) => {
    // STEP 3
    const bodyPayment = convertPaymentParams(paymentRefNo, totalPrice);

    generatePayment(bodyPayment, {
      onSuccess: (resp) => {
        const result = resp?.data?.result;

        if (!resp?.data.success || !result?.length) {
          throw new Error("cannot generate payment url");
        }
      },
      onError: () => {
        showModalError(ModalErrorType.payment, () =>
          fetchPayment(paymentRefNo)
        );
      },
    });
  };

  const genProposalID = (refNoList: IMtiGenerateProposalIdRefNo[]) => {
    generateProposalId(
      { Input: refNoList },
      {
        onSuccess: (resp) => {
          const data = resp?.data;
          const output = data?.Output;

          if (output?.length) {
            const proposalIdsData = output.map((order) => ({
              refNo: order.refNo,
              proposalId: order.proposalId,
              isCMI: isFormatCMI(order.refNo),
            }));

            proposalIdsDataRef.current = proposalIdsData;

            // ref no use proposalId of vmi
            const vmiData = proposalIdsData.find((prop) => !prop.isCMI);
            const vmiProposalId = vmiData?.proposalId ?? "";
            if (!vmiProposalId) {
              throw new Error("cannot generate proposalId");
            }

            refNoRef.current = vmiProposalId;

            // STEP 2
            fetchCheckAndGeneratePolicy(
              data.paymentRefno,
              proposalIdsData,
              false,
              () => fetchPayment(data.paymentRefno)
            );
          } else {
            throw new Error("cannot generate proposalId");
          }
        },
        onError: () => {
          showModalError();
        },
      }
    );
  };

  useEffect(() => {
    if (!carPackageSelected) return;

    const timestamp = timestampRef.current;
    const uniqRef = `${timestamp}_${randomNumber(11)}`;
    const refNoList = [{ refNo: `${CarInsuranceType.VMI}_${uniqRef}` }];
    if (carPackageSelected?.hasCTP) {
      refNoList.push({ refNo: `${CarInsuranceType.CMI}_${uniqRef}` });
    }

    // if already gen proposal or gen payment will skip STEP 1 2 3
    if (transactionInfo?.proposals?.length && transactionInfo?.refNo) {
      refNoRef.current = transactionInfo.refNo;

      if (transactionInfo?.payments?.length) {
        return;
      } else if (transactionInfo.paymentRefNo) {
        fetchPayment(transactionInfo.paymentRefNo);
        return;
      }
    }

    // STEP 1
    genProposalID(refNoList);

    return () => {
      window.onpopstate = () => {
        return null;
      };
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(isLoadingProposal || isLoadingPolicy || isLoadingPayment);
  }, [isLoadingProposal, isLoadingPolicy, isLoadingPayment, setLoading]);

  useEffect(() => {
    if (paymentData) {
      setTransactionState({
        ...transactionInfo,
        payments: paymentData.result,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentData]);

  const getAddressText = (address: IAddress, isFullAddress = true) => {
    let text = "";
    if (address.buildNo) {
      text += `${address.buildNo}`;
    }
    if (address.buildName) {
      text += ` ${t("address.buildName")} ${address.buildName}`;
    }
    if (address.floor) {
      text += ` ${t("address.floor")} ${address.floor}`;
    }
    if (address.room) {
      text += ` ${t("address.room")} ${address.room}`;
    }
    if (address.moo) {
      text += ` ${t("address.moo")} ${address.moo}`;
    }
    if (address.soi) {
      text += ` ${t("address.soi")} ${address.soi}`;
    }
    if (address.road) {
      text += ` ${t("address.road")} ${address.road}`;
    }

    if (!isFullAddress) {
      return text;
    }

    if (address.subdistrict) {
      text += ` ${t("address.subdistrict")} ${
        address.subdistrict.subdistrictName
      }`;
    }
    if (address.district) {
      text += ` ${t("address.district")} ${address.district.districtName}`;
    }
    if (address.province) {
      text += ` ${t("address.province")} ${address.province.provinceName}`;
    }
    if (address.zipcode) {
      text += ` ${address.zipcode}`;
    }

    return text;
  };

  const convertOrderParams = (
    paymentRef: string,
    proposalId: string,
    quoteNumber: string,
    isPolicyRequest: boolean,
    isCMI = false
  ): IMtiGeneratePolicyParams => {
    const isDelivery =
      userInfo.sentOption === sentAddressValue.mail ||
      userInfo.sentOption === sentAddressValue.both;
    let deliveryData = {};

    if (isDelivery) {
      const deliveryAddress = userInfo.isSentAddressAsIDCard
        ? userInfo.address
        : userInfo.sentAddress;

      deliveryData = {
        Delivery_Title: userInfo.user.prefix?.prefixDescription,
        Delivery_Name: userInfo.user.firstName,
        Delivery_Surname: userInfo.user.lastName,
        Delivery_AddrNo: deliveryAddress?.buildNo ?? "",
        Delivery_AddrMoo: deliveryAddress?.moo ?? "",
        Delivery_AddrBuilding: deliveryAddress?.buildName ?? "",
        Delivery_AddrFloor: deliveryAddress?.floor ?? "",
        Delivery_AddrRoom: deliveryAddress?.room ?? "",
        Delivery_AddrSoi: deliveryAddress?.soi ?? "",
        Delivery_AddrRoad: deliveryAddress?.road ?? "",
        Delivery_SubDistrict: deliveryAddress?.subdistrict?.subdistrictName,
        Delivery_District: deliveryAddress?.district?.districtName,
        Delivery_Province: deliveryAddress?.province?.provinceName,
        Delivery_PostalCode: deliveryAddress?.zipcode,
        Delivery_EmailAddr: userInfo.user.email,
      };
    }

    return {
      AgentNo: searchParam?.agent ?? "",
      PolicyStatus: "N",
      PropId: proposalId,
      PreviousPolicyNumber: "",
      QuoteNumber: quoteNumber,
      ContractNumber: paymentRef,
      ContractDt: formatDate(new Date(timestampRef.current), FORMAT_DATE),
      ContractTime: formatTime(new Date(timestampRef.current)),
      SaleCode: "",
      AgencyEmployee: "",
      RemarkText: "",
      InsuredType: 1,
      InsuredBranchCd: "",
      InsuredBranchName: "",
      InsuredUniqueID: userInfo.user.id,
      BirthDt: userInfo.user.birth
        ? formatDate(userInfo.user.birth, FORMAT_DATE)
        : "",
      Age: 0,
      InsuredTitle: userInfo.user.prefix?.prefixDescription ?? "",
      InsuredName: userInfo.user.firstName,
      InsuredSurname: userInfo.user.lastName,
      Gender: userInfo.user.prefix?.gender ?? "",
      Addr: getAddressText(userInfo.address, false),
      SubDistrict: userInfo.address.subdistrict?.subdistrictName ?? "",
      District: userInfo.address.district?.districtName ?? "",
      Province: userInfo.address.province?.provinceName ?? "",
      PostalCode: `${userInfo.address.zipcode}`,
      OccupationDesc: "",
      MobilePhoneNumber: userInfo.user.tel,
      PhoneNumber: "",
      OfficePhoneNumber: "",
      EmailAddr: userInfo.user.email,
      Beneficiaries: "",
      RateGroup:
        (isCMI
          ? carPackageSelected?.package?.cmiCode
          : carPackageSelected?.package?.vehCode) ?? "",
      PolicyTypeCd: isCMI
        ? PolicyTypeCd.CMI
        : carPackageSelected?.package?.coverType ?? "",
      WorkShop: isCMI ? "" : carPackageSelected?.package?.workshopTxt ?? "",
      CampaignCode: isCMI
        ? ""
        : carPackageSelected?.package?.campaignCode ?? "",
      PackageCode: isCMI ? "" : carPackageSelected?.package?.packageCode ?? "",
      SumInsured: isCMI ? 0 : carPackageSelected?.package?.sumInsure ?? 0,
      EffectiveDt: formatDate(carInfo?.insuranceCoverageStartDate, FORMAT_DATE),
      ExpirationDt: formatDate(carInfo?.insuranceCoverageEndDate, FORMAT_DATE),
      VehicleKey: "",
      Manufacturer: carInfo.brand?.brand ?? "",
      Model: getCarModelFullName(carInfo.model?.modelName ?? ""),
      GearSystem: "",
      FuelType: carInfo.model?.fuelType ?? "",
      SeatingCapacity: 0,
      Displacement: +(carInfo.model?.engineSize ?? 0),
      DoorCount: +(carInfo.model?.doorNum ?? 0),
      GrossVehOrCombinedWeight: 0,
      ChassisSerialNumber: carInfo?.carUniqueInfo?.chassisSerialNumber ?? "",
      EngineSerialNumber: carInfo?.carUniqueInfo?.engineSerialNumber ?? "",
      ColorCode: carInfo?.carUniqueInfo?.color?.ColorCode ?? "",
      LicensePlateType: carInfo?.carUniqueInfo?.isNew
        ? LicensePlateType.red
        : LicensePlateType.general,
      Registration: carInfo?.carUniqueInfo?.plateNumber ?? "",
      RegisteredProvCd: carInfo.regProvince?.short ?? "",
      RegisteredYear: +(carInfo.year?.yearGroup ?? 0),
      PromotionCode: carPackageSelected?.promotion ?? "",
      // QNumPremium: "",
      // Deduct: null;
      // NetCom: null;
      Consents: [{ Code: ConsentCode.MTI003, isAccept: true }],
      CarInspectionType: "",
      CarInspectionContract: "",
      CarInspectionName: "",
      PaymentType: "",
      PolicyRequest: isPolicyRequest,
      EPolicyIsCopy: false,
      EPolicyTo: userInfo.user.email,
      EPolicyCc: "",
      EPolicyBcc: "",
      Delivery: userInfo.sentOption,
      ...deliveryData,
    };
  };

  const convertPaymentParams = (
    paymentRefNumber: string,
    amount: number
  ): IPaymentParams => {
    const propId = proposalIdsDataRef.current
      .map((proposal) => proposal.proposalId)
      .join(",");

    const callback = encodeURIComponent(
      `${configs.appUrl}${PAYMENT_RESULT_PATH}?refNo=${refNoRef.current}&propId=${propId}`
    );
    // for localhost, cannot send localhost text to cloudfront
    // const callback = encodeURIComponent(
    //   `https://carinspect-uat.muangthaiinsurance.com${PAYMENT_RESULT_PATH}?refNo=${refNoRef.current}&propId=${propId}`
    // );
    const redirectUrl = `${configs.caraiApiUrl}/car/inspection/payment/2c2p?callback=${callback}`;

    return {
      orderId: paymentRefNumber,
      invoiceNo: paymentRefNumber,
      amount: searchParam?.debug ? 1 : amount,
      description: "MOTORE2E-ประกันรถยนต์",
      email: userInfo.user.email,
      redirectUrl,
      backendUrl: "",
      userDefined1: "",
      userDefined2: "",
      userDefined3: "",
      userDefined4: "",
      userDefined5: "",
      language: "th",
      owner: configs.mtiApiPartnerCode,
      methods: [
        { paymentKey: paymentMethod.CreditCard },
        { paymentKey: paymentMethod.Installment },
        { paymentKey: paymentMethod.Transfer },
      ],
    };
  };

  const onSubmitPayment = (form: IOrderForm) => {
    const paymentMethod = form?.paymentMethod;
    if (paymentMethod) {
      // STEP 4
      setLoading(true);
      window.location.href = paymentMethod.value as string;
    }
  };

  const paymentMethodOptions: IOption[] = (
    paymentData?.result ??
    transactionInfo?.payments ??
    []
  ).map((payment) => ({
    name: payment.name,
    value: payment.url,
    paymentKey: payment.paymentKey,
  }));

  return (
    <>
      <div className="flex flex-col gap-5 h-full" data-testid="summary-package">
        {carPackageSelected && (
          <PackageDetail packageData={carPackageSelected} carInfo={carInfo} />
        )}
        {carPackageSelected && (
          <PackageAmountDetail packageData={carPackageSelected} />
        )}
        <UserDetail userInfo={userInfo} />
        <DetailCard
          title={t("user.addressByIdCard")}
          data-testid="summary-package-address"
        >
          <p className="whitespace-normal">
            {getAddressText(userInfo.address)}
          </p>
        </DetailCard>
        <DetailCard
          title={t("address.sentChannel")}
          data-testid="summary-package-address-sentoption"
        >
          <p className="whitespace-normal">
            {t(sentAddressLabel[userInfo.sentOption])}
          </p>
        </DetailCard>
        {userInfo.sentAddress && (
          <DetailCard
            title={t("user.addressByPolicy")}
            data-testid="summary-package-address-sent"
          >
            <p className="whitespace-normal">
              {userInfo.isSentAddressAsIDCard
                ? getAddressText(userInfo.address)
                : getAddressText(userInfo.sentAddress)}
            </p>
          </DetailCard>
        )}
        <div data-testid="summary-package-payment-summary">
          <DetailCard
            title={`${t("order.paymentRef")} ${paymentRefNo}`}
            showDivider={false}
          >
            <div className="h-[65px]" />
          </DetailCard>
          <Button
            fullWidth
            className="mt-[-70px] border-2 pointer-events-none p-4"
            size="fit"
            variant="gradient"
            color="secondary"
          >
            <div className="font-semibold flex justify-between items-center w-full">
              <span className="text-base">{t("order.netPremium")}</span>
              <span className="text-3xl">
                {formatNumber(totalPrice)} {t("thb")}
              </span>
            </div>
          </Button>
        </div>
        <OrderForm
          paymentMethodOptions={paymentMethodOptions}
          onSubmitForm={onSubmitPayment}
          disabledSubmitBtn={!!(errProposal || errPolicy || errPayment)}
        />
      </div>
      {openConfirmBackDialog && (
        <DialogConfirmBack
          open={openConfirmBackDialog}
          onClose={() => setOpenConfirmBackDialog(false)}
          onSubmit={() => {
            isSubmitConfirmBackDialog.current = true;
            clearBackState(true, true);
          }}
          onCancel={() => {
            setOpenConfirmBackDialog(false);
          }}
        />
      )}
    </>
  );
};

export default OrderPage;
