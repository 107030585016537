import { carConfig, AiFailType } from "../configs/car";

export interface ICarDetectionBody {
  companyRef: {
    referenceNo: string;
  };
}

export enum ImageStatus {
  pending = "pending",
  aiProcessing = "aiprocessing",
  completed = "completed",
}

export interface ICarSubmitBody {
  userRemark: {
    aiResultAccept: boolean;
    remark: string;
  };
}

type CarSide = keyof typeof carConfig;
export interface ICarDetectionResponse {
  refNo: string;
  lastMTIStatus: string;
  urlInspectionExpireDate?: string;
  images: ICarDetectionImagePosition;
  status: string;
  companyRef: {
    referenceNo: string;
    lineUUID: string | null;
  };
  carInspectionId: string;
}

export interface ICarDetectionImagePosition {
  default: {
    [key: string]: ICarDetectionImage;
  };
  additional: {
    [key: string]: ICarDetectionImage;
  };
  defaultOrder: string[];
  additionalOrder: string[];
}

export interface ICarDetectionImage {
  carInspectionId: string;
  position: string;
  status: ImageStatus | "";
  isPass: boolean;
  progress: number;
  aiProcessTime: number;
  failReason: string;
  failService: string;
  failType: AiFailType;
  damageResult: {
    hasDamage: boolean;
    damageCount: number;
  };
  urls: {
    original: string;
    damageLabel: string;
  };
}

export interface ICarUploadImgResponse {
  carInspectionId: string;
  imageId: string;
  urls: string;
}
