import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";
import DialogWarning from "components/Dialog/DialogWarning";
import Header from "../Header";
import { useCommonState } from "hooks/useCommonState";
import { useValidateRouter } from "hooks/useRouter";
interface Props {
  children: React.ReactNode;
  title?: string;
  showHeader?: boolean;
  showBackBtn?: boolean;
  showStepper?: boolean;
  step?: number;
}

const Layout = ({
  children,
  title = "",
  showBackBtn,
  showStepper,
  step,
  showHeader = true,
}: Props) => {
  useValidateRouter();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loading, setLoading, warningDialog } = useCommonState();
  const headerHeight = `${76 + (showStepper ? 69 : 0)}`;

  const layoutHeightCss = showHeader
    ? `max-w-screen-sm min-h-[calc(100%-${headerHeight}px)] p-5 mx-auto my-0`
    : `w-full min-h-100svh p-0 m-0`;

  useEffect(() => {
    return () => setLoading(false);
  }, [setLoading]);

  return (
    <div className="flex flex-col w-full h-viewport">
      {showHeader && (
        <Header
          title={t(title)}
          onClickBackBtn={showBackBtn ? () => navigate(-1) : null}
          showStepper={showStepper}
          step={step}
        />
      )}
      <div
        id="main-wrapper"
        // min height calc subtract height of header
        className={`flex-1 w-full justify-center ${layoutHeightCss}`}
      >
        {/* <Outlet /> */}
        {children}
        {warningDialog && <DialogWarning warning={warningDialog} />}
      </div>
      {loading && <Loader open />}
    </div>
  );
};

export default Layout;
