/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import Label from "../Label";

type Props = {
  classNameLabel?: string;
  children?: React.ReactNode;
  label?: string;
  keyTitle?: string;
  errorMsg?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  value?: any;
  options?: Record<string, any>[];
};

interface ISelectConfig {
  [key: string]: string;
}

const selectConfig: ISelectConfig = {
  default: "bg-primary-input-bg border-primary-input-border",
  disabled: "bg-primary-input-disabled-bg border-primary-input-disabled-border",
  error: "bg-primary-input-bg border-error",
};

const Select = ({
  label,
  keyTitle,
  placeholder,
  onChange,
  disabled,
  value,
  options,
  errorMsg,
  children,
  classNameLabel = "",
}: Props) => {
  const { t } = useTranslation();
  const isError = !!errorMsg;
  let inputCss = "";
  let textCss = "";

  if (disabled) inputCss = selectConfig.disabled;
  else if (isError) inputCss = selectConfig.error;
  else inputCss = selectConfig.default;

  if (value) {
    textCss = disabled
      ? "text-primary-input-disabled-text"
      : "text-primary-input-text";
  } else {
    textCss = "text-primary-input-placeholder";
  }

  return (
    <>
      {label && (
        <Label
          disabled={disabled}
          className={classNameLabel}
          data-testid="select-label"
        >
          {label}
        </Label>
      )}
      <Listbox
        by={keyTitle}
        value={value}
        onChange={onChange}
        disabled={disabled}
        data-testid="select-list"
      >
        <div className="relative mt-1">
          <Listbox.Button
            className={`relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left border placeholder:text-primary-input-placeholder ${inputCss} ${textCss}`}
          >
            <span className="block truncate">
              {(keyTitle && t(value?.[keyTitle])) || value || placeholder}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1">
              <ChevronDownIcon
                className={`w-7 ${
                  disabled
                    ? "text-primary-input-disabled-icon"
                    : "text-primary-input-icon"
                }`}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="z-20 absolute mt-1 max-h-[27rem] w-full text-primary-input-text bg-white overflow-auto rounded-md py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {children ??
                options?.map((option, index) => {
                  return (
                    <Listbox.Option
                      data-testid={`select-list-${index}`}
                      key={index}
                      className={({ active, selected }) =>
                        `relative cursor-default select-none py-2 pl-6 pr-4 ${
                          active || selected ? "bg-gray-600" : ""
                        }`
                      }
                      value={option}
                    >
                      {({ selected }) => (
                        <div className="flex justify-between">
                          <span className="block break-word">
                            {(keyTitle && t(option?.[keyTitle])) || value}
                          </span>
                          {selected && <CheckIcon className="w-[17px]" />}
                        </div>
                      )}
                    </Listbox.Option>
                  );
                })}
              {(!options || options.length === 0) && (
                <Listbox.Option
                  className="relative cursor-default select-none py-2 pl-6 pr-4"
                  value={null}
                  disabled
                >
                  <span className="block truncate">{t("notfound")}</span>
                </Listbox.Option>
              )}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {isError && (
        <span className="text-error" data-testid="select-error">
          {errorMsg}
        </span>
      )}
    </>
  );
};

export default Select;
