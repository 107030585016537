import { useQuery } from "@tanstack/react-query";
import { getPrefix, convertPrefixData } from "services/mti/user";
import { IMtiPrefixListParams, IPrefixListQueryKey } from "types/user.d";

export const usePrefix = (params: IMtiPrefixListParams, isAutoFetch = true) => {
  const queryKey: IPrefixListQueryKey = ["prefix", params];
  const { data, isLoading, isError, error, refetch } = useQuery(
    queryKey,
    getPrefix,
    { enabled: isAutoFetch }
  );

  const res = data?.data?.[0] ?? null;
  const prefix = res?.Prefix?.map(convertPrefixData);

  return { data: prefix, isLoading, isError, error, refetch };
};
