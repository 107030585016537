import { configs } from "configs/config";
import { axiosBaseApi } from "utils/axios";
import {
  ICarDetectionBody,
  ICarSubmitBody,
  ICarDetectionResponse,
  ICarUploadImgResponse,
} from "types/car-detection.d";

export const carDetection = async (body: ICarDetectionBody, debug = false) => {
  const qs = debug ? "?debug=1" : "";

  return axiosBaseApi.post<ICarDetectionResponse>(
    `${configs.caraiApiUrl}/car/inspection${qs}`,
    body
  );
};

export const carSubmit = async (
  carInspectionId: string,
  body: ICarSubmitBody,
  debug = false
) => {
  const qs = debug ? "?debug=1" : "";

  return axiosBaseApi.post(
    `${configs.caraiApiUrl}/car/inspection/${carInspectionId}/submit${qs}`,
    body
  );
};

export const carUpload = async (carInspectionId: string, body: FormData) => {
  return axiosBaseApi.post<ICarUploadImgResponse>(
    `${configs.caraiBaseApiUrl}/car/inspection/transaction/${carInspectionId}/image`,
    body
  );
};
