import { configs } from "configs/config";
import { axios } from "utils/axios";
import {
  IKycGenerateTokenResponse,
  IKycGenerateTokenParams,
  IKycValidationResponse,
} from "types/kyc.d";

export const generateTokenKYC = (body: IKycGenerateTokenParams) => {
  return axios.post<IKycGenerateTokenResponse>(
    `${configs.apiUrl}/friends/api/TokenAuth/GenerateToken`,
    body
  );
};

export const validateKYC = (accessToken: string) => {
  return axios.post<IKycValidationResponse>(
    `${configs.apiUrl}/friends/api/services/app/User/CheckEkyc`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken || ""}`,
      },
    }
  );
};
