import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { sendEmailContact } from "services/mti/mail";
import { MAIL_SUCCESS_PATH } from "configs/page";
import { MailContactType } from "configs/common";
import { ISendMailResponse } from "types/mail.d";
import { useCommonState } from "./useCommonState";
import { useTranslation } from "react-i18next";
import { formatFormDataToObject } from "utils/formatter";
import { captureLogMessage, createExtras } from "utils/logging";
import { logTags } from "configs/logging";

export const useSendMailContact = (): {
  data: ISendMailResponse | null;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  sendMailContact: UseMutateFunction<
    AxiosResponse<unknown, unknown> | null,
    unknown,
    FormData,
    unknown
  >;
} => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setWarningDialog, setLoading } = useCommonState();
  const { data, isError, error, mutate, isLoading } = useMutation(
    (body: FormData) => sendEmailContact(body),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: (resp, variables) => {
        if (resp.data.success) {
          const contactType = variables?.get("ContactType") ?? "";
          // final page not shown close btn
          const showCloseBtn = contactType !== MailContactType.CarDetection;

          navigate(MAIL_SUCCESS_PATH, { state: { showCloseBtn } });
        } else {
          const bodyParam = formatFormDataToObject(variables);
          captureLogMessage(
            "cannot send mail",
            {
              ...logTags.request,
              requestURL: resp.config.url ?? "",
            },
            createExtras(bodyParam, resp)
          );
          throw new Error("cannot send mail");
        }
      },
      onError: () => {
        setWarningDialog({
          title: t("warning.common"),
          message: t("warning.retry"),
        });
      },
      onSettled: () => {
        setLoading(false);
      },
    }
  );

  const res = data?.data ?? null;
  return {
    data: res,
    isLoading,
    isError,
    error,
    sendMailContact: mutate,
  };
};
