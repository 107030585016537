import React from "react";
import { CloseIcon } from "components/Icon/Common";
interface Props {
  title?: string;
  className?: string;
  heightFullMax?: boolean;
  children: React.ReactNode;
  shadow?: "left" | "top" | "none";
  borderColor?: "primary" | "gray" | "darkPrimary" | "none";
  backgroundColor?: "white" | "gray";
  onClose?: () => void;
}

const colorBgVariants = {
  white: "bg-white",
  gray: "bg-gray-50",
};

const Card = ({
  title,
  className = "",
  children,
  shadow = "none",
  heightFullMax,
  borderColor = "primary",
  backgroundColor = "white",
  onClose,
  ...rest
}: Props) => {
  let cardCss = `rounded-3xl ${colorBgVariants[backgroundColor]}`;
  if (borderColor === "primary") {
    cardCss = `${cardCss} border-[2px] border-primary-main-300`;
  } else if (borderColor === "gray") {
    cardCss = `${cardCss} border-[2px] border-gray-700`;
  } else if (borderColor === "darkPrimary") {
    cardCss = `${cardCss} border-[1px] border-primary-main-800`;
  }

  if (shadow === "left") {
    cardCss = `${cardCss} shadow-left`;
  } else if (shadow === "top") {
    cardCss = `${cardCss} shadow-top`;
  }

  const cardHeight = heightFullMax ? "max-h-[calc(100svh_-_6rem)]" : "";

  return (
    <div
      className={`flex w-full flex-col justify-center ${cardCss} ${className}`}
      data-testid="card"
      {...rest}
    >
      <div
        className={`p-4 overflow-y-auto scrollbar-hide whitespace-nowrap ${cardHeight}`}
      >
        <div className="flex w-full items-center">
          <div className="flex-1" />
          {title && (
            <h1 className=" text-center" data-testid="card-title">
              {title}
            </h1>
          )}
          <div className="flex flex-1 justify-end">
            {onClose && (
              <CloseIcon onClick={onClose} data-testid="card-close-btn" />
            )}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Card;
