import { configs } from "configs/config";
import { axios } from "utils/axios";

export const authenJWT = () => {
  return axios.post(`${configs.apiUrl}/authen/api/JWT`, {
    username: configs.ocrUsername,
    password: configs.ocrPassword,
    ExpireMinutes: 30,
  });
};
