import React from "react";
import BookRegisterForm from "./components/BookRegisterForm";

const BookRegisterPage = (): JSX.Element => {
  return (
    <div>
      <BookRegisterForm />
    </div>
  );
};

export default BookRegisterPage;
