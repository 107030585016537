import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CarBg from "assets/images/common/car-bg.png";
import { CameraAddIcon, KeyboardIcon } from "components/Icon/Common";
import Button from "components/Button";
import { CAR_BOOK_PREVIEW_PATH, CAR_BOOK_REGISTER_PATH } from "configs/page";
import { useCommonState } from "hooks/useCommonState";

const LandingPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { setAuth } = useCommonState();

  useEffect(() => {
    setAuth(true);
  }, [setAuth]);

  return (
    <div className="flex flex-col justify-between items-center h-viewport pb-16 landscape:pb-4 portrait:min-h-[680px] landscape:min-h-[360px]">
      <div className="flex flex-1 justify-center w-full origin-top">
        <div className="relative flex justify-center bg-gradient-to-t from-primary-main-500 to-primary-main-900 rounded-b-[126.5px] landscape:rounded-b-[126.5px] landscape:lg:rounded-b-[226.5px] w-full portrait:md:h-[70%] portrait:md:rounded-b-[200px] h-[60%] landscape:h-[80%] landscape:w-[50%]">
          <img
            data-testid="landing-logo"
            src={CarBg}
            alt="logo"
            className="z-10 absolute top-[30%] landscape:-top-[150%] landscape:mt-0 portrait:w-[100%] landscape:max-w-[300%] landscape:max-h-[300%] max-h-[150%] object-contain w-[100%]"
          />
        </div>
      </div>
      <div className="flex flex-col flex-1 text-center mx-6 pt-8 pl-3 pr-3 w-full justify-end">
        <h1 className="h1-title" data-testid="landing-title">
          {t("carInfo.checkPriceInsTitle")}
        </h1>
        <h1
          className="text-primary-text-700 m-3"
          data-testid="landing-select-method"
        >
          {t("carInfo.registerSelectMethod")}
        </h1>
        <div className="flex flex-col landscape:flex-row landscape:mt-0 landscape:w-full landscape:justify-center landscape:items-start gap-4 my-4">
          <Link to={CAR_BOOK_PREVIEW_PATH} className="flex-1">
            <Button
              variant="gradient"
              fullWidth
              color="primary"
              size="large"
              data-testid="landing-select-camera-btn"
            >
              <span data-testid="landing-select-camera-text">
                {t("carInfo.ocrBookRegister")}
              </span>
              <CameraAddIcon
                className="ml-4"
                width={24}
                height={24}
                data-testid="landing-select-camera-icon"
              />
            </Button>
          </Link>
          <Link
            to={CAR_BOOK_REGISTER_PATH}
            className="flex flex-1 justify-center h-full"
          >
            <Button
              variant="gradient"
              fullWidth
              color="secondary"
              size="large"
              data-testid="landing-select-typing-btn"
            >
              <span data-testid="landing-select-typing-text">
                {t("carInfo.manualBookRegister")}
              </span>
              <KeyboardIcon
                className="ml-4"
                width={24}
                height={24}
                data-testid="landing-select-typing-icon"
              />
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
