import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { SELECTED_INSURED_PATH } from "configs/page";
import { FORMAT_DATE } from "configs/date";
import { CntType, MailContactType } from "configs/common";
import { formatDate } from "utils/formatter";
import CarCommonDetail from "./components/CarCommonDetail";
import CarUniqueDetail from "./components/CarUniqueDetail";
import { carPackageState } from "states/carPackageState";
import { searchParamsState } from "states/searchParamsState";
import { carInfoState } from "states/carInfoState";
import Button from "components/Button";
import DialogContactStaff from "components/Dialog/DialogContactStaff";
import { ICarUniqueInfoForm } from "types/car-info.d";
import { useCheckDuplicateChassis } from "hooks/useTransaction";
import { useCommonState } from "hooks/useCommonState";

//Pattern sent by MTI [กก 1111, 1ก-1111, 11-1111, 1กก 1111, ก-1111, ก11-1111]
const carLicensePlatePattern =
  /((^[ก-ฮ]{2,2})([ ]{1,1})([0-9]{1,4}$))|((^[0-9]{1,1}[ก-ฮ]{0,1})([-])([0-9]{1,4}$))|((^[0-9]{1,2})([-])([0-9]{1,4}$))|((^[0-9]{1,1}[ก-ฮ]{2,2})([ ]{1,1})([0-9]{1,4}$))|((^[ก-ฮ]{1,1})([-]{1,1})([0-9]{1,4}$))|((^[ก-ฮ]{1,1})([0-9]{2,2})([-]{1,1})([0-9]{1,4}$))/;

const carChassisNumberPattern = /^[A-Za-z0-9]{1,30}$/;

interface ICheckDupParams {
  chassisSerialNumber: string;
  insuranceCoverageStartDate: Date | null;
}

const CarSummaryPage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setLoading, setWarningDialog } = useCommonState();
  const searchParam = useRecoilValue(searchParamsState);
  const carPackage = useRecoilValue(carPackageState);
  const [carInfo, setCarInfo] = useRecoilState(carInfoState);
  const [isOpenDialogContactStaff, setIsOpenDialogContactStaff] =
    useState<boolean>(false);

  const defaultValues: ICarUniqueInfoForm = {
    isNew: carInfo.carUniqueInfo?.isNew ?? false,
    plateNumber: carInfo.carUniqueInfo?.plateNumber ?? "",
    color: carInfo.carUniqueInfo?.color ?? null,
    chassisSerialNumber: carInfo.carUniqueInfo?.chassisSerialNumber ?? "",
    engineSerialNumber: carInfo.carUniqueInfo?.engineSerialNumber ?? "",
  };

  const schema = yup
    .object({
      isNew: yup.boolean().required(t("form.inputError.Toggle")),
      plateNumber: yup
        .string()
        .required(t("carInfo.inputError.plateNumber"))
        .when("isNew", {
          is: true,
          then: yup.string(),
          otherwise: yup
            .string()
            .matches(
              carLicensePlatePattern,
              t("carInfo.patternError.plateNumber")
            ),
        }),
      color: yup
        .object()
        .typeError(t("carInfo.inputError.color"))
        .required(t("carInfo.inputError.color")),
      chassisSerialNumber: yup
        .string()
        .required(t("carInfo.inputError.chassisSerialNumber"))
        .matches(
          carChassisNumberPattern,
          t("carInfo.patternError.chassisSerialNumber")
        ),
      engineSerialNumber: yup
        .string()
        .required(t("carInfo.inputError.engineSerialNumber")),
    })
    .required();

  const methods = useForm<ICarUniqueInfoForm>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: "onChange",
  });

  const convertDuplicateChassisParams = (
    car: ICheckDupParams,
    isCTP: boolean
  ) => ({
    cnttype: isCTP ? CntType.CTP : CntType.PMX,
    policystatus: "N", // new
    chassisno: car.chassisSerialNumber || "",
    effectivedt: car?.insuranceCoverageStartDate
      ? formatDate(car.insuranceCoverageStartDate, FORMAT_DATE)
      : "",
    agentno: searchParam?.agent ?? "",
  });

  const duplicateChassis = useCheckDuplicateChassis();

  const checkDup = async (data: ICheckDupParams) => {
    const checkDupList = [{ isCMI: false }];
    if (carPackage?.hasCTP) {
      checkDupList.push({ isCMI: true });
    }

    const checkDupWithMutation = checkDupList.map((prop) => {
      const bodyCheckDup = convertDuplicateChassisParams(data, prop.isCMI);

      return duplicateChassis.checkDupChassisAsync(bodyCheckDup);
    });

    try {
      const respList = await Promise.all(checkDupWithMutation);
      const errorList = (respList || []).filter((resp) => !resp?.data?.Success);

      if (errorList.length === 0) {
        navigate(SELECTED_INSURED_PATH);
      } else {
        setIsOpenDialogContactStaff(true);
      }
    } catch (error) {
      setWarningDialog({
        title: t("warning.common"),
        message: t("warning.retry"),
      });
    }
  };

  const onSubmit = (formData: ICarUniqueInfoForm) => {
    const updateCarInfo = { ...carInfo, carUniqueInfo: formData };
    setCarInfo(updateCarInfo);

    checkDup({
      chassisSerialNumber: updateCarInfo.carUniqueInfo.chassisSerialNumber,
      insuranceCoverageStartDate:
        updateCarInfo?.insuranceCoverageStartDate ?? null,
    });
  };

  useEffect(() => {
    setLoading(duplicateChassis.isLoading);

    return () => setLoading(false);
  }, [setLoading, duplicateChassis.isLoading]);

  return (
    <>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <CarCommonDetail t={t} carInfo={carInfo} carPackage={carPackage} />
        <CarUniqueDetail t={t} carInfo={carInfo} methods={methods} />
        <div className="py-6">
          <Button
            data-testid="car-summary-submit-btn"
            fullWidth
            variant="gradient"
            color="primary"
            size="large"
            type="submit"
          >
            {t("confirmBtn")}
          </Button>
        </div>
      </form>
      <DialogContactStaff
        contactType={MailContactType.DupChassis}
        open={isOpenDialogContactStaff}
        onCloseDialog={() => {
          setIsOpenDialogContactStaff(false);
        }}
      />
    </>
  );
};
export default CarSummaryPage;
