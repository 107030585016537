import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { ID_HOME_PATH, ID_REGISTER_PATH } from "configs/page";
import { configs } from "configs/config";
import { CameraType } from "configs/camera";
import Camera from "components/Camera";
import ImagePreview from "components/ImagePreview";
import { searchParamsState } from "states/searchParamsState";
import { IIDOCRResponse } from "types/id-ocr.d";
import { useIDOCR } from "hooks/useIDOCR";
import { dataURIToBlob } from "utils/file";
import { useCommonState } from "hooks/useCommonState";

const IDPreviewPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const { idOCRMutate } = useIDOCR();
  const searchParam = useRecoilValue(searchParamsState);
  const { setLoading, setWarningDialog } = useCommonState();

  const onChange = (image: string) => {
    setOpen(false);
    if (image) {
      setImagePreview(image);
    }
  };

  const openCamera = () => {
    setOpen(true);
  };

  const onSubmit = async () => {
    if (imagePreview) {
      setLoading(true);
      const file = dataURIToBlob(imagePreview);
      const formData = new FormData();
      formData.append("image", file, "idcard");
      formData.append("owner", configs.mtiApiPartnerCode);
      idOCRMutate(formData, {
        onSuccess(resp) {
          const ocrResp = resp?.data as IIDOCRResponse;
          const ocrData = ocrResp?.data;
          navigate(ID_REGISTER_PATH, {
            state: {
              ocrData: ocrData,
            },
          });
        },
        onError() {
          setWarningDialog({
            title: t("id.ocr.fail"),
            onClose: () => {
              navigate(ID_REGISTER_PATH);
            },
          });
        },
        onSettled() {
          setLoading(false);
        },
      });
    }
  };

  useEffect(() => {
    return () => setLoading(false);
  }, [setLoading]);

  return (
    <>
      <ImagePreview
        src={imagePreview}
        alt="id-card"
        onRetake={openCamera}
        onSubmit={onSubmit}
        setImagePreview={setImagePreview}
        debug={!!searchParam?.debug}
      />

      {open && (
        <Camera
          onChange={onChange}
          open={open}
          setOpen={setOpen}
          onClickBackBtn={() => {
            setOpen(false);
            navigate(ID_HOME_PATH);
          }}
          type={CameraType.IdCard}
        />
      )}
    </>
  );
};

export default IDPreviewPage;
