/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Label from "components/Input/Label";
import { SolidCircleIcon } from "components/Icon/Common";
import { TFunction } from "i18next";

type Props = {
  className?: string;
  classNameOption?: string;
  classNameLabel?: string;
  name: string;
  label?: string;
  errorMsg?: string;
  disabled?: boolean;
  onChange: (e?: React.ChangeEvent<HTMLInputElement> | null) => void;
  value?: number | string;
  options?: { [key: string]: any }[] | null;
  t?: TFunction;
};

const Radio = ({
  t,
  className = "",
  classNameOption = "",
  label,
  name,
  onChange,
  disabled,
  options,
  errorMsg,
  classNameLabel,
  value,
  ...rest
}: Props) => {
  const isError = !!errorMsg;

  let optionCss = "";
  if (disabled) {
    optionCss +=
      "border-primary-input-disabled-text text-primary-input-disabled-text";
  }

  return (
    <div className={className}>
      {label && (
        <Label
          disabled={disabled}
          className={`my-2 ${classNameLabel}`}
          data-testid="radio-label"
        >
          {label}
        </Label>
      )}

      <div data-testid="radio-input">
        {options?.map((option) => (
          <label
            htmlFor={option.label ? `${option.label}-radio` : "radio"}
            key={`radio-${option.label}`}
            className="flex w-full my-2 items-center"
          >
            <div
              className={`h-8 w-8 mr-2 flex border-primary-input-accent border-[1px] rounded-full justify-center items-center ${optionCss}`}
            >
              <input
                id={option.label ? `${option.label}-radio` : "radio"}
                name={name}
                className={`sr-only`}
                onChange={() => onChange(option.value)}
                type="radio"
                disabled={disabled}
                value={option.value}
                {...rest}
              />

              {value === option.value && (
                <span className={`${disabled ? "opacity-60" : ""}`}>
                  <SolidCircleIcon />
                </span>
              )}
            </div>
            <p className={`inline-block ${optionCss} ${classNameOption}`}>
              {t ? t(option.label) : option.label}
            </p>
          </label>
        ))}
      </div>
      {isError && <span className="text-error">{errorMsg}</span>}
    </div>
  );
};

export default Radio;
