import React from "react";

interface Props {
  size?: "medium" | "large";
  className?: string;
}

interface IDividerConfig {
  [key: string]: string;
}

const dividerConfig: IDividerConfig = {
  // size
  large: "border-solid border-b-8",
  medium: "border-solid border-b",
};

const Divider: React.FC<Props> = ({
  size = "medium",
  className = "",
}: Props) => {
  const borderCss = dividerConfig[size] || "";

  return <div className={`${borderCss} ${className}`} />;
};

export default Divider;
